import React from "react";
import Dialog from "@material-ui/core/Dialog";

import DialogContent from "@material-ui/core/DialogContent";

import Slide from "@material-ui/core/Slide";
import LoadingModal from "../../../../../baseComponents/LoadingModal";

import {
  StyledCancelButton,
  StyledConfirmButton,
  StyledDialogContentText,
  StyledDialogActions,
} from "./styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmationDialog({
  openDialog,
  setOpenDialog,
  deleteDish,
  isLoadingDelete,
}) {
  const dishDeleted = () => {
    deleteDish();
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return !isLoadingDelete ? (
    <div>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle id='alert-dialog-slide-title'>
          {"Use Google's location service?"}
        </DialogTitle> */}
        <DialogContent>
          <StyledDialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this dish?
          </StyledDialogContentText>
        </DialogContent>
        <StyledDialogActions>
          <StyledCancelButton onClick={handleClose}>Cancel</StyledCancelButton>
          <StyledConfirmButton onClick={dishDeleted}>Yes</StyledConfirmButton>
        </StyledDialogActions>
      </Dialog>
    </div>
  ) : (
    <LoadingModal />
  );
}
