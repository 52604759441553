import styled from "styled-components/macro";
import { Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";

const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
const FormContainer = styled.div`
  padding: 40px;
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.h1`
  text-align: center;
`;

const TextBlock = styled.div`
  font-size: 16px;
  margin: 10px;

  &:last-child {
    margin-bottom: 30px;
  }

  & > img {
    margin-right: 10px;
  }
`;

const QRContainer = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  background-color: #ef403b;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
`;

const RestaurantName = styled.div`
  height: 50px;
  color: white;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: center;
  border-radius: 10px;
`;

const QRBodyContainer = styled.div`
  border-left: 10px solid #ef403b;
  border-right: 10px solid #ef403b;
`;

const QRFooter = styled.div`
  height: 50px;
  color: white;
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center;
`;

const ButtonContainer = styled.div`
  text-align: center;
  padding: 0.25rem 2rem 0.25rem 2rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
  background-color: #ef403b;
  border-radius: 10px;
`;

const StyledPrintButton = styled(Button)`
  &&& {
    color: white;
    text-transform: capitalize;
  }
`;

const StyledGoToButton = styled(Button).attrs(() => ({ variant: "outlined" }))`
  &&& {
    margin: 3rem auto 0;
  }
`;

const StyledHeader = styled(Typography).attrs(() => ({
  component: "h1",
  variant: "h5",
}))`
  &&& {
    text-align: center;
    margin-bottom: 20px;
    font-weight: 600;
    text-transform: capitalize;
  }
`;

const ButtonsContainer = styled.div`
  // width: 50%;
  // margin: 30px auto 20px auto;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  margin-bottom: 0;
`;
const StyledCancelButton = styled(Button).attrs(() => ({
  variant: "contained",
}))`
  &&& {
    background-color: white;
    color: #ef403b;
    border: 1px solid #ef403b;
    //width: 142px;
    padding: 5px 30px !important;
    border-radius: 8px !important;
    font-weight: bold !important;
    margin: 10px !important;
  }
`;
const StyledSaveButton = styled(Button).attrs(() => ({
  variant: "contained",
}))`
  &&& {
    background-color: #ef403b;
    color: white;
    //width: 142px;
    padding: 5px 30px !important;
    border-radius: 8px !important;
    font-weight: bold !important;
    margin: 10px !important;
  }
`;
export {
  Container,
  FormContainer,
  Header,
  TextBlock,
  QRContainer,
  StyledHeader,
  RestaurantName,
  QRBodyContainer,
  QRFooter,
  ButtonContainer,
  StyledPrintButton,
  StyledGoToButton,
  ButtonsContainer,
  StyledCancelButton,
  StyledSaveButton,
};
