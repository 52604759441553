import React, { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import Form from "./Form";

function Categories() {
  const handleChipClick = useOutletContext();

  useEffect(() => {
    handleChipClick("categories");
  }, [handleChipClick]);

  return <Form />;
}

export default Categories;
