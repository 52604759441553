import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import CategoriesCard from "./CategoriesCard";
import TextFieldCard from "./TextFieldCard";
// import Modal from "../../cancelModal/CancelModal";
import { makeAPiCall } from "../../../../../services/api";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../../../firebase";
import LoadingModal from "../../../../baseComponents/LoadingModal";

import {
  Container,
  StyledHeader,
  StyledButton,
  ButtonContainer,
  StyledCancelButton,
  StyledSaveButton,
} from "./styles";

function Form() {
  const navigate = useNavigate();
  const dragItem = useRef();
  const dragOverItem = useRef();
  const [showTextField, setShowTextField] = useState(false);
  // const [open, setOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [FormData, setData] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [editCategoryValue, setEditCategoryValue] = useState("");
  const [editCategoryId, setEditCategoryId] = useState("");

  const handleCancelClick = () => {
    setShowTextField(false);
  };

  // const handleClose = () => setOpen(false);

  useEffect(() => {
    setIsloading(true);
    if (localStorage.getItem("uid")) {
      const getData = async () => {
        const docRef = doc(db, "restaurants", localStorage.getItem("uid"));
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setIsUpdate(true);
          setIsloading(false);
          setData([...docSnap.data().categories]);
        } else {
          console.log("no data");
          setIsloading(false);
        }
      };
      getData();
    }
  }, []);
  // const setData = (val)=>{
  //   console.log(val );
  //   setDataOrg(val);
  //   console.log({ FormData });
  //   const data = {
  //     uid: localStorage.getItem("uid"),
  //     categories: FormData,
  //   };
  //   console.log({ data });
  // };

  const handleSubmit = async (val) => {
    // e.preventDefault();
    setIsloading(true);
    const data = {
      uid: localStorage.getItem("uid"),
      categories: val,
    };

    // setTimeout(function(){
    //   console.log( val );

    // }, 500);

    // if (val.length >1) {
    //   const washingtonRef = doc(db, "restaurants", localStorage.getItem("uid"));

    //   console.log({ FormData });

    //   await updateDoc(washingtonRef, {
    //     categories: FormData,
    //   }).then(() => {
    //     setIsloading(false);
    //     // navigate("/register/menu-items");
    //   });
    // } else {
    makeAPiCall("POST", "add-categories-subcategories", data)
      .then((data) => {
        setIsloading(false);
        // navigate("/register/menu-items");
      })
      .catch((error) => {
        console.log(error);
      });
    // }
  };

  const dragStart = (e, position) => {
    console.log("Get Details:::", e);
    dragItem.current = position;
    console.log(e.target.innerHTML);
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
    console.log(e.target.innerHTML);
  };

  const drop = (e) => {
    console.log("Get Details:::", e);
    const copyListItems = [...FormData];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    console.log("Get Response>>>", copyListItems);
    handleSubmit(copyListItems);
    setData(copyListItems);
  };

  const handleDeleteCategory = async (id) => {
    let data = [];
    FormData.map((val) => {
      if (val.id !== id) {
        data.push(val);
      }
      return val;
    });
    setData((prev) => prev.filter((val) => val.id !== id));
    setIsloading(true);
    const washingtonRef = doc(db, "restaurants", localStorage.getItem("uid"));

    console.log(data);

    await updateDoc(washingtonRef, {
      categories: data,
    }).then(() => {
      setIsloading(false);
    });
  };

  return (
    <Container>
      <StyledHeader>
        {isUpdate
          ? "Edit Categories/Sub-Categories"
          : "Add Categories/Sub-Categories"}{" "}
      </StyledHeader>
      {FormData.length > 0 &&
        FormData.map((data, index) => (
          <CategoriesCard
            data={data}
            setData={setData}
            handleSubmit={handleSubmit}
            id={data.id}
            showTextField={showTextField}
            handleCancelClick={handleCancelClick}
            index={index}
            onDragStart={(e) => dragStart(e, index)}
            onDragEnter={(e) => dragEnter(e, index)}
            onDragEnd={drop}
            handleDeleteCategory={(id) => handleDeleteCategory(id)}
          />
        ))}
      {showTextField && editCategoryValue ? (
        <TextFieldCard
          label="Category"
          setData={setData}
          handleSubmit={handleSubmit}
          v
          handleCancelClick={handleCancelClick}
          editCategoryValue={editCategoryValue}
          setEditCategoryValue={setEditCategoryValue}
          editCategoryId={editCategoryId}
          setEditCategoryId={setEditCategoryId}
        />
      ) : showTextField ? (
        <TextFieldCard
          label="Category"
          setData={setData}
          handleSubmit={handleSubmit}
          handleCancelClick={handleCancelClick}
        />
      ) : null}

      <div>
        <StyledButton onClick={() => setShowTextField(true)}>
          + Add Category
        </StyledButton>
      </div>
      {/* <ButtonContainer>
        <StyledCancelButton onClick={() => setOpen(true)}>
          Cancel
        </StyledCancelButton>
        {open && <Modal open={open} handleClose={handleClose} />}
        <StyledSaveButton type="submit" onClick={handleSubmit}>
          {isUpdate ? "Update" : "Save"}
        </StyledSaveButton>
      </ButtonContainer> */}

      <ButtonContainer>
        <StyledCancelButton onClick={() => navigate("/register/about-us")}>
          &lt;&nbsp;Previous
        </StyledCancelButton>

        <StyledSaveButton onClick={() => navigate("/register/menu-items")}>
          Next&nbsp;&gt;
        </StyledSaveButton>
      </ButtonContainer>
      {isLoading && <LoadingModal />}
    </Container>
  );
}

export default Form;
