import "./App.css";
import Header from "./components/header/Header";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Protected from "./ProtectedRoutes";
import Login from "./components/login";
import SignUp from "./components/signUp";
import List from "./components/restoList";
import Register from "./components/register";
import AccountDetails from "./components/register/components/forms/accountDetails";
import RestoDeatils from "./components/register/components/forms/restoDetails";
import AboutUs from "./components/register/components/forms/aboutUs";
import Categories from "./components/register/components/forms/categoriesSubCategories";
import MenuItems from "./components/register/components/forms/menuItems";
import Sponsored from "./components/register/components/forms/Sponsored";
import Featured from "./components/register/components/forms/featured";
import QrCode from "./components/register/components/forms/qrCode";
import Review from "./components/register/components/forms/review";
import Home from "./components/register/components/forms/home";
import Setting from "./components/register/components/forms/settingScreen";

function App() {
  return (
    <div className="App">
      <div className="" style={{ height: "100vh" }}>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route exact path="/" element={<Protected Component={List} />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/sign-up" element={<SignUp />} />
            <Route exact path="/register" element={<Register />}>
              <Route
                path="/register/account-details"
                element={<Protected Component={AccountDetails} />}
              />

              <Route
                path="/register/restaurant-details"
                element={<Protected Component={RestoDeatils} />}
              />
              <Route
                path="/register/about-us"
                element={<Protected Component={AboutUs} />}
              />
              <Route
                path="/register/categories"
                element={<Protected Component={Categories} />}
              />
              <Route
                path="/register/menu-items"
                element={<Protected Component={MenuItems} />}
              />
              <Route
                path="/register/featured"
                element={<Protected Component={Featured} />}
              />
              <Route
                path="/register/promotions"
                element={<Protected Component={Sponsored} />}
              />
              <Route
                path="/register/qr-code"
                element={<Protected Component={QrCode} />}
              />
              <Route
                path="/register/review"
                element={<Protected Component={Review} />}
              />
              <Route
                path="/register/home"
                element={<Protected Component={Home} />}
              />
              <Route
                path="/register/setting"
                element={<Protected Component={Setting} />}
              />
            </Route>
          </Routes>
        </BrowserRouter>
        {/* <Footer /> */}
      </div>
    </div>
  );
}

export default App;
