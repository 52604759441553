import React, { useState, useEffect } from "react";
import {
  Container,
  StyledForm,
  StyledHeader,
  StyledTips,
  StyledTips1,
  ButtonsContainer,
  StyledCancelButton,
} from "./styles";
import { Switch } from "antd";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { db } from "../../../../../firebase";

function Setting() {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(true);
  const [checkedReview, setCheckedReview] = useState(true);
  const [checkedCall, setCheckedCall] = useState(true);

  useEffect(() => {
    fetchRestaurantLogoURL();
  }, []);

  const fetchRestaurantLogoURL = async () => {
    localStorage.setItem("id", localStorage.getItem("uid"));
    const id = localStorage.getItem("id");
    const docRef = doc(db, "restaurants", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const enableRestaurant = docSnap.data().enableRestaurant ?? true;
      const enableReviews = docSnap.data().enableReviews ?? true;
      const enableCall = docSnap.data().callToOrder ?? true;
      setChecked(enableRestaurant);
      setCheckedReview(enableReviews);
      setCheckedCall(enableCall);
    }
  };

  const handleSwitchChange = async () => {
    try {
      setChecked(!checked);
      const id = localStorage.getItem("id");
      console.log("Get response:::", id);
      const docRef = doc(db, "restaurants", localStorage.getItem("id"));

      await setDoc(docRef, { enableRestaurant: !checked }, { merge: true });

      setChecked(!checked);
    } catch (err) {
      console.log("Get Error:::", err);
    }
  };

  const handleReviewSwitchChange = async () => {
    setCheckedReview(!checkedReview);
    const docRef = doc(db, "restaurants", localStorage.getItem("id"));
    await setDoc(docRef, { enableReviews: !checkedReview }, { merge: true });
  };
  const handleCallSwitchChange = async () => {
    setCheckedCall(!checkedCall);
    const docRef = doc(db, "restaurants", localStorage.getItem("id"));
    await setDoc(docRef, { callToOrder: !checkedCall }, { merge: true });
  };

  return (
    <Container>
      <StyledForm>
        <StyledHeader>Restaurant Settings</StyledHeader>
        <StyledTips>
          Enable restaurant in Menu app
          <Switch
            style={{ backgroundColor: checked ? "#1BB55C" : "grey" }}
            checked={checked}
            onChange={handleSwitchChange}
          />
        </StyledTips>
        <StyledTips>
          Enable reviews in Menu app
          <Switch
            style={{ backgroundColor: checkedReview ? "#1BB55C" : "grey" }}
            checked={checkedReview}
            onChange={handleReviewSwitchChange}
          />
        </StyledTips>
        <StyledTips1>
          Call to order
          <Switch
            style={{ backgroundColor: checkedCall ? "#1BB55C" : "grey" }}
            checked={checkedCall}
            onChange={handleCallSwitchChange}
          />
        </StyledTips1>
      </StyledForm>
      <ButtonsContainer>
        <StyledCancelButton onClick={() => navigate("/register/review")}>
          &lt;&nbsp;Previous
        </StyledCancelButton>
      </ButtonsContainer>
    </Container>
  );
}

export default Setting;
