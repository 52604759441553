import styled from "styled-components/macro";
import { Link } from "react-router-dom";

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;

  /* Media query for phones */
  @media only screen and (max-width: 1300px) {
    width: 25%;
  }

  /* Media query for desktops and larger screens */
  @media only screen and (min-width: 1300px) {
    width: 30%;
  }

  overflow-y: auto;
  // margin-left: 64px;
  // margin-top: 54px;
  margin-top: clamp(10px, 3vw, 64px);
  // margin-bottom: clamp(10px, 5vw, 64px);
  margin-left: clamp(10px, 3vw, 64px);

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: white;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 50px;
  }
`;

const StyledLink = styled(Link)`
  padding: 20px;
  margin: 1px 0;
  background-color: white;

  color: ${({ isActive }) => (isActive ? "#ef403b" : "black")};
  text-decoration: ${({ isActive }) => (isActive ? "none" : "underline")};
`;

export { SidebarContainer, StyledLink };
