import React from "react";
import {
  footer as HeaderLayout,
  TextStyle,
  styles,
  footerTop as FooterTop,
  TextTitle,
} from "./styles";
import menuLogo from "../../assets/menuLogo.png";
import social from "../../assets/social.png";

function Footer(props) {
  return (
    <div style={{ bottom: 0, width: "100%", left: 0, marginTop: "20px" }}>
      <div style={styles}>
        <FooterTop>
          <TextStyle>
            <a
              style={{ color: "#fff", textDecoration: "none" }}
              href={"https://www.globalmenu.app/about"}
            >
              <span style={{ fontSize: "13px" }}> ABOUT US</span>
            </a>
            <a
              style={{ color: "#fff", textDecoration: "none" }}
              href={"https://www.globalmenu.app/contact"}
            >
              <span style={{ marginLeft: 20, fontSize: "13px" }}>
                CONTACT US
              </span>
            </a>
          </TextStyle>
          <TextTitle>Choose Wisely, Choose Right</TextTitle>
        </FooterTop>
      </div>

      <HeaderLayout>
        <TextStyle>
          © 2022 Menu Technologies. All Rights Reserved.{" "}
          <img
            src={menuLogo}
            alt=""
            style={{
              width: 70,
              height: 18,
              marginLeft: 14,
              marginTop: 8,
              resize: "none",
            }}
          />
        </TextStyle>
        <img
          src={social}
          alt=""
          style={{
            width: 120,
            height: 18,
            marginTop: 10,
            resize: "none",
            marginRight: "20px",
          }}
        />
      </HeaderLayout>
    </div>
  );
}

export default Footer;
