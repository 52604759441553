import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, FormControl } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useDispatch } from "react-redux";
import { login } from "./loginSignupSlice";
// import { makeAPiCall } from "../../services/api";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import {
  Container,
  Form,
  Wrapper,
  StyledTextField,
  StyledTypograthy,
  ButtonContainer,
  styles,
  ImageForm,
} from "./styles";
import details from "../../assets/details.png";
import Footer from "../footer/Footer";

function LoginForm({ setUser }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const userAuth = useAuth();
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });
  // const [passwordVisible, setPasswordVisible] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const loginFirebase = () => {
    const { email, password } = loginData;

    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        const docRef = doc(db, "users", user.uid);

        const docSnap = await getDoc(docRef);
        if (docSnap?.data() && docSnap?.data().role === "admin") {
          navigate("/register/home");
          dispatch(login(JSON.stringify(user)));
          localStorage.setItem("user", JSON.stringify(user));
        } else if (docSnap?.data() && docSnap?.data().role === "super admin") {
          dispatch(login(JSON.stringify(user)));
          localStorage.setItem("user", JSON.stringify(user));
          setLoading(false);
          navigate("/", {
            replace: true,
          });
        } else {
          setErrors({ email: "Email or password is incorrect." });
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error?.code?.split("/")[1] === "wrong-password") {
          setErrors({ password: error?.code?.split("/")[1] });
        } else {
          setErrors({ email: error?.code?.split("/")[1] });
        }
      });
  };

  const validate = () => {
    const errors = {};
    errors.email = loginData.email
      ? /.+@.+..+/.test(loginData.email)
        ? ""
        : "Invalid Email"
      : "Login Email is Required";
    errors.password = loginData.password ? "" : "Password Is Required";
    setErrors({ ...errors });
    return Object.values(errors).every((error) => error === "");
  };

  const handleChange = (event) => {
    const { value, checked, name } = event.target;
    setLoginData((prev) => ({
      ...prev,
      [name ? name : "rememberMe"]: name ? value : checked,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (validate()) {
      loginFirebase();
    } else setLoading(false);
  };

  return (
    <>
      <div style={{ minHeight: "calc(100vh - 175px)" }}>
        <Container>
          <Form onSubmit={handleSubmit}>
            <StyledTypograthy component="h1" variant="h5">
              Restaurant Admin Login
            </StyledTypograthy>
            <FormControl margin="normal" required fullWidth>
              <Wrapper>
                <StyledTextField
                  label="Login Email"
                  onChange={handleChange}
                  name="email"
                  value={loginData.email}
                  error={errors.email && errors.email.length > 0}
                  helperText={errors.email}
                  variant="outlined"
                  onBlur={() => {
                    setErrors({});
                  }}
                />
              </Wrapper>
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <Wrapper>
                <StyledTextField
                  label="Password"
                  onChange={handleChange}
                  name="password"
                  value={loginData.password}
                  // type={passwordVisible ? "text" : "password"}
                  type={"password"}
                  error={errors.password && errors.password.length > 0}
                  helperText={errors.password}
                  variant="outlined"
                  onBlur={() => {
                    setErrors({});
                  }}
                />
                {/* <button onClick={() => setPasswordVisible(!passwordVisible)}>
        {passwordVisible ? "Hide" : "Show"}
      </button> */}
              </Wrapper>
            </FormControl>
            <ButtonContainer>
              <Button
                variant={"contained"}
                type="submit"
                className="form-button"
                color="primary"
                disabled={loading}
              >
                {loading ? (
                  <>
                    <CircularProgress color="primary" size={28} />{" "}
                  </>
                ) : (
                  "Log in"
                )}
              </Button>
              <Link to="/sign-up">Create Account</Link>
            </ButtonContainer>
          </Form>

          <ImageForm>
            <div style={styles}>
              <img
                src={details}
                alt=""
                style={{
                  width: "auto",
                  height: "auto",
                  resize: "none",
                  maxHeight: "90%",
                  maxWidth: "80%",
                }}
              />
            </div>
          </ImageForm>
        </Container>
      </div>

      <Footer />
    </>
  );
}

export default LoginForm;
