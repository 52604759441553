import styled from "styled-components/macro";
import {
  Button,
  Box,
  Typography,
  TextField,
  Select,
  InputLabel,
  Checkbox,
  withTheme,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";

const ModalContentContainer = withTheme(styled.div`
  position: absolute;
  width: 800px;
  outline: none;
  border-radius: 10px;
  height: 80vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  ${({ theme }) => `
  background-color: ${theme.palette.background.paper};
  box-shadow: ${theme.shadows[5]};
  padding: ${theme.spacing(4)}px;
`}
`);

const CategoryContainer = styled(Box)`
  &&& {
    display: flex;
    gap: 12px;
  }
`;

const InputContaner = styled.div`
  width: 50%;
`;

const DishToggleBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 80vh;
`;

const StyledHeader = styled(Typography).attrs(() => ({
  component: "h1",
  variant: "h5",
}))`
  &&& {
    margin-bottom: 20px;
    font-weight: 600;
  }
`;

const StyledHintContainer = styled(Typography).attrs(() => ({
  component: "p",
  variant: "p",
}))`
  &&& {
    display: flex;
    align-items: center;
    margin: 10px 0;
    font-size: 13px;

    & span {
      margin-left: 10px;
      text-align: start;
    }
  }
`;

const ModalForm = styled.form`
  flex: 1;
  overflow-y: auto;
  padding: 0 20px 20px 0;
  height: 100%;
  margin-bottom: 50px;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: white;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 50px;
  }
`;

const HelperText = styled.div`
  color: #f44336;
  margin: 0 0 0 14px;
  font-size: 12px;
  text-align: start;
`;

const DescriptionText = styled.div`
  color: #000;
  margin: 20px 0 0 0;
  font-size: 16px;
  text-align: start;
`;

const PriceTitle = styled.div`
  color: #000;
  font-size: 16px;
  text-align: start;
  font-weight: 700;
  width: 230px;
`;

const DefaultText = styled.div`
  color: #000;
  font-size: 16px;
  text-align: start;
  font-weight: 700;
  width: 150px;
`;

const TextAreaWrapper = styled.div`
  margin: 10px 0;
`;

const IngridientsInputContainer = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  align-items: center;
`;

const StyledAddIngridientsButton = styled(Button).attrs(() => ({
  variant: "contained",
}))`
  &&& {
    background-color: #0b4d6e;
    color: white;
    width: 180px;
  }
`;

const StyledTextField = styled(TextField).attrs(() => ({
  variant: "outlined",
}))`
  &&& {
    outline: none;
    border-radius: 5px;
    border-color: rgba(0, 0, 0, 0.23);

    border: ${({ error }) =>
      error
        ? "1px solid #f44336"
        : ""} /*TO DO: Check border and fix condition*/

    &:focus {
      border: 2px solid #3f51b5;
    }
  }
`;

const DropdownContainer = styled.div`
  &&& {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 5;
    background-color: #d8d8d8;
    min-width: 500px;
    max-height: 200px;
    overflow: auto;
    border-radius: 3px;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: white;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 50px;
  }
`;

const IngridientsContainer = styled.div`
  border: 1px solid black;
  min-height: 50px;
  border-radius: 3px;
`;

const StyledRemoveIngridientsButton = styled(Button)`
  &&& {
    position: relative;
    background-color: #0b4d6e;
    color: white;
    min-width: 100px;
    margin: 0.5rem;

    & span span {
      background-color: #fff;
      color: black;
      position: absolute;
      width: 20px;
      height: 20px;
      top: -5px;
      right: 0px;
      }
    }
  }
`;

const DropdownField = styled.p`
  border-bottom: 1px solid black;
  padding: 10px 4px 10px 4px;
  margin: 0px;
  text-align: left;

  &:hover {
    background-color: rgb(11, 77, 110);
    cursor: pointer;
    color: #fff;
  }
`;

const FileWrapper = styled.div`
  display: flex;
  margin: 10px 0;
`;

const FileUpload = styled.div`
  margin-top: 10px;

  & button {
    background: white;
    outline: none;
    cursor: pointer;
    padding: 5px 20px;
    color: #ef403b;
    border: 2px solid #ef403b;
    border-radius: 5px;
    text-transform: capitalize;
    font-weight: 600;
  }

  & input {
    display: none;
  }

  & button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const UploadedFile = styled.div`
  width: 35px;
  height: 35px;
  margin-left: 40px;
  position: relative;
  border-radius: 3px;
  border: 2px solid red;
  background-size: cover;
  margin-top: 10px;
  display: inline-block;
`;

const DeleteIcon = styled.div`
  color: #100f0f;
  position: absolute;
  top: -6px;
  left: 22px;
  cursor: pointer;
  background-color: #fff;
  padding: 0px;
  width: 0px;
  height: 0px;
`;

const StyledCancelIcon = styled(Cancel)`
  &&& {
    background-color: white;
    border-radius: 5px;
    height: 15px;
    width: 15px;
  }
`;

const StyledCloseButton = styled(Button).attrs(() => ({
  variant: "contained",
}))`
  &&& {
    background-color: white;
    color: #ef403b;
    border: 1px solid #ef403b;
    //width: 142px;
    padding: 5px 30px !important;
    border-radius: 8px !important;
    font-weight: bold !important;
    margin: 10px !important;
  }
`;

const ButtonContainer = styled.div`
  // width: 50%;
  // margin: 30px auto 20px auto;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  margin-bottom: 0;
`;

const StyledDeleteButton = styled(Button).attrs(() => ({
  variant: "contained",
}))`
  &&& {
    background-color: white;
    color: #ef403b;
    border: 1px solid #ef403b;
    //width: 142px;
    padding: 5px 30px !important;
    border-radius: 8px !important;
    font-weight: bold !important;
    margin: 10px !important;
  }
`;

const StyledCancelButton = styled(Button).attrs(() => ({
  variant: "contained",
}))`
  &&& {
    background-color: white;
    color: #ef403b;
    border: 1px solid #ef403b;
    //width: 142px;
    padding: 5px 30px !important;
    border-radius: 8px !important;
    font-weight: bold !important;
    margin: 10px !important;
  }
`;

const StyledSaveButton = styled(Button).attrs(() => ({ variant: "contained" }))`
  &&& {
    background-color: #ef403b;
    color: white;
    //width: 142px;
    padding: 5px 30px !important;
    border-radius: 8px !important;
    font-weight: bold !important;
    margin: 10px !important;
  }
`;

const StyledSelect = styled(Select)`
  &&& {
    text-align: start;
  }
`;

const StyledInputLabel = styled(InputLabel)`
  &&& {
    color: ${({ isError }) => (isError ? "red" : "")};
  }
`;

const SubCategoryContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledCheckbox = styled(Checkbox)`
  &&& {
    color: black;
  }
`;

const IngridientsDropdownContainer = styled.div`
  position: relative;
`;

const MenuBarContainer = styled.div`
  display: flex;
  gap: 30px;
  border-bottom: 1px solid #d8d8d8;
  overflow: hidden;
  height: 50px;
`;

const SubcategoryContainer = styled(MenuBarContainer)`
  margin-top: 1.5rem;
`;

const CategoryView = styled.div`
  min-width: 120px;
  border: none;
  height: 45px;
`;

const CategoryName = styled.p`
  display: inline-block;
  height: 27px;
  min-width: 30px;
  whitespace: nowrap;
  max-width: 150px;
  ${({ isActive }) =>
    isActive &&
    `border-bottom: 3px solid #ef403b;
    padding-bottom: 5px;
    font-weight: bold;
  `};
`;

const SubcategoryName = styled.div`
  margin-top: 20px;
  font-weight: bold;
  padding-left: 40px;
`;

const MenuItemsListContainer = styled.div`
  padding: 40px;
  margin: 0;
`;

const StyledMenuItemsHeaderContainer = styled(Box)`
  &&& {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
  }
`;

const StyledAddNewDishButton = styled(Button).attrs(() => ({
  variant: "outlined",
}))`
  &&& {
    border: 1px solid #ef403b;
    color: #ef403b;
    font-weight: 600;
    // margin: 20px 0;
  }
`;

const StyledSpaceLeft = styled(Button)`
  &&& {
    font-weight: 600;
    margin: 20px 0;
  }
`;

const StyledMenuItemsHeader = styled(Typography).attrs(() => ({
  component: "h1",
  variant: "h5",
}))`
  &&& {
    /* Media query for phones */
    @media only screen and (max-width: 1300px) {
      padding-left: 25%;
    }

    /* Media query for desktops and larger screens */
    @media only screen and (min-width: 1300px) {
      padding-left: 40%;
    }

    font-weight: 600;
    margin-bottom: 20px;
  }
`;

const ButtonDescription = styled.div`
  margin-right: 2rem;
  flex-grow: 0.8;
  color: black;
  font-weight: 500;
  text-align: left;
  font-size: 14px;
`;

const selected = styled.div`
  text-align: left;
`;

const ScrollButton = styled.button`
  display: inline-block;
  width: 70px;
  height: 35px;
  background-color: #fff;
  border: none;
  color: #ef403b;
  font-size: 24px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  &:hover {
    transform: scale(1.1);
  }
`;

const ScrollButtonLeft = styled(ScrollButton)`
  left: 0;
  padding: 5px;
  margin-top: 10px;
  color: #ef403b;
`;

const ScrollButtonRight = styled(ScrollButton)`
  right: 0;
  padding: 5px;
  margin-top: 10px;
  color: #ef403b;
`;

export {
  ScrollButton,
  ScrollButtonLeft,
  ScrollButtonRight,
  CategoryView,
  ModalContentContainer,
  CategoryContainer,
  InputContaner,
  DishToggleBox,
  ContentContainer,
  StyledHeader,
  StyledHintContainer,
  ModalForm,
  HelperText,
  TextAreaWrapper,
  IngridientsInputContainer,
  StyledAddIngridientsButton,
  StyledTextField,
  DropdownContainer,
  IngridientsContainer,
  StyledRemoveIngridientsButton,
  DropdownField,
  FileWrapper,
  FileUpload,
  UploadedFile,
  DeleteIcon,
  StyledCancelIcon,
  StyledCloseButton,
  ButtonContainer,
  StyledDeleteButton,
  StyledSaveButton,
  StyledCancelButton,
  StyledSelect,
  StyledInputLabel,
  SubCategoryContainer,
  StyledCheckbox,
  IngridientsDropdownContainer,
  MenuBarContainer,
  SubcategoryContainer,
  CategoryName,
  SubcategoryName,
  MenuItemsListContainer,
  StyledMenuItemsHeaderContainer,
  StyledAddNewDishButton,
  StyledMenuItemsHeader,
  ButtonDescription,
  selected,
  DescriptionText,
  PriceTitle,
  DefaultText,
  StyledSpaceLeft,
};
