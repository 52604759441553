import styled from "styled-components/macro";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

const StyledCancelButton = styled(Button).attrs(() => ({
  variant: "contained",
}))`
  &&& {
    background-color: white;
    color: #ef403b;
    border: 1px solid #ef403b;
    width: 80px;
    margin-right: 10px;
  }
`;

const StyledConfirmButton = styled(Button).attrs(() => ({
  variant: "contained",
}))`
  &&& {
    background-color: #ef403b;
    color: white;
    border: 1px solid #ef403b;
    width: 80px;
    margin-right: 10px;
  }
`;

const StyledDialogContentText = styled(DialogContentText)`
  &&& {
    font-weight: bold;
    color: black;
  }
`;

const StyledDialogActions = styled(DialogActions)`
  &&& {
    justify-content: center;
    padding-bottom: 1.5rem;
  }
`;

export {
  StyledCancelButton,
  StyledConfirmButton,
  StyledDialogContentText,
  StyledDialogActions,
};
