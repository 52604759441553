import React, { useState, useRef } from "react";
import {
  CheckboxTextWrapper,
  Featured,
  StyledCheckbox,
  EditButton,
  FileWrapper,
  FileUpload,
  UploadedFile,
  DeleteIcon,
  StyledCancelIcon,
  IconsContainer,
  StyledCheckCircleOutline,
  StyledHighlightOff,
} from "./styles";
import {
  Delete as DeleteOutlineIcon,
  Edit as EditOutlineIcon,
} from "@material-ui/icons";
import { db } from "../../../../../firebase";
import { doc, setDoc } from "firebase/firestore";
import {
  IconButton,
  Button,
  FormControl,
  TextField,
  CircularProgress,
} from "@material-ui/core";

import Modal from "../../deleteModal/DeleteModal";

// import menu from "../../../../../assets/category-menu.png";

function FeaturedCard(props) {
  const multiInputImage = useRef([]);
  const [open, setOpen] = useState(false);
  const [showField, setShowField] = useState(false);

  const [selectedField, setSelectedField] = useState("");
  const [featureItems, setFeatureItems] = useState({
    name: "",
    background: "",
    url: "",
    color: "",
    isEnabled: false,
  });

  const [errors, setErrors] = useState({});
  const {
    index,
    FeaturedData,
    handleFeaturedChecked,
    DeleteFeatured,
    handleFileChange,
    isloading,
    editableValue,
    handleDeleteFileEdit,
    featureItemsList,
    setFeatureItemsList,
    setDataSubmitted,
    // setData,
    // handleSubmit,
    // id,
    // onDragStart,
    // onDragEnter,
    // onDragEnd,
    // handleDeleteCategory,
  } = props;

  const validate = () => {
    const errors = {};

    errors.name =
      featureItems.name.length > 0 && featureItems?.name.length <= 100
        ? ""
        : "Description over 100 characters";
    errors.name = featureItems.name.length > 0 ? "" : "Description Is Required";
    // errors.url =
    //   featureItems.url.length > 0 ? "" : "Background image Is Required";

    setErrors({ ...errors });
    return Object.values(errors).every((error) => error === "");
  };
  const handleOpenClickForEdit = (val) => {
    console.log("handleOpenClickForEdit" + val);
    // setIsUpdate(true);
    setFeatureItems(val);

    setSelectedField(val.name);
    setShowField((prev) => true);
  };

  const handleChange = (e) => {
    setFeatureItems((prev) => {
      return {
        ...prev,
        name: e.target.value,
      };
    });
  };
  const newSaveSubmit = async () => {
    console.log(featureItems.name);
    console.log(selectedField);

    if (validate()) {
      const docRef = doc(db, "restaurants", localStorage.getItem("id"));
      // const docSnap = await getDoc(docRef);

      var finaldata = featureItemsList.map((x) => {
        if (x.name === selectedField) {
          return {
            ...featureItems,
          };
        }
        return {
          ...x,
        };
      });
      setFeatureItemsList(finaldata);
      console.log(finaldata);

      setDoc(docRef, { featured: [...finaldata] }, { merge: true }).then(
        (doc) => {
          setDataSubmitted((prev) => !prev);
          setShowField((prev) => false);
        }
      );
    }
  };

  const handleClose = () => setOpen(false);
  return (
    <div key={index}>
      <CheckboxTextWrapper key={index}>
        {/* <img src={menu} alt="" height="20" /> */}
        <StyledCheckbox
          onChange={(e, v) => {
            handleFeaturedChecked(index, v);
          }}
          checked={FeaturedData.isEnabled}
        />
        <Featured>{FeaturedData.name}</Featured>
        <EditButton onClick={() => handleOpenClickForEdit(FeaturedData)}>
          <EditOutlineIcon style={{ color: "red" }} />
        </EditButton>

        {/* <button onClick={() => handleDeleteCategory(data.id)}>Delete</button> */}

        <IconButton onClick={() => setOpen(true)}>
          <DeleteOutlineIcon style={{ color: "red" }} />
        </IconButton>
        {open && (
          <Modal
            title={"Delete Featured"}
            open={open}
            handleDeleteCategory={() => {
              DeleteFeatured(index);
              handleClose();
            }}
            handleClose={handleClose}
            message={"Do you want to delete the featured?"}
          />
        )}
      </CheckboxTextWrapper>
      {showField && (
        <CheckboxTextWrapper>
          <FormControl margin="normal" required fullWidth>
            <TextField
              label="Description"
              onChange={(e) => handleChange(e)}
              name="description"
              value={featureItems.name}
              error={errors.name !== undefined && errors.name !== ""}
              helperText={featureItems.name && errors.name}
              variant="outlined"
            />
          </FormControl>
          <IconsContainer>
            <StyledCheckCircleOutline
              onClick={() => {
                newSaveSubmit();
              }}
            />
            <StyledHighlightOff
              onClick={() => {
                setFeatureItems({
                  name: "",
                  background: "",
                  url: "",
                  color: "",
                  isEnabled: false,
                });

                setShowField((prev) => false);
              }}
            />
          </IconsContainer>
        </CheckboxTextWrapper>
      )}
      <FileWrapper>
        <FileUpload>
          <input
            type="file"
            style={{ display: "none" }}
            ref={(element) => {
              multiInputImage.current[index] = element;
            }}
            onChange={(e) => handleFileChange(e, "featured", FeaturedData)}
            name="backgroundImage"
          />

          <Button
            disabled={FeaturedData.url ? true : false}
            onClick={() => {
              multiInputImage.current[index].click();
              // setEditableValue(val);
            }}
          >
            + Add Background Image
          </Button>
        </FileUpload>
        {isloading && editableValue.url === FeaturedData.url && (
          <CircularProgress />
        )}

        {FeaturedData.url && (
          <UploadedFile>
            <img width={35} height={35} src={FeaturedData.url} alt="cover" />
            <DeleteIcon
              onClick={() => {
                handleDeleteFileEdit(FeaturedData);
              }}
            >
              <StyledCancelIcon />
            </DeleteIcon>
          </UploadedFile>
        )}
      </FileWrapper>
    </div>
  );
}

export default FeaturedCard;
