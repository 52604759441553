import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./components/login/loginSignupSlice";
import isSlice from "./components/restoList/tableSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    id: isSlice,
  },
});

export default store;
