import React, { useState } from "react";
import { Link } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { Button, FormControl } from "@material-ui/core";
import { doc, setDoc } from "firebase/firestore";
import { makeAPiCall } from "../../services/api";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { db } from "../../firebase";
import validate from "../../formValidations";
import Footer from "../footer/Footer";

import {
  Container,
  Form,
  StyledHeader,
  SignUpWrapper,
  ButtonContainer,
  ShowPasswordButton,
  styles,
  ImageForm,
  StyledTextField,
  StyledTextFieldPassword,
} from "./styles";
import details from "../../assets/details.png";

function SignUpForm() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    subscriptionPlan: "Gold",
    enabled: "yes",
  });

  const [errors, setErrors] = useState({});
  const [isLoading, setIsloading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleChange = (event) => {
    const { value, name } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validateError = Object.values(validate(formData)).every(
      (error) => error === ""
    );

    if (validateError) {
      setIsloading((prev) => !prev);
      const status = formData.enabled === "yes" ? "published" : "disabled";

      localStorage.setItem("status", status);

      const docData = {
        resturantAccountName: formData.name,
        email: formData.email,
        subscriptionPlan: formData.subscriptionPlan,
        status,
      };
      createUserWithEmailAndPassword(auth, formData.email, formData.password)
        .then(async (userCredential) => {
          // Signed up
          const user = userCredential.user;
          const { uid } = userCredential.user;
          localStorage.setItem("uid", uid);
          localStorage.setItem("user", JSON.stringify(user));

          await setDoc(doc(db, "users", uid), {
            email: formData.email,
            uid: uid,
            role: "admin",
          })
            .then((data) => {
              makeAPiCall("POST", "add-account-details", {
                ...docData,
                restaurantId: uid,
                uid,
              })
                .then((data) => {
                  setIsloading((prev) => !prev);
                  navigate("/register/restaurant-details");
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          if (error.code === "auth/email-already-in-use") {
            setErrors((prev) => {
              return {
                name: "",
                password: "",
                email: "Email already in use",
              };
            });
            setIsloading(false);
          } else if (error.code === "auth/weak-password") {
            setIsloading(false);
          } else {
            console.log(error);
            setIsloading(false);
          }

          // ..
        });
    } else {
      setErrors({ ...validate(formData) });
    }
  };

  return (
    <>
      <div style={{ minHeight: "calc(100vh - 175px)" }}>
        <Container>
          <Form onSubmit={handleSubmit}>
            <StyledHeader>Sign Up</StyledHeader>
            <FormControl margin="normal" required fullWidth>
              <SignUpWrapper>
                <StyledTextField
                  label="Restaurant Account Name"
                  onChange={handleChange}
                  name="name"
                  value={formData.name}
                  error={errors.name && errors.name.length > 0}
                  helperText={errors.name}
                  variant="outlined"
                />
              </SignUpWrapper>
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <SignUpWrapper>
                <StyledTextField
                  label="Login Email"
                  onChange={handleChange}
                  name="email"
                  value={formData.email}
                  error={errors.email && errors.email.length > 0}
                  helperText={errors.email}
                  variant="outlined"
                  autoComplete="none"
                />
              </SignUpWrapper>
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <SignUpWrapper>
                <ShowPasswordButton
                  onClick={() => {
                    setShowPassword((prev) => !prev);
                  }}
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </ShowPasswordButton>
                <StyledTextFieldPassword
                  label="Password (Min 6 chars)"
                  onChange={handleChange}
                  name="password"
                  type="password"
                  value={formData.password}
                  error={errors.password && errors.password.length > 0}
                  helperText={errors.password}
                  variant="outlined"
                  inputProps={{
                    type: `${showPassword ? "text" : "password"}`,
                    autoComplete: "new-password",
                  }}
                />
              </SignUpWrapper>
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <SignUpWrapper>
                <ShowPasswordButton
                  onClick={() => {
                    setShowConfirmPassword((prev) => !prev);
                  }}
                >
                  {showConfirmPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </ShowPasswordButton>
                <StyledTextFieldPassword
                  label="Confirm Password (Min 6 chars)"
                  onChange={handleChange}
                  name="confirmPassword"
                  type="password"
                  value={formData.confirmPassword}
                  error={
                    errors.confirmPassword && errors.confirmPassword.length > 0
                  }
                  helperText={errors.confirmPassword}
                  variant="outlined"
                  inputProps={{
                    type: `${showConfirmPassword ? "text" : "password"}`,
                    autoComplete: "new-password",
                  }}
                />
              </SignUpWrapper>
            </FormControl>
            <ButtonContainer>
              <Button
                variant={"contained"}
                type="submit"
                className="form-button"
                color="primary"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <CircularProgress color="primary" size={28} />{" "}
                  </>
                ) : (
                  "Sign Up"
                )}
              </Button>
              <Link to="/login">Log In</Link>
            </ButtonContainer>
          </Form>
          <ImageForm>
            <div style={styles}>
              <img
                src={details}
                alt=""
                style={{
                  width: "auto",
                  height: "auto",
                  resize: "none",
                  maxHeight: "90%",
                  maxWidth: "80%",
                }}
              />
            </div>
          </ImageForm>
        </Container>
      </div>

      <Footer />
    </>
  );
}

export default SignUpForm;
