import styled from "styled-components/macro";

const Header = styled.div`
  margin: 0px 0px 10px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: 1;
  background-color: white;
  padding: 1rem;
`;

const HeaderLogo = styled.div`
  background-color: white;
  padding: 0 20px;
  cursor: pointer;
`;
const Button = styled.div`
  padding-right: 2rem;
  cursor: pointer;
  font-weight: bold;
`;

export { Header, HeaderLogo, Button };
