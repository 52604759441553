import styled from "styled-components/macro";
import { Button, Typography } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";

const Container = styled.div`
  padding: 40px;
`;
const StyledForm = styled.form`
  width: 100%;
  margin: 0 auto;
`;

const StyledHeader = styled(Typography).attrs(() => ({
  component: "h1",
  variant: "h5",
}))`
  text-align: center;
  margin-bottom: 20px;
  font-weight: 600 !important;
  text-transform: capitalize;
`;

const StyledTips = styled(Typography).attrs(() => ({
  component: "h1",
  // variant: "h6",
}))`
  display: flex;
  align-items: center;
  margin: 0px 20px !important;
  margin-top: 50px;
  font-size: 15px;
  // width: 60%;
  padding: 20px;
  border-bottom: 1px solid #d8d8d8;
  justify-content: space-between;
`;

const StyledTips1 = styled(Typography).attrs(() => ({
  component: "h1",
  // variant: "h6",
}))`
  display: flex;
  align-items: center;
  margin: 0px 20px !important;
  font-size: 15px;
  // width: 60%;
  padding: 20px;
  justify-content: space-between;
`;

const ItemWrapper = styled.div`
  margin: 10px 0;
`;

const CheckboxTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FileWrapper = styled.div`
  display: flex;
  margin: 10px 0;
`;
const FileUpload = styled.div`
  width: 230px;

  && button {
    background: white;
    outline: none;
    cursor: pointer;
    padding: 5px 20px;
    color: #ef403b;
    border: 2px solid #ef403b;
    border-radius: 5px;
    text-transform: capitalize;
    font-weight: 600;
  }

  && button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const UploadedFile = styled.div`
  width: 35px;
  height: 35px;
  margin-left: 40px;
  position: relative;
  border-radius: 3px;
  border: 2px solid red;
  background-size: cover;
`;

const DeleteIcon = styled.div`
  color: #100f0f;
  position: absolute;
  top: -6px;
  left: 22px;
  cursor: pointer;
  background-color: #fff;

  padding: 0px;
  width: 0px;
  height: 0px;
`;

const ButtonsContainer = styled.div`
  // width: 50%;
  // margin: 30px auto 20px auto;
  // display: flex;
  // justify-content: space-around;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  margin-bottom: 0;
`;

const HelperText = styled.div`
  color: #f44336;
  margin: 0 0 0 14px;
  font-size: 12px;
`;

const StyledCancelIcon = styled(CancelIcon)`
  && {
    background-color: white;
    border-radius: 5px;
    height: 15px;
    width: 15px;
  }
`;

const StyledAddFeaturedButton = styled(Button).attrs(() => ({
  variant: "text",
}))`
  && {
    margin-top: 1rem;
    color: #ef403b;
    font-weight: 600;
  }
`;

const StyledCancelButton = styled(Button).attrs(() => ({
  variant: "contained",
}))`
  &&& {
    background-color: white;
    color: #ef403b;
    border: 1px solid #ef403b;
  }
`;
const StyledSaveButton = styled(Button).attrs(() => ({
  variant: "contained",
}))`
  &&& {
    background-color: #ef403b;
    color: white;
  }
`;

export {
  Container,
  StyledForm,
  StyledHeader,
  StyledTips,
  ItemWrapper,
  CheckboxTextWrapper,
  FileWrapper,
  FileUpload,
  UploadedFile,
  DeleteIcon,
  ButtonsContainer,
  HelperText,
  StyledCancelIcon,
  StyledAddFeaturedButton,
  StyledCancelButton,
  StyledSaveButton,
  StyledTips1,
};
