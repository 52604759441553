import styled from "styled-components/macro";

const DishContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  padding-left: 40px;
  margin-bottom: 5px;
  width: 90%;
  margin-top: 10px;
`;

const IconsContainer = styled.div`
  margin-right: 5px;
  margin-top: 5px;
`;

const DishName = styled.div`
  margin-right: 2rem;
  flex-grow: 1;
  color: black;
  font-weight: 600;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`;

const EditAction = styled.div`
  color: #0085ff;
  font-weight: 600;
  cursor: pointer;
  margin-left: 0.5rem;
`;

const Price = styled.div`
  margin-right: 0.5rem;
  font-weight: 600;
`;

export { DishContainer, IconsContainer, DishName, Actions, EditAction, Price };
