import React, { useState, useRef } from "react";
import SubCategoriesCard from "./SubCategoriesCard";
import TextFieldCard from "./TextFieldCard";
import { IconButton } from "@material-ui/core";
import Modal from "../../deleteModal/DeleteModal";
import menu from "../../../../../assets/category-menu.png";
import {
  CategoryWrapper,
  Category,
  StyledAddSubcategoryButton,
  EditButton,
  SubCategories,
  StyledCheckbox,
  CategoryTips,
} from "./styles";

import {
  Delete as DeleteOutlineIcon,
  Edit as EditOutlineIcon,
} from "@material-ui/icons";

function CategoriesCard(props) {
  const dragItem = useRef();
  const dragOverItem = useRef();
  const {
    data,
    setData,
    handleSubmit,
    id,
    onDragStart,
    onDragEnter,
    onDragEnd,
    handleDeleteCategory,
  } = props;
  const [showTextField, setShowTextField] = useState(false);
  const [editSubValue, setEditSubValue] = useState("");
  const [editSubId, setEditSubId] = useState("");
  const [open, setOpen] = useState(false);
  const [isCategorySelected, setIsCategorySelected] = useState(
    !data.isDisabled ?? true
  );
  const [editCategoryValue, setEditCategoryValue] = useState("");
  const [editCategoryId, setEditCategoryId] = useState("");
  const [showCategoryTextField, setShowCategoryTextField] = useState(false);

  const handleOpenClick = (val, id) => {
    setEditCategoryId(id);
    setEditCategoryValue(val);
    setShowCategoryTextField(true);
  };

  const handlerSubEdit = (data, id) => {
    setEditSubValue(data);
    setEditSubId(id);
    setShowTextField(true);
  };

  const handleCancelClick = () => {
    setShowTextField(false);
    setShowCategoryTextField(false);
  };

  const handleCategoryChecked = (e) => {
    if (e.target.checked) {
      setIsCategorySelected(true);
    }
    setData((prev) => {
      var updateData = prev.map((val) => {
        if (data.id === val.id) {
          return {
            id: val.id,
            checkCategory: !val.checkCategory,
            name: val.name,
            order: val.order,
            subcategories: val.subcategories.map((subCateData) => {
              if (!e.target.checked) {
                return {
                  ...subCateData,
                  checkSubCategoryChecked: !val.checkCategory,
                };
              }
              return subCateData;
            }),
          };
        }
        return val;
      });
      handleSubmit(updateData);
      return updateData;
    });
  };

  const handleClose = () => setOpen(false);

  const dragStart = (e, position) => {
    console.log("Get Details:::", e);
    dragItem.current = position;
    console.log(e.target.innerHTML);
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
    console.log(e.target.innerHTML);
  };

  const drop = (e) => {
    let dataArray = data.subcategories;
    let allData = [];
    setData((prev) => (allData = prev));
    console.log("Get Details:::", allData);
    const copyListItems = [...dataArray];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    // allData[props.index].subcategories=copyListItems

    // setData(allData)
    setData((prev) => {
      var updateData = prev.map((val) => {
        if (data.id === val.id) {
          return {
            id: val.id,
            checkCategory: val.checkCategory,
            name: val.name,
            order: val.order,
            subcategories: copyListItems,
          };
        }
        return val;
      });
      handleSubmit(updateData);
      return updateData;
    });
    console.log("Get response:::", data);
  };

  const DeleteCategory = (id) => {
    setOpen(false);
    handleDeleteCategory(id);
  };

  return (
    <>
      <div>
        <CategoryWrapper
          onDragStart={onDragStart}
          onDragEnter={onDragEnter}
          onDragEnd={onDragEnd}
          key={props.index}
          draggable
        >
          <img src={menu} alt="" height="20" />
          <StyledCheckbox
            onChange={handleCategoryChecked}
            checked={data.checkCategory}
          />
          <Category>{data.name}</Category>
          {/* <EditButton onClick={() => handleOpenClick(data.name, data.id)}>
            Edit
          </EditButton> */}

          <EditButton onClick={() => handleOpenClick(data.name, data.id)}>
            <EditOutlineIcon style={{ color: "red" }} />
          </EditButton>

          {/* <button onClick={() => handleDeleteCategory(data.id)}>Delete</button> */}

          <IconButton onClick={() => setOpen(true)}>
            <DeleteOutlineIcon style={{ color: "red" }} />
          </IconButton>
          {open && (
            <Modal
              title={"Delete Category"}
              open={open}
              handleDeleteCategory={() => DeleteCategory(data.id)}
              handleClose={handleClose}
              message={
                "Do you want to delete the category? All sub-categories, if applicable, will be deleted, and dishes within will be marked as ‘Uncategorized’. The ‘Uncategorized’ items can be managed under the ‘Menu items’ page."
              }
            />
          )}
          {!isCategorySelected && (
            <CategoryTips>
              Please enable the category first to enable the sub category
            </CategoryTips>
          )}
        </CategoryWrapper>
        {showCategoryTextField && editCategoryValue ? (
          <TextFieldCard
            label="Category"
            setData={setData}
            handleSubmit={handleSubmit}
            handleCancelClick={handleCancelClick}
            editCategoryValue={editCategoryValue}
            setEditCategoryValue={setEditCategoryValue}
            editCategoryId={editCategoryId}
            setEditCategoryId={setEditCategoryId}
          />
        ) : null}
        <SubCategories>
          {data.subcategories.map((subCategory, index) => (
            <SubCategoriesCard
              subCategory={subCategory}
              data={data}
              key={subCategory.id}
              categoryId={id}
              id={subCategory.id}
              setData={setData}
              handleSubmit={handleSubmit}
              handleOpenClick={handlerSubEdit}
              isCategorySelected={isCategorySelected}
              setIsCategorySelected={setIsCategorySelected}
              index={index}
              onDragStart={(e) => dragStart(e, index)}
              onDragEnter={(e) => dragEnter(e, index)}
              onDragEnd={drop}
            />
          ))}
        </SubCategories>
      </div>
      {showTextField && editSubId ? (
        <TextFieldCard
          id={id}
          label="Sub Category"
          data={data}
          setData={setData}
          handleSubmit={handleSubmit}
          handleCancelClick={handleCancelClick}
          editCategoryValue={editSubValue}
          setEditCategoryValue={setEditSubValue}
          editCategoryId={editSubId}
          setEditCategoryId={setEditSubId}
        />
      ) : showTextField ? (
        <TextFieldCard
          id={id}
          label="Sub Category"
          data={data}
          setData={setData}
          handleSubmit={handleSubmit}
          handleCancelClick={handleCancelClick}
          setIsCategorySelected={setIsCategorySelected}
          isCategorySelected={isCategorySelected}
        />
      ) : null}
      <StyledAddSubcategoryButton onClick={() => setShowTextField(true)}>
        + Add Sub-Category
      </StyledAddSubcategoryButton>
    </>
  );
}

export default CategoriesCard;
