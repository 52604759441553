import React from "react";

const Progress_bar = ({ bgcolor, progress, height, index }) => {
  const Parentdiv = {
    height: height,
    width: "100%",
    backgroundColor: "whitesmoke",
    borderRadius: 40,
    marginLeft: 10,
  };

  const Childdiv = {
    height: "100%",
    width: `${progress}%`,
    backgroundColor: bgcolor,
    borderRadius: 40,
    textAlign: "right",
  };

  // const progresstext = {
  //   padding: 10,
  //   color: "black",
  //   fontWeight: 900,
  // };

  const progressBarRow = {
    display: "flex",
    fontWeight: 400,
    fontSize: 16,
    alignItems: "center",
  };

  return (
    <div style={progressBarRow}>
      {index}
      <div style={Parentdiv}>
        <div style={Childdiv}>
          {/* <span style={progresstext}>{`${progress}%`}</span> */}
        </div>
      </div>
    </div>
  );
};

export default Progress_bar;
