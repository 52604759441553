import React, { useState, useEffect } from "react";
import SidebarChip from "./SidebarChip";
import { sidebarList } from "../../constant";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../../firebase";

import { SidebarContainer } from "./styles";

function Sidebar(props) {
  const { selectedChip, handleChipClick } = props;
  const [sideList, setSideList] = useState([...sidebarList]);
  useEffect(() => {
    const getData = async () => {
      const docRef = doc(
        db,
        "users",
        JSON.parse(localStorage.getItem("user")).uid
      );

      const docSnap = await getDoc(docRef);

      if (docSnap.exists() && docSnap.data()?.role === "super admin") {
        // setSideList(sideList.filter((list) => list.id !== "home"));
        setSideList(sideList.filter((list) => list));
      }
    };

    getData();
  }, []);

  return (
    <SidebarContainer>
      {sideList.map((list) => (
        <SidebarChip
          key={list.id}
          list={list}
          selectedChip={selectedChip}
          handleChipClick={handleChipClick}
        />
      ))}
    </SidebarContainer>
  );
}

export default Sidebar;
