import React, { useEffect, useState } from "react";
import {
  ReviewCardWrapper,
  StarItem,
  ReviewCardLeft,
  ReviewCardRight,
  ReviewAvg,
  StarRow,
  TotalReview,
} from "../styles";
import { db } from "../../../../../../firebase";
import { collectionGroup, query, getDocs, where } from "firebase/firestore";
import Progressbar from "./Reatingbar";
function ReviewCard(props) {
  const { selectedFilter } = props;
  const [reviewData, setReviewData] = useState([]);

  const [totReviewCount, setTotReviewCount] = useState(0);
  const [averageRating, setAverageRating] = useState(0.0);
  const [noOfOneStarReviews, setNoOfOneStarReviews] = useState(0);
  const [noOfTwoStarReviews, setNoOfTwoStarReviews] = useState(0);
  const [noOfThreeStarReviews, setNoOfThreeStarReviews] = useState(0);
  const [noOfFourStarReviews, setNoOfFourStarReviews] = useState(0);
  const [noOfFiveStarReviews, setNoOfFiveStarReviews] = useState(0);
  useEffect(() => {
    const data = [];
    const getData = async () => {
      const museums = query(
        collectionGroup(db, "reviews"),
        where("restaurantId", "==", localStorage.getItem("uid"))
      );
      const querySnapshot = await getDocs(museums);
      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, reviewData: doc.data() });
      });

      setReviewData(data);
      filterReviewByDateRange(data, selectedFilter);
    };
    getData();
  }, []);
  useEffect(() => {
    filterReviewByDateRange(reviewData, selectedFilter);
  }, [selectedFilter]);

  function isBefore(date1, date2) {
    return date1 < date2;
  }
  function isAfter(date1, date2) {
    return date1 > date2;
  }
  function toDateTime(secs) {
    var t = new Date(Date.UTC(1970, 0, 1)); // Epoch
    t.setSeconds(secs);
    return t;
  }
  const filterReviewByDateRange = (reviewData, sf) => {
    var firstDate = new Date();
    var currentDate = new Date();
    var ratingSum = 0;
    var noOfOneStarReviews = 0;
    var noOfTwoStarReviews = 0;
    var noOfThreeStarReviews = 0;
    var noOfFourStarReviews = 0;
    var noOfFiveStarReviews = 0;
    var day;
    if (sf === "1w") {
      day = firstDate.getTime() - 7 * 24 * 60 * 60 * 1000;
      firstDate.setTime(day);
      console.log("7 day before " + firstDate);
      console.log(reviewData);
      let filteredData = reviewData.filter(
        (u) =>
          isBefore(toDateTime(u.reviewData.createdAt.seconds), currentDate) &&
          isAfter(toDateTime(u.reviewData.createdAt.seconds), firstDate)
      );
      filteredData.forEach((doc) => {
        var element = doc.reviewData;
        ratingSum += element.amountOfStars ?? 0;
        switch (element.amountOfStars) {
          case 1:
            noOfOneStarReviews += 1;
            break;
          case 2:
            noOfTwoStarReviews += 1;
            break;
          case 3:
            noOfThreeStarReviews += 1;
            break;
          case 4:
            noOfFourStarReviews += 1;
            break;
          case 5:
            noOfFiveStarReviews += 1;
            break;
          default:
            break;
        }
      });

      setTotReviewCount(filteredData.length);
      setAverageRating(
        filteredData.length === 0 ? 0.0 : ratingSum / filteredData.length
      );
      setNoOfOneStarReviews(noOfOneStarReviews);
      setNoOfTwoStarReviews(noOfTwoStarReviews);
      setNoOfThreeStarReviews(noOfThreeStarReviews);
      setNoOfFourStarReviews(noOfFourStarReviews);
      setNoOfFiveStarReviews(noOfFiveStarReviews);
    } else if (sf === "1m") {
      day = firstDate.getTime() - 30 * 24 * 60 * 60 * 1000;
      firstDate.setTime(day);
      console.log("30 day before " + firstDate);
      let filteredData = reviewData.filter(
        (u) =>
          isBefore(toDateTime(u.reviewData.createdAt.seconds), currentDate) &&
          isAfter(toDateTime(u.reviewData.createdAt.seconds), firstDate)
      );
      filteredData.forEach((doc) => {
        var element = doc.reviewData;
        ratingSum += element.amountOfStars ?? 0;
        switch (element.amountOfStars) {
          case 1:
            noOfOneStarReviews += 1;
            break;
          case 2:
            noOfTwoStarReviews += 1;
            break;
          case 3:
            noOfThreeStarReviews += 1;
            break;
          case 4:
            noOfFourStarReviews += 1;
            break;
          case 5:
            noOfFiveStarReviews += 1;
            break;
          default:
            break;
        }
      });

      setTotReviewCount(filteredData.length);
      setAverageRating(
        filteredData.length === 0 ? 0.0 : ratingSum / filteredData.length
      );
      setNoOfOneStarReviews(noOfOneStarReviews);
      setNoOfTwoStarReviews(noOfTwoStarReviews);
      setNoOfThreeStarReviews(noOfThreeStarReviews);
      setNoOfFourStarReviews(noOfFourStarReviews);
      setNoOfFiveStarReviews(noOfFiveStarReviews);
    } else if (sf === "1y") {
      day = firstDate.getTime() - 365 * 24 * 60 * 60 * 1000;
      firstDate.setTime(day);
      console.log("365 day before " + firstDate);
      let filteredData = reviewData.filter(
        (u) =>
          isBefore(toDateTime(u.reviewData.createdAt.seconds), currentDate) &&
          isAfter(toDateTime(u.reviewData.createdAt.seconds), firstDate)
      );
      filteredData.forEach((doc) => {
        var element = doc.reviewData;
        ratingSum += element.amountOfStars ?? 0;
        switch (element.amountOfStars) {
          case 1:
            noOfOneStarReviews += 1;
            break;
          case 2:
            noOfTwoStarReviews += 1;
            break;
          case 3:
            noOfThreeStarReviews += 1;
            break;
          case 4:
            noOfFourStarReviews += 1;
            break;
          case 5:
            noOfFiveStarReviews += 1;
            break;
          default:
            break;
        }
      });

      setTotReviewCount(filteredData.length);
      setAverageRating(
        filteredData.length === 0 ? 0.0 : ratingSum / filteredData.length
      );
      setNoOfOneStarReviews(noOfOneStarReviews);
      setNoOfTwoStarReviews(noOfTwoStarReviews);
      setNoOfThreeStarReviews(noOfThreeStarReviews);
      setNoOfFourStarReviews(noOfFourStarReviews);
      setNoOfFiveStarReviews(noOfFiveStarReviews);
    } else {
      console.log("All day before ");

      // let filteredData = reviewData.filter(
      //   (u) => isBefore(toDateTime(u.reviewData.createdAt.seconds), currentDate) && isAfter(toDateTime(u.reviewData.createdAt.seconds), firstDate)
      //   );
      reviewData.forEach((doc) => {
        var element = doc.reviewData;
        ratingSum += element.amountOfStars ?? 0;
        switch (element.amountOfStars) {
          case 1:
            noOfOneStarReviews += 1;
            break;
          case 2:
            noOfTwoStarReviews += 1;
            break;
          case 3:
            noOfThreeStarReviews += 1;
            break;
          case 4:
            noOfFourStarReviews += 1;
            break;
          case 5:
            noOfFiveStarReviews += 1;
            break;
          default:
            break;
        }
      });

      setTotReviewCount(reviewData.length);
      setAverageRating(
        reviewData.length === 0 ? 0.0 : ratingSum / reviewData.length
      );
      setNoOfOneStarReviews(noOfOneStarReviews);
      setNoOfTwoStarReviews(noOfTwoStarReviews);
      setNoOfThreeStarReviews(noOfThreeStarReviews);
      setNoOfFourStarReviews(noOfFourStarReviews);
      setNoOfFiveStarReviews(noOfFiveStarReviews);
    }
  };

  return (
    <ReviewCardWrapper>
      <ReviewCardLeft>
        <ReviewAvg>{averageRating.toFixed(1)}</ReviewAvg>
        <StarRow>
          <StarItem
            style={{
              color: Math.round(averageRating) >= 1 ? "#ef403b" : "#DADADA",
            }}
          />
          <StarItem
            style={{
              color: Math.round(averageRating) >= 2 ? "#ef403b" : "#DADADA",
            }}
          />
          <StarItem
            style={{
              color: Math.round(averageRating) >= 3 ? "#ef403b" : "#DADADA",
            }}
          />
          <StarItem
            style={{
              color: Math.round(averageRating) >= 4 ? "#ef403b" : "#DADADA",
            }}
          />
          <StarItem
            style={{
              color: Math.round(averageRating) >= 5 ? "#ef403b" : "#DADADA",
            }}
          />
        </StarRow>
        <TotalReview>{totReviewCount} reviews</TotalReview>
      </ReviewCardLeft>
      <ReviewCardRight>
        <Progressbar
          bgcolor="#ef403b"
          progress={
            totReviewCount === 0
              ? "0"
              : ((noOfFiveStarReviews / totReviewCount) * 100).toString()
          }
          height={3}
          index={5}
        />
        <Progressbar
          bgcolor="#ef403b"
          progress={
            totReviewCount === 0
              ? "0"
              : ((noOfFourStarReviews / totReviewCount) * 100).toString()
          }
          height={3}
          index={4}
        />
        <Progressbar
          bgcolor="#ef403b"
          progress={
            totReviewCount === 0
              ? "0"
              : ((noOfThreeStarReviews / totReviewCount) * 100).toString()
          }
          height={3}
          index={3}
        />
        <Progressbar
          bgcolor="#ef403b"
          progress={
            totReviewCount === 0
              ? "0"
              : ((noOfTwoStarReviews / totReviewCount) * 100).toString()
          }
          height={3}
          index={2}
        />
        <Progressbar
          bgcolor="#ef403b"
          progress={
            totReviewCount === 0
              ? "0"
              : ((noOfOneStarReviews / totReviewCount) * 100).toString()
          }
          height={3}
          index={1}
        />
      </ReviewCardRight>
    </ReviewCardWrapper>
  );
}

export default ReviewCard;
