import React, { useState, useRef } from "react";
import {
  Button,
  FormControl,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import Modal from "../../cancelModal/CancelModal";
import LoadingModal from "../../../../baseComponents/LoadingModal";
import done from "../../../../../assets/done.svg";
import { makeAPiCall } from "../../../../../services/api";
import { useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useEffect } from "react";
import { db, storage } from "../../../../../firebase";

import {
  Container,
  StyledForm,
  Tips,
  StyledDescriptionTextField,
  StyledHeader,
  FileUpload,
  FileUploadContainer,
  MultipleFile,
  HelperText,
  DeleteIcon,
  UploadedFile,
  StyledCancelIcon,
  // ButtonContainer,
  ButtonsContainer,
  StyledCancelButton,
  StyledSaveButton,
  StyledTextButton,
  SuccessWrapper,
  StyledCheckCircleOutline,
} from "./styles";

function Form() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    aboutUs: "",
    description: "",
    aboutCoverPhoto: null,
    aboutPhotos: [],
  });
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoadingCover, setIsloadingCover] = useState(false);
  const [isLoadingAboutPhoto, setIsloadingAboutPhoto] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [isSaveSuccess, setIsSaveSuccess] = useState(false);
  const coverInput = useRef();
  const aboutPhotosInput = useRef();

  useEffect(() => {
    if (localStorage.getItem("uid")) {
      localStorage.setItem("id", localStorage.getItem("uid"));
      const getData = async () => {
        const docRef = doc(db, "restaurants", localStorage.getItem("id"));
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setIsUpdate(true);
          setFormData({
            aboutUs: docSnap.data().about.headline,
            description: docSnap.data().about.description,
            aboutCoverPhoto: docSnap.data().about.aboutCoverPhoto,
            aboutPhotos: docSnap.data().about.aboutPhotos,

            // additional: [],
          });
        } else {
        }
      };
      getData();
    }
  }, []);

  const validate = () => {
    const errors = {};
    errors.aboutUs = formData.aboutUs ? "" : "Input Required";
    errors.aboutUs =
      formData?.aboutUs?.length > 100
        ? `Length should not be more than 100 characters. Current length: ${formData?.aboutUs?.length}`
        : "";
    errors.description = formData.description ? "" : "Description is Required";
    errors.description =
      formData?.description?.length > 500
        ? `Length should not be more than 500 characters. Current length: ${formData?.description?.length}`
        : "";
    // errors.aboutCoverPhoto = formData.aboutCoverPhoto ? "" : "Cover Required";
    setErrors({ ...errors });
    return Object.values(errors).every((error) => error === "");
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setIsloading(true);
      const { aboutUs, description, aboutCoverPhoto, aboutPhotos } = formData;
      const data = {
        uid: localStorage.getItem("uid"),

        about: {
          headline: aboutUs,
          description: description,
          aboutCoverPhoto: aboutCoverPhoto,
          aboutPhotos: aboutPhotos,
        },
      };

      if (isUpdate) {
        const washingtonRef = doc(
          db,
          "restaurants",
          localStorage.getItem("id")
        );

        await updateDoc(washingtonRef, {
          about: {
            headline: aboutUs,
            description: description,
            aboutCoverPhoto: aboutCoverPhoto,
            aboutPhotos: aboutPhotos,
          },
        }).then(() => {
          setIsloading(false);
          setIsSaveSuccess(true);
          setInterval(function () {
            setIsSaveSuccess(false);
          }, 5000);
          // navigate("/register/categories");
        });
      } else {
        makeAPiCall("POST", "add-about", data)
          .then((data) => {
            setIsloading(false);
            setIsSaveSuccess(true);
            setInterval(function () {
              setIsSaveSuccess(false);
            }, 5000);
            // navigate("/register/categories");
          })
          .catch((error) => {
            setIsloading(false);
            console.log(error);
          });
      }
    }
  };

  const handleFileChange = (event, type) => {
    const { files } = event.target;

    if (type === "aboutCoverPhoto") {
      setIsloadingCover(true);
    } else {
      setIsloadingAboutPhoto(true);
    }

    let randomId = Math.floor(Math.random() * 899999 + 100000);
    // setProgress(true);
    const storageRef = ref(storage, `${type}/${randomId}-${files[0].name}`);

    const uploadTask = uploadBytesResumable(storageRef, files[0]);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        // Handle unsuccessful uploads
        if (type === "aboutCoverPhoto") {
          setIsloadingCover(false);
        } else {
          setIsloadingAboutPhoto(false);
        }
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          if (type === "aboutCoverPhoto") {
            setFormData((prev) => {
              return {
                ...prev,
                aboutCoverPhoto: downloadURL,
              };
            });
            setIsloadingCover(false);
          } else {
            setFormData((prev) => {
              if (prev.aboutPhotos === undefined || prev.aboutPhotos === null) {
                return {
                  ...prev,
                  aboutPhotos: [downloadURL],
                };
              } else {
                return {
                  ...prev,
                  aboutPhotos: [...prev.aboutPhotos, downloadURL],
                };
              }
            });
            setIsloadingAboutPhoto(false);
          }
        });
      }
    );
  };

  const handleDeleteFile = (type, item) => {
    if (type === "aboutPhotos") {
      setFormData((prev) => {
        return {
          ...prev,
          aboutPhotos: [...prev.aboutPhotos.filter((val) => val !== item)],
        };
      });
    } else {
      setFormData((prev) => {
        return {
          ...prev,
          aboutCoverPhoto: null,
        };
      });
    }
  };

  const handleClose = () => setOpen(false);

  return (
    <Container>
      <StyledForm onSubmit={handleSubmit}>
        <StyledHeader>About Us</StyledHeader>
        {isSaveSuccess && (
          <SuccessWrapper>
            <StyledCheckCircleOutline></StyledCheckCircleOutline>Information has
            been saved successfully
          </SuccessWrapper>
        )}
        <Tips component="p" variant="p">
          <img src={done} alt="" />
          Let the customers know about who you are
        </Tips>
        <Tips component="p" variant="p">
          <img src={done} alt="" />
          Add some fancy photos of your restaurants to let users Know about
          dishes/ambience
        </Tips>
        <FormControl margin="normal" required fullWidth>
          <TextField
            label="About Us"
            onChange={handleChange}
            name="aboutUs"
            value={formData?.aboutUs}
            error={errors.aboutUs && errors.aboutUs.length > 0}
            helperText={errors.aboutUs}
            variant="outlined"
            style={{ marginTop: "14px", marginBottom: "8px" }}
          />
        </FormControl>
        {/* <div className={classes.description}>Description</div> */}
        <StyledDescriptionTextField
          onChange={handleChange}
          name="description"
          variant="outlined"
          fullWidth
          multiline
          value={formData?.description}
          error={errors.description && errors.description.length > 0}
          helperText={errors.description}
          minRows={4}
          label="Enter detailed Description"
          style={{
            marginTop: "14px",
            marginBottom: "8px",
            border:
              errors.description && errors.description.length > 0
                ? "1px solid #f44336"
                : "",
          }}
        />
        {errors.description && errors.description.length > 0 && (
          <HelperText>{errors.description}</HelperText>
        )}

        <FileUploadContainer>
          <FileUpload>
            <input
              type="file"
              style={{ display: "none" }}
              ref={coverInput}
              onChange={(e) => handleFileChange(e, "aboutCoverPhoto")}
            />
            <Button
              onClick={() => coverInput.current.click()}
              disabled={formData.aboutCoverPhoto || isLoadingAboutPhoto}
            >
              + Add Background Picture
            </Button>
          </FileUpload>
          {isLoadingCover && <CircularProgress />}
          {formData.aboutCoverPhoto && (
            <UploadedFile>
              <img
                width={35}
                height={35}
                src={formData.aboutCoverPhoto}
                alt="cover"
              />
              <DeleteIcon
                onClick={() => {
                  handleDeleteFile("aboutCoverPhoto");
                  coverInput.current.value = null;
                }}
              >
                <StyledCancelIcon />
              </DeleteIcon>
            </UploadedFile>
          )}
        </FileUploadContainer>
        {errors.aboutCoverPhoto && errors.aboutCoverPhoto.length > 0 && (
          <HelperText>{errors.aboutCoverPhoto}</HelperText>
        )}
        <FileUploadContainer>
          <FileUpload>
            <input
              type="file"
              style={{ display: "none" }}
              ref={aboutPhotosInput}
              multiple
              onChange={(e) => handleFileChange(e, "aboutPhotos")}
            />
            <Button
              disabled={isLoadingCover}
              onClick={() => aboutPhotosInput.current.click()}
            >
              + Add Additional Pictures
            </Button>
          </FileUpload>
          {isLoadingAboutPhoto && <CircularProgress />}
          <MultipleFile>
            {formData?.aboutPhotos?.length > 0 &&
              formData?.aboutPhotos?.map((item) => (
                <UploadedFile>
                  <div>
                    <img width={35} height={35} src={item} alt="cover" />
                  </div>
                  <DeleteIcon
                    onClick={() => {
                      handleDeleteFile("aboutPhotos", item);
                      aboutPhotosInput.current.value = null;
                    }}
                  >
                    <CancelIcon
                      style={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        height: "15px",
                        width: "15px",
                      }}
                    />
                  </DeleteIcon>
                </UploadedFile>
              ))}
          </MultipleFile>
        </FileUploadContainer>

        {/* <span className="error-msg">{loginErrorMsg}</span><br/><br/> */}
        {/* <ButtonContainer>
          <Button
            variant={"contained"}
            style={{
              backgroundColor: "white",
              color: "#ef403b",
              border: "1px solid #ef403b",
            }}
            onClick={() => setOpen(true)}
          >
            Cancel
          </Button>
          {open && <Modal open={open} handleClose={handleClose} />}
          <Button
            variant={"contained"}
            type="submit"
            style={{ backgroundColor: "#ef403b", color: "white" }}
            disabled={isLoadingAboutPhoto || isLoadingCover}
          >
            Save
          </Button>
        </ButtonContainer> */}

        <ButtonsContainer>
          <StyledCancelButton
            onClick={() => navigate("/register/restaurant-details")}
          >
            &lt;&nbsp;Previous
          </StyledCancelButton>
          {open && <Modal open={open} handleClose={handleClose} />}

          <StyledSaveButton onClick={() => navigate("/register/categories")}>
            Next&nbsp;&gt;
          </StyledSaveButton>
          <StyledTextButton
            type="submit"
            disabled={isLoadingAboutPhoto || isLoadingCover}
          >
            Save
          </StyledTextButton>
        </ButtonsContainer>
      </StyledForm>
      {isLoading && <LoadingModal />}
    </Container>
  );
}

export default Form;
