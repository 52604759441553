import React, { useState, useRef } from "react";
import "date-fns";
import TimeRangePicker from "@wojtekmaj/react-timerange-picker";
import {
  FormControl,
  TextField,
  InputLabel,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import { Delete as DeleteOutlineIcon } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import ListItemText from "@material-ui/core/ListItemText";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import Chip from '@material-ui/core/Chip';
// import Checkbox from "@material-ui/core/Checkbox";
import Modal from "../../cancelModal/CancelModal";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { initialState, generateId } from "../../../constant";
import { makeAPiCall } from "../../../../../services/api";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { geohashForLocation } from "geofire-common";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db, storage } from "../../../../../firebase";
import LoadingModal from "../../../../baseComponents/LoadingModal";
import { useSelector } from "react-redux";
import remove from "../../../../../assets/remove.png";

import {
  StyledHeader,
  FormContainer,
  DetailsForm,
  LocationContainer,
  StyledSelectLocationButton,
  HelperText,
  DeleteButton,
  AddMoreButton,
  FileWrapper,
  UploadedFile,
  StyledAddPictureButton,
  DeleteIconButton,
  StyledCancelIcon,
  TimingHeader,
  ButtonsContainer,
  StyledCancelButton,
  StyledSaveButton,
  StyledTextButton,
  MySelect,
  SuccessWrapper,
  StyledCheckCircleOutline,
} from "./styles";
// import { CloseRounded } from "@material-ui/icons";

const CuisineArray = [
  "Mexican",
  "Chinese",
  "Italian",
  "American",
  "Mediterranean",
  "Indian",
  "Thai",
  "Japanese/Sushi",
  "Spanish",
  "Steakhouse",
  "Bar",
  "Coffee",
  "Dessert",
  //  "Others",
];

function Form() {
  const navigate = useNavigate();
  const { id } = useSelector((state) => state.id);
  const [formData, setFormData] = useState({
    name: "",
    location: [{ ...initialState, id: generateId("location") }],
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    cuisine: [],
    phone: [{ ...initialState, id: generateId("phone") }],
    email: "",
    price: "",
    status: "",
    currency: "",
  });

  const [letLang, setLatLang] = useState({
    lat: "",
    lng: "",
  });
  const [thumbnail, setThumbnail] = useState(null);
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [IconProgress, setIconProgress] = useState(false);
  const [coverProgress, setCoverProgress] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [isSaveSuccess, setIsSaveSuccess] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  // const [isHaveOthers, setIsHaveOthers] = useState(false);
  const [selectedCuisine, setSelectedCuisine] = useState([]);
  const fileInput = useRef();
  const fileInputCover = useRef();

  // const [timeArray,setTimeArray] = useState([{time:'',timing:[{from:'',to:''},["", ""]]}])
  const [timeArray, setTimeArray] = useState([{ category: "", timing: [] }]);
  const [refresh, setRefresh] = useState(false);
  // const [otherCusine, setOtherCusine] = useState("");

  useEffect(() => {
    if (id || localStorage.getItem("uid")) {
      localStorage.setItem("id", localStorage.getItem("uid"));
      const getData = async () => {
        const docRef = doc(db, "restaurants", localStorage.getItem("uid"));
        const docSnap = await getDoc(docRef);

        if (docSnap.exists() && docSnap.data().name) {
          setIsUpdate(true);
          var phones = [
            { value: docSnap.data().phone, id: generateId("phone") },
          ];
          if (docSnap.data().otherPhones) {
            for (let i = 0; i < docSnap.data().otherPhones.length; i++) {
              const element = docSnap.data().otherPhones[i];
              phones.push({ value: element, id: generateId("phone") });
            }
          }
          setFormData({
            name: docSnap.data().name,
            addressLine1: docSnap.data().address.addressLine1,
            addressLine2: docSnap.data().address.addressLine2,
            email: docSnap.data().address.email,
            city: docSnap.data().address.city,
            state: docSnap.data().address.state,
            zipCode: docSnap.data().address.zipCode,
            country: docSnap.data().address.country,
            price: docSnap.data().priceRange,
            currency: docSnap.data().currency,
            location: [
              {
                id: generateId("location"),
                value: docSnap.data().address.city,
              },
            ],
            // cuisine: [
            //   "Mexican",
            //   "Chinese",
            //   "Italian",
            //   "American",
            //   "Mediterranean",
            //   "Indian",
            //   "Thai",
            //   "Japanese/Sushi",
            //   "Spanish",
            //   "Steakhouse",
            //   "Bar",
            //   "Coffee",
            //   "Dessert",
            // ].includes(docSnap.data().cuisine?.name || "")
            //   ? docSnap.data().cuisine.name
            //   : "others",
            phone: phones,
            // email: docSnap.data().email,
          });
          // if (
          //   ![
          //     "Mexican",
          //     "Chinese",
          //     "Italian",
          //     "American",
          //     "Mediterranean",
          //     "Indian",
          //     "Thai",
          //     "Japanese/Sushi",
          //     "Spanish",
          //     "Steakhouse",
          //     "Bar",
          //     "Coffee",
          //     "Dessert",
          //   ].includes(docSnap.data().cuisine.name)
          // ) {
          //   setOtherCusine(docSnap.data().cuisine.name);
          // } else {
          //   setOtherCusine("");
          // }
          if (isIterable(docSnap.data().cuisine)) {
            setSelectedCuisine(docSnap.data().cuisine);
          }

          // setCuisineId(docSnap.data().cuisine.cuisineId);
          setLatLang({
            lat: docSnap.data().coordinates.lat,
            lng: docSnap.data().coordinates.lng,
          });

          // if (brunch && brunch.trim() !== '') {
          //   brunch = brunch.split(' ');
          // } else {
          //   brunch = null;
          // }

          if (docSnap.data().timeCategory !== undefined) {
            let brunch = docSnap.data().timeCategory;
            setTimeArray(brunch);
          }
          // if (docSnap.data().timings.brunch != undefined) {
          //   let brunch = docSnap.data().timings.brunch.split("until ");
          //   setBrunch(brunch);
          // }

          // // let lunch = docSnap.data().timings.lunch.split(" ");
          // // let dinner = docSnap.data().timings.dinner.split(" ");

          // if (docSnap.data().timings.lunch != undefined) {
          //   let lunch = docSnap.data().timings.lunch.split("until ");
          //   setLunch(lunch);
          // }

          // if (docSnap.data().timings.dinner != undefined) {
          //   let dinner = docSnap.data().timings.dinner.split("until ");
          //   setDinner(dinner);
          // }
          // setLunch(lunch);
          // setDinner(dinner);
          setThumbnail(docSnap.data().icon);
          setCoverPhoto(docSnap.data().coverPhoto);
        } else {
          console.log("No such document!");
        }
      };
      getData();
    }
  }, [id]);

  const validate = () => {
    const errors = {};
    // const enteredLocationFields = formData.location.filter(
    //   (item) => item.value
    // );

    const enteredPhoneFields = formData.phone.filter((item) => item.value);
    errors.name = formData.name ? "" : "Restaurant Name Is Required";
    // errors.location =
    //   enteredLocationFields.length === formData.location.length
    //     ? ""
    //     : "Location Is Required";
    errors.addressLine1 = formData.addressLine1
      ? ""
      : "Address Line 1 is required";

    errors.city = formData.city ? "" : "City is required";
    // if (formData.cuisine === "others") {
    //   errors.otherCusine = otherCusine ? "" : "Cusine is required";
    // }

    errors.state = formData.state ? "" : "State is required";

    errors.zipCode = formData.zipCode ? "" : "ZIP Code is required";
    errors.country = formData.country ? "" : "Country is required";

    // errors.cuisine = formData.cuisine ? "" : "Cuisine Is Required";
    errors.phone =
      enteredPhoneFields.length === formData.phone.length
        ? ""
        : "Phone Is Required";
    errors.email = formData.email
      ? /.+@.+..+/.test(formData.email)
        ? ""
        : "Invalid Email"
      : "Email is Required";
    errors.price = formData.price ? "" : "Estimated Price Point Required";
    // errors.brunch =
    //   brunch[0].length > 2 && brunch[1].length > 2
    //     ? ""
    //     : "Brunch Timings Required";
    // errors.lunch =
    //   lunch[0].length > 2 && lunch[1].length > 2
    //     ? ""
    //     : "Lunch Timings Required";
    // errors.dinner =
    //   dinner[0].length > 2 && dinner[1].length > 2
    //     ? ""
    //     : "Dinner Timings Required";
    // errors.thumbnail = thumbnail ? "" : "Thumbnail Required";
    // errors.coverPhoto = coverPhoto ? "" : "Cover Photo Required";
    errors.currency = formData.currency ? "" : "Currency Required";

    setErrors({ ...errors });

    return Object.values(errors).every((error) => error === "");
  };

  // Adding latitude and longitude of current locations

  function isIterable(input) {
    if (input === null || input === undefined) {
      return false;
    }

    return typeof input[Symbol.iterator] === "function";
  }

  const getMyLocation = (e) => {
    let location = null;
    let latitude = null;
    let longitude = null;
    if (window.navigator && window.navigator.geolocation) {
      location = window.navigator.geolocation;
    }
    if (location) {
      location.getCurrentPosition(function (position) {
        latitude = position.coords.latitude;
        longitude = position.coords.longitude;

        setLatLang({
          lat: latitude,
          lng: longitude,
        });
      });
    }
  };

  const handleChange = (event, id) => {
    const { value, name } = event.target;
    console.log("val", value, name);

    if (name === "lat" || name === "lng") {
      return setLatLang((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
    setFormData((prev) => {
      if (name === "location" || name === "phone") {
        return {
          ...prev,
          [name]: prev[name].map((item) => {
            if (item.id === id) {
              return {
                ...item,
                value,
              };
            }
            return item;
          }),
        };
      }
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const handleChange2 = (event, val) => {
    const { value, name } = event.target;
    console.log("val", val, name);
    // const preventDuplicate = value.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
    const valnew = val.filter((v) => v.trim() !== "");
    setSelectedCuisine(valnew);
    console.log(selectedCuisine?.includes("Others") ?? false);
    console.log("Get Response Value in Array>>>", value);
    // setFormData((prev) => {
    //   return {
    //     ...prev,
    //     [name]: typeof preventDuplicate === 'string' ? preventDuplicate.split(',') : preventDuplicate,
    //   };
  };
  // const handleChange2 = (event, id) => {
  //   const { value, name } = event.target;
  //   console.log("val", value, name);
  //   const preventDuplicate = value.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
  //   setFormData((prev) => {
  //     return {
  //       ...prev,
  //       [name]: typeof preventDuplicate === 'string' ? preventDuplicate.split(',') : preventDuplicate,
  //     };
  //   });
  // };

  const handleChange1 = (event, index) => {
    const { value, name } = event.target;
    console.log("val", value, name, index);
    let added = false;
    if (value === "Other (I want to type my own)") {
      timeArray[index].category = "";
      timeArray[index].other = true;
      setRefresh(!refresh);
      timeArray[index].timing = [{ fromDay: "", toDay: "", time: ["", ""] }];
    } else {
      timeArray.map((item, index) => {
        if (item.category === value) {
          added = true;
        }
        return 0;
      });

      if (!added) {
        timeArray[index].category = value;
        setRefresh(!refresh);
        timeArray[index].timing = [{ fromDay: "", toDay: "", time: ["", ""] }];
      } else {
        alert("This option is already selected. Please other one.");
      }
    }
  };

  const handleChange3 = (event, index1, index, from) => {
    const { value, name } = event.target;
    console.log("val", value, name, index);

    if (from === "from") {
      timeArray[index].timing[index1].fromDay = value;
      setRefresh(!refresh);
    } else {
      timeArray[index].timing[index1].toDay = value;
      setRefresh(!refresh);
    }
  };

  const handleSubmit = async (e) => {
    console.log("formData.phone " + formData.phone);
    e.preventDefault();

    if (validate()) {
      setIsloading(true);
      // const getTimingValue = (openingTime) => {
      //   if (openingTime[0].length > 0 && openingTime[1].length > 0) {
      //     const start = openingTime[0];
      //     const end = openingTime[1];
      //     return `${start} until ${end}`;
      //   } else {
      //     return "";
      //   }
      // };
      console.log("formData.phone " + formData.phone);

      const data = {
        uid: localStorage.getItem("uid"),
        phone: formData.phone[0].value || "",
        otherPhones: formData.phone.slice(1).map((item) => item.value),
        // geohash: hash,
        coordinates: { lat: Number(letLang.lat), lng: Number(letLang.lng) },
        cuisine: [...selectedCuisine],
        // cuisine: {
        //   name: formData.cuisine === "others" ? otherCusine : formData.cuisine,
        //   cuisineId: cuisineId || Date.now().toString(),
        // },
        name: formData.name,
        address: {
          addressLine1: formData.addressLine1,
          addressLine2: formData.addressLine2,
          city: formData.city,
          state: formData.state,
          zipCode: formData.zipCode,
          country: formData.country,
          email: formData.email || "",
        },
        currency: formData.currency,
        icon: thumbnail,
        coverPhoto: coverPhoto,
        priceRange: parseInt(formData.price),
        timeCategory: timeArray,
        // timings: {
        //   brunch: getTimingValue(brunch),
        //   lunch: getTimingValue(lunch),
        //   dinner: getTimingValue(dinner),
        // },
      };

      if (isUpdate) {
        const geohash = geohashForLocation([
          Number(letLang.lat),
          Number(letLang.lng),
        ]);
        delete data.uid;
        data.geohash = geohash;
        setIsloading(false);

        const washingtonRef = doc(
          db,
          "restaurants",
          localStorage.getItem("id")
        );

        // Set the "capital" field of the city 'DC'
        await updateDoc(washingtonRef, {
          ...data,
        }).then(() => {
          // ...
          setIsloading(false);
          setIsSaveSuccess(true);
          setInterval(function () {
            setIsSaveSuccess(false);
          }, 5000);

          // navigate("/register/about-us");
        });
      } else {
        const geohash = geohashForLocation([
          Number(letLang.lat),
          Number(letLang.lng),
        ]);

        data.geohash = geohash;

        makeAPiCall("POST", "add-restaurants-details", data)
          .then(async (data) => {
            setIsloading(false);

            setIsSaveSuccess(true);
            setInterval(function () {
              setIsSaveSuccess(false);
            }, 5000);
            // navigate("/register/about-us");
          })
          .catch((error) => {
            setIsloading(false);
          });
      }
    }
  };

  const handleAddMore = (type) => {
    setFormData((prevData) => {
      return {
        ...prevData,
        [type]: [...prevData[type], { ...initialState, id: generateId(type) }],
      };
    });
  };

  const handleDelete = (id, type) => {
    setFormData((prevData) => {
      return {
        ...prevData,
        [type]: prevData[type].filter((item) => item.id !== id),
      };
    });
  };

  const handleFileChange = (event) => {
    const { files, name } = event.target;

    let ImageFolderName;
    let randomId = Math.floor(Math.random() * 899999 + 100000);
    if (name === "icon") {
      ImageFolderName = "thumbnail";
      setIconProgress(true);
    } else {
      ImageFolderName = "cover";
      setCoverProgress(true);
    }

    const storageRef = ref(
      storage,
      `${ImageFolderName}/${randomId}-${files[0].name}`
    );

    const uploadTask = uploadBytesResumable(storageRef, files[0]);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        // Handle unsuccessful uploads
        if (name === "icon") {
          setIconProgress(false);
        } else {
          setCoverProgress(false);
        }
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          if (name === "icon") {
            setThumbnail(downloadURL);
            setIconProgress(false);
          } else {
            setCoverPhoto(downloadURL);
            setCoverProgress(false);
          }
        });
      }
    );
  };

  const handleClose = () => setOpen(false);

  const onDeleteTime = async (index, index1) => {
    let allArray = timeArray[index].timing;
    let array = [];
    allArray.map((item, index2) => {
      if (index1 !== index2) {
        array.push(item);
      }
      return 0;
    });
    setRefresh(!refresh);
    timeArray[index].timing = array;
    setTimeArray(timeArray);
  };

  const onDeleteCategory = async (index) => {
    let array = [];
    timeArray.map((item, index2) => {
      if (index !== index2) {
        array.push(item);
      }
      return 0;
    });
    setRefresh(!refresh);
    setTimeArray(array);
  };

  const handleDeleteClick = (valueToDelete) => {
    // console.log(selectedCuisine.filter((value) => value != valueToDelete));

    setSelectedCuisine(
      selectedCuisine.filter((value) => value !== valueToDelete)
    );
  };

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, "0");
  }

  return (
    <FormContainer>
      <DetailsForm onSubmit={handleSubmit}>
        <StyledHeader>Restaurant Details</StyledHeader>
        {isSaveSuccess && (
          <SuccessWrapper>
            <StyledCheckCircleOutline></StyledCheckCircleOutline>Information has
            been saved successfully
          </SuccessWrapper>
        )}
        <FormControl margin="normal" required fullWidth>
          <TextField
            label="Restaurant Name"
            onChange={handleChange}
            name="name"
            value={formData.name}
            error={errors.name && errors.name.length > 0}
            helperText={errors.name}
            variant="outlined"
          />
        </FormControl>

        <FormControl margin="normal" required fullWidth>
          <TextField
            label="Address Line 1"
            onChange={handleChange}
            name="addressLine1"
            value={formData.addressLine1}
            error={errors.addressLine1 && errors.addressLine1.length > 0}
            helperText={errors.addressLine1}
            variant="outlined"
          />
        </FormControl>

        <FormControl margin="normal" fullWidth>
          <TextField
            label="Address Line 2"
            onChange={handleChange}
            name="addressLine2"
            value={formData.addressLine2}
            variant="outlined"
          />
        </FormControl>

        <div className="col-centered">
          <FormControl
            margin="normal"
            style={{ width: "48%", marginRight: "2%" }}
          >
            <TextField
              label="City"
              onChange={handleChange}
              name="city"
              value={formData.city}
              error={errors.city && errors.city.length > 0}
              helperText={errors.city}
              variant="outlined"
            />
          </FormControl>
          <FormControl
            margin="normal"
            style={{ width: "48%", marginLeft: "2%" }}
          >
            <TextField
              label="State"
              onChange={handleChange}
              name="state"
              value={formData.state}
              error={errors.state && errors.state.length > 0}
              helperText={errors.state}
              variant="outlined"
            />
          </FormControl>
        </div>

        <div className="col-centered">
          <FormControl
            margin="normal"
            style={{ width: "48%", marginRight: "2%" }}
          >
            <TextField
              label="ZIP Code"
              // type='number'
              onChange={handleChange}
              name="zipCode"
              value={formData.zipCode}
              error={errors.zipCode && errors.zipCode.length > 0}
              helperText={errors.zipCode}
              variant="outlined"
            />
          </FormControl>
          <FormControl
            margin="normal"
            style={{ width: "48%", marginLeft: "2%" }}
          >
            <TextField
              label="Country"
              onChange={handleChange}
              name="country"
              value={formData.country}
              error={errors.country && errors.country.length > 0}
              helperText={errors.country}
              variant="outlined"
            />
          </FormControl>
        </div>

        <LocationContainer
          style={{
            marginTop: "16px",
            marginBottom: "8px",
          }}
        >
          <TextField
            label="Latitude"
            name="lat"
            value={letLang.lat}
            // disabled={true}
            variant="outlined"
            onChange={(e) => {
              setLatLang((prev) => {
                return {
                  ...prev,
                  [e.target.name]: e.target.value,
                };
              });
            }}
          />
          <TextField
            label="Longitude"
            name="lng"
            value={letLang.lng}
            // disabled={true}
            variant="outlined"
            onChange={(e) => {
              setLatLang((prev) => {
                return {
                  ...prev,
                  [e.target.name]: e.target.value,
                };
              });
            }}
          />
        </LocationContainer>
        <StyledSelectLocationButton
          onClick={getMyLocation}
          style={{
            marginTop: "16px",
            marginBottom: "8px",
          }}
        >
          Select current Lat/Lng
        </StyledSelectLocationButton>

        <div style={{ margin: "12px 0 10px" }}>
          <Autocomplete
            multiple
            id="tags-standard"
            freeSolo
            options={CuisineArray}
            getOptionLabel={(option) => option}
            // defaultValue={[top100Films[13]]}
            value={selectedCuisine}
            onChange={handleChange2}
            renderTags={(selected, getTagProps) => (
              // selected.map((option, index) => (
              //   <Chip variant="outlined" deleteIcon={<CloseRounded style={{ borderColor: red, color: "red" }} />} style={{ borderColor: red[500], color: "red" }} label={option} {...getTagProps({ index })} />
              // ))

              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {selected.map((value, index) => (
                  //  value === "Others"? <div></div> :
                  <div
                    style={{
                      border: "1px solid red",
                      padding: "5px",
                      marginRight: "10px",
                      borderRadius: "5px",
                      color: "red",
                    }}
                    {...getTagProps({ index })}
                  >
                    {value}
                    <img
                      width={13}
                      height={12}
                      style={{ marginLeft: "8px" }}
                      src={remove}
                      alt="cover"
                      onClick={(e) => {
                        handleDeleteClick(value);
                      }}
                    />

                    {/* <CloseRounded style={{ borderColor: red, color: "red" ,height:15 }}  /> */}
                  </div>
                ))}
              </div>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Cuisine"
                placeholder="Cuisine"
              />
            )}
          />
        </div>

        {errors.cuisine && errors.cuisine.length > 0 && (
          <HelperText>{errors.cuisine}</HelperText>
        )}
        {/* {(selectedCuisine?.includes("Others") ?? false) && (
          <FormControl margin="normal" fullWidth>
            <TextField
              label="Add Cuisine Manually"
              onChange={(e) => setOtherCusine(e.target.value)}
            //   onSubmit={(e) => {
            //     selectedCuisine = [...selectedCuisine,e.target.value]
            //     setOtherCusine("");
            //     // setOtherCusine(e.target.value);
            //   }
            // }
              name="cuisine"
              value={otherCusine}
              error={errors.otherCusine && errors.otherCusine.length > 0}
              helperText={errors.otherCusine}
              variant="outlined"
            />

          </FormControl>
        )} */}
        {/* <AddMoreButton onClick={() => {}}>
                +
              </AddMoreButton> */}

        <div className="col-centered" style={{ display: "flex" }}>
          <div style={{ width: "48%", marginRight: "2%" }}>
            {formData.phone.map((item, index) => (
              <FormControl margin="normal" required fullWidth key={item.id}>
                <TextField
                  label="Enter Phone"
                  onChange={(event) => handleChange(event, item.id)}
                  name="phone"
                  value={item.value}
                  error={errors.phone && errors.phone.length > 0 && !item.value}
                  helperText={!item.value && errors.phone}
                  variant="outlined"
                />
                {index > 0 && (
                  <DeleteButton onClick={() => handleDelete(item.id, "phone")}>
                    <DeleteIcon />
                  </DeleteButton>
                )}
              </FormControl>
            ))}
            {formData.phone.length <= 1 && (
              <AddMoreButton onClick={() => handleAddMore("phone")}>
                + Add another Phone Number
              </AddMoreButton>
            )}
          </div>

          <FormControl
            margin="normal"
            style={{ width: "48%", marginLeft: "2%" }}
          >
            <TextField
              label="Email"
              onChange={handleChange}
              name="email"
              value={formData.email}
              error={errors.email && errors.email.length > 0}
              helperText={errors.email}
              variant="outlined"
            />
          </FormControl>
        </div>

        <div className="col-centered">
          <FormControl
            margin="normal"
            variant="outlined"
            error={errors.currency && errors.currency.length > 0}
            style={{ width: "48%", marginRight: "2%" }}
          >
            <InputLabel>Currency</InputLabel>
            <Select
              label="Restaurant Currency"
              labelId="currency"
              name="currency"
              onChange={handleChange}
              value={formData.currency}
              error={errors.currency && errors.currency.length > 0}
              // disabled={isUpdate}
            >
              <MenuItem value="USD">US Dollar ($)</MenuItem>
              <MenuItem value="EUR">Euro (€)</MenuItem>
              <MenuItem value="GBP">British Pound (£)</MenuItem>
              <MenuItem value="INR">Indian Rupee (₹)</MenuItem>
              <MenuItem value="BRL">Brazilian Real (BRL)</MenuItem>
              <MenuItem value="AED">United Arab Emirates Dirham (AED)</MenuItem>
              <MenuItem value="CAD">Canadian Dollar (CAD)</MenuItem>
              <MenuItem value="AUD">Australian Dollar (AUD)</MenuItem>
              <MenuItem value="BDT">Bangladeshi Taka (BDT)</MenuItem>
              <MenuItem value="SGD">Singapore Dollar (SGD)</MenuItem>
              <MenuItem value="MYR">Malaysian Ringgit (MYR)</MenuItem>
              <MenuItem value="ARS">Argentine Peso (ARS)</MenuItem>
              <MenuItem value="MXN">Mexican Peso (MXN)</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            margin="normal"
            variant="outlined"
            error={errors.price && errors.price.length > 0}
            style={{ width: "48%", marginLeft: "2%" }}
          >
            <InputLabel id="price">Estimated Price Point</InputLabel>
            <Select
              label="Estimated Price Point"
              labelId="price"
              name="price"
              onChange={handleChange}
              value={formData.price}
              error={errors.priceRange && errors.priceRange.length > 0}
            >
              <MenuItem value="1">$</MenuItem>
              <MenuItem value="2">$$</MenuItem>
              <MenuItem value="3">$$$</MenuItem>
              <MenuItem value="4">$$$$</MenuItem>
            </Select>
          </FormControl>
        </div>
        {errors.priceRange && errors.priceRange.length > 0 && (
          <HelperText>{errors.priceRange}</HelperText>
        )}
        <FileWrapper>
          <div>
            <input
              type="file"
              style={{ display: "none", minWidth: "135px" }}
              ref={fileInput}
              name="icon"
              onChange={(e) => handleFileChange(e)}
            />
            <StyledAddPictureButton
              onClick={() => fileInput.current.click()}
              disabled={thumbnail || coverProgress}
              style={{ width: "240px" }}
            >
              + Add Restaurant Logo
            </StyledAddPictureButton>
          </div>
          {IconProgress && <CircularProgress />}
          {thumbnail && (
            <UploadedFile>
              <img
                width={35}
                height={35}
                src={thumbnail}
                alt="remove-thumbnail"
              />
              <DeleteIconButton
                onClick={() => {
                  setThumbnail(null);
                }}
              >
                {thumbnail && <StyledCancelIcon />}
              </DeleteIconButton>
            </UploadedFile>
          )}
        </FileWrapper>
        {errors.thumbnail && errors.thumbnail.length > 0 && (
          <HelperText>{errors.thumbnail}</HelperText>
        )}
        <FileWrapper>
          <div>
            <input
              type="file"
              style={{ display: "none" }}
              name="coverPhoto"
              ref={fileInputCover}
              onChange={(e) => handleFileChange(e)}
            />
            <StyledAddPictureButton
              onClick={() => fileInputCover.current.click()}
              disabled={coverPhoto || IconProgress}
              style={{ width: "240px" }}
            >
              + Add Cover / Banner Picture
            </StyledAddPictureButton>
          </div>
          {coverProgress && <CircularProgress />}
          {coverPhoto && (
            <UploadedFile>
              <img width={35} height={35} src={coverPhoto} alt="cover" />
              <DeleteIconButton
                onClick={() => {
                  setCoverPhoto(null);
                }}
              >
                {coverPhoto && <StyledCancelIcon />}
              </DeleteIconButton>
            </UploadedFile>
          )}
        </FileWrapper>
        {errors.coverPhoto && <HelperText>{errors.coverPhoto}</HelperText>}
        <h4 style={{ marginTop: "2.5vw", marginBottom: "0.8vw" }}>Timings</h4>
        {timeArray.map((item, index) => {
          return (
            <FormControl
              style={{ marginTop: "0px" }}
              fullWidth
              variant="outlined"
            >
              <FileWrapper>
                <div style={{ width: "94%" }}>
                  <FormControl
                    style={{ marginTop: "0px" }}
                    fullWidth
                    variant="outlined"
                  >
                    <InputLabel id="time">Select Timing Category</InputLabel>
                    <Select
                      label="Select Timing Category"
                      labelId="time"
                      autoWidth
                      name="time"
                      onChange={(event) => handleChange1(event, index)}
                      value={
                        item?.other
                          ? "Other (I want to type my own)"
                          : item.category
                      }
                    >
                      <MenuItem value="Breakfast">Breakfast</MenuItem>
                      <MenuItem value="Brunch">Brunch</MenuItem>
                      <MenuItem value="Lunch">Lunch</MenuItem>
                      <MenuItem value="Dinner">Dinner</MenuItem>
                      <MenuItem value="Other (I want to type my own)">
                        Other (I want to type my own)
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <IconButton onClick={() => onDeleteCategory(index)}>
                  <DeleteOutlineIcon style={{ color: "red" }} />
                </IconButton>
              </FileWrapper>
              {item?.other ? (
                <FormControl margin="normal" required fullWidth key={item.id}>
                  <TextField
                    label="Enter Category"
                    onChange={(event) => {
                      const { value } = event.target;
                      timeArray[index].category = value;
                      setRefresh(!refresh);
                      console.log("Get Response>>>", value);
                    }}
                    name="category"
                    value={item.category}
                    helperText={!item.value && errors.phone}
                    variant="outlined"
                  />
                </FormControl>
              ) : null}
              {item.timing.map((item1, index1) => {
                return (
                  <FileWrapper
                    style={{ margin: index1 === 0 ? "10px 0;" : "0" }}
                  >
                    <div style={{ width: "18%" }}>
                      {index1 === 0 ? <TimingHeader>From</TimingHeader> : null}
                      <FormControl fullWidth>
                        <MySelect
                          label="from"
                          labelId="fromTime"
                          name="fromTime"
                          onChange={(event) =>
                            handleChange3(event, index1, index, "from")
                          }
                          disableUnderline={true}
                          value={item1.fromDay}
                        >
                          <MenuItem value="Mon">Mon</MenuItem>
                          <MenuItem value="Tue">Tue</MenuItem>
                          <MenuItem value="Wed">Wed</MenuItem>
                          <MenuItem value="Thu">Thu</MenuItem>
                          <MenuItem value="Fri">Fri</MenuItem>
                          <MenuItem value="Sat">Sat</MenuItem>
                          <MenuItem value="Sun">Sun</MenuItem>
                        </MySelect>
                      </FormControl>
                    </div>
                    <div
                      style={{ width: "20%", marginRight: 30, marginLeft: 30 }}
                    >
                      {index1 === 0 ? (
                        <TimingHeader>
                          To <i style={{ color: "#666" }}>(Optional)</i>
                        </TimingHeader>
                      ) : null}
                      <FormControl fullWidth>
                        <MySelect
                          label="to"
                          labelId="toTime"
                          name="toTime"
                          onChange={(event) =>
                            handleChange3(event, index1, index, "to")
                          }
                          disableUnderline={true}
                          value={item1.toDay}
                        >
                          <MenuItem value="Mon">Mon</MenuItem>
                          <MenuItem value="Tue">Tue</MenuItem>
                          <MenuItem value="Wed">Wed</MenuItem>
                          <MenuItem value="Thu">Thu</MenuItem>
                          <MenuItem value="Fri">Fri</MenuItem>
                          <MenuItem value="Sat">Sat</MenuItem>
                          <MenuItem value="Sun">Sun</MenuItem>
                        </MySelect>
                      </FormControl>
                    </div>
                    <div>
                      {index1 === 0 ? (
                        <div
                          style={{ marginBottom: "10px", height: "24px" }}
                        ></div>
                      ) : null}

                      <TimeRangePicker
                        onChange={(text) => {
                          let texting = text;

                          const [previousStart, previousEnd] = item1.time;
                          const [newStart, newEnd] = text;
                          if (newStart !== previousStart) {
                            let time = texting[0];

                            const h = time.split(":")[0];
                            const m = time.split(":")[1];

                            let hour = parseInt(h, 10);
                            const amPm = hour >= 12 ? "PM" : "AM";

                            hour = hour > 12 ? hour - 12 : hour;
                            hour = padWithLeadingZeros(hour > 0 ? hour : 12, 2);
                            texting[0] = `${hour}:${m} ${amPm}`;
                            // texting[0] = `${texting[0]} ${amPm}`;
                          }
                          if (newEnd !== previousEnd) {
                            let time2 = texting[1];

                            const h = time2.split(":")[0];
                            const m = time2.split(":")[1];

                            let hour = parseInt(h, 10);
                            const amPm = hour >= 12 ? "PM" : "AM";
                            hour = hour > 12 ? hour - 12 : hour;
                            hour = padWithLeadingZeros(hour > 0 ? hour : 12, 2);
                            texting[1] = `${hour}:${m} ${amPm}`;
                          }
                          console.log("Get Response::", texting);
                          timeArray[index].timing[index1].time = texting;

                          setRefresh(!refresh);
                        }}
                        style={{ Width: "242px" }}
                        format={"hh:mm a"}
                        value={item1.time}
                        clockIcon={null}
                        openClockOnFocus={false}
                      />
                    </div>
                    <div>
                      {index1 === 0 ? (
                        <div
                          style={{ marginBottom: "10px", height: "24px" }}
                        ></div>
                      ) : null}
                      <IconButton onClick={() => onDeleteTime(index, index1)}>
                        <DeleteOutlineIcon style={{ color: "red" }} />
                      </IconButton>
                    </div>
                  </FileWrapper>
                );
              })}
              {item.timing.length > 0 ? (
                <AddMoreButton
                  style={{ marginTop: "10px" }}
                  onClick={() => {
                    let data = timeArray[index].timing;
                    let NewItemAdd = {
                      fromDay: "",
                      toDay: "",
                      time: ["", ""],
                    };
                    data.push(NewItemAdd);
                    timeArray[index].timing = data;
                    setRefresh(!refresh);
                    setTimeArray(timeArray);
                  }}
                >
                  + Add another Timing
                </AddMoreButton>
              ) : null}
            </FormControl>
          );
        })}

        <FileWrapper>
          <div>
            <StyledAddPictureButton
              onClick={() => {
                let NewItemAdd = {
                  category: "",
                  timing: [],
                };
                setTimeArray((modifierAdd) => [...modifierAdd, NewItemAdd]);
              }}
            >
              + Add Timing Category
            </StyledAddPictureButton>
          </div>
        </FileWrapper>
        {/* <TimingHeader>Brunch Timings</TimingHeader>

        <div>
          <TimeRangePicker
            onChange={setBrunch}
            value={brunch}
            clockIcon={null}
            openClockOnFocus={false}
          />
        </div>
        {errors.brunch && errors.brunch.length > 0 && (
          <HelperText>{errors.brunch}</HelperText>
        )}

        <TimingHeader>Lunch Timings</TimingHeader>
        <div>
          <TimeRangePicker
            onChange={setLunch}
            value={lunch}
            clockIcon={null}
            openClockOnFocus={false}
          />
        </div>
        {errors.lunch && errors.lunch.length > 0 && (
          <HelperText>{errors.lunch}</HelperText>
        )}
        <TimingHeader>Dinner Timings</TimingHeader>
        <div>
          <TimeRangePicker
            onChange={setDinner}
            value={dinner}
            clockIcon={null}
            openClockOnFocus={false}
          />
        </div>
        {errors.dinner && errors.dinner.length > 0 && (
          <HelperText>{errors.dinner}</HelperText>
        )} */}

        {/* <span className="error-msg">{loginErrorMsg}</span><br/><br/> */}
        <ButtonsContainer>
          <StyledCancelButton
            onClick={() => navigate("/register/account-details")}
          >
            &lt;&nbsp;Previous
          </StyledCancelButton>
          {open && <Modal open={open} handleClose={handleClose} />}

          <StyledSaveButton onClick={() => navigate("/register/about-us")}>
            Next&nbsp;&gt;
          </StyledSaveButton>
          <StyledTextButton
            type="submit"
            disabled={IconProgress || coverProgress}
          >
            Save
          </StyledTextButton>
        </ButtonsContainer>
      </DetailsForm>
      {isLoading && <LoadingModal />}
    </FormContainer>
  );
}

export default Form;
