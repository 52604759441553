import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { Typography } from "@material-ui/core";
import MenuBar from "./MenuBar";
import AddDishModal from "./AddDishModal";
import LoadingModal from "../../../../baseComponents/LoadingModal";
import { makeAPiCall } from "../../../../../services/api";

import {
  doc,
  getDocs,
  updateDoc,
  addDoc,
  getDoc,
  collection,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import { db } from "../../../../../firebase";
import {
  MenuItemsListContainer,
  StyledMenuItemsHeaderContainer,
  StyledAddNewDishButton,
  StyledMenuItemsHeader,
  ButtonContainer,
  StyledCancelButton,
  StyledSaveButton,
} from "./styles";

function MenuItemsList() {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({
    id: "",
    name: "",
  });
  const [categorySubCategoryList, setCategorySubCategoryList] = useState([]);
  const [open, setOpen] = useState(false);
  const [subCategories, setSubCategories] = useState([]);
  const [dishes, setDishes] = useState([]);
  const [upDateToDishId, setUpDateToDishId] = useState("");
  const [dishToBeDeleted, setDishToBeDeleted] = useState(null);
  const [subCollectionDeleted, setSubCollectionDeleted] = useState(false);
  const [restaurantDetails, setRestaurantsDetails] = useState({
    name: "",
    restaurantId: "",
  });

  const [currencyValue, setCurrencyValue] = useState("");
  const [dishOftheDay, setDishOfTheDay] = useState({ dishOftheDay: false });
  const [cheifSpecial, setCheifSpecial] = useState({ cheifSpecial: false });
  const [dishData, setDishData] = useState({
    name: "",
    description: "",
    categoryId: "",
    createdAt: "",
    subcategoryId: "",
    price: "",
    currency: "",
    icon: "",
    coverPhoto: "",
    dishPhotos: [],
    ingredients: [],
    isDisabled: false,
    multiplePrice: false,
  });

  const [isLoading, setIsloading] = useState(false);
  const [isLoadingDelete, setIsloadingDelete] = useState(false);
  const [sabCategoryList, setSabCategoryList] = useState([]);

  useEffect(() => {
    console.log("useEffect");
    const getData = async () => {
      const docRef = collection(
        db,
        `restaurants`,
        `${localStorage.getItem("uid")}`,
        `dishes`
      );
      const docSnap = await getDocs(docRef);
      setDishes(
        docSnap.docs.map((d) => {
          console.log("Get Category>>>", d.data());

          return {
            id: d.id,
            ...d.data(),
          };
        })
      );
    };
    getData();
  }, [open, subCollectionDeleted, dishData]);

  useEffect(() => {
    if (localStorage.getItem("uid")) {
      setIsloading(true);
      makeAPiCall("GET", `get-restaurant/${localStorage.getItem("uid")}`).then(
        (data) => {
          if (data.response.categories !== undefined) {
            const res = data.response.categories;
            setCurrencyValue(data.response.currency);

            let temp = res.filter((u) => u);
            let newdata1 = [];
            let newdata2 = [];
            res.map((v) => {
              if (v.subcategories.length > 0) {
                newdata2.push(...v.subcategories);
                console.log("Get Temp Subcategory", newdata2);
              }
              return 0;
            });
            let newTemp = temp.map((v) => {
              let newdata = v.subcategories.filter((z) => {
                return z;
              });
              // newdata2 = v.subcategories;
              if (newdata.length > 0) {
                newdata1.push(...newdata);
                return {
                  ...v,
                  subcategories: newdata,
                };
              } else {
                return {
                  ...v,
                  subcategories: [],
                };
              }
            });
            setRestaurantsDetails({
              name: data?.response?.name,
              restaurantId: data?.response?.restaurantId,
            });

            setList(newTemp);
            setTimeout(() => {
              setSabCategoryList(newdata2);
            }, 500);

            res.length > 0 && setSelectedCategory(temp[0]);
            res.length > 0 && setSubCategories(res[0].subcategories);
            res.length > 0 && setCategorySubCategoryList(res);
          }
          setIsloading(false);
        }
      );
    }
  }, []);

  const handleSelectedCategory = (selectedCategory) => {
    setSelectedCategory(selectedCategory);
    setSubCategories(selectedCategory.subcategories);
  };

  const handleClose = () => {
    setOpen(false);
    setUpDateToDishId("");
    setCheifSpecial({ cheifSpecial: false });
    setDishOfTheDay({ dishOftheDay: false });
  };

  const editHandler = async (dish, category) => {
    console.log(
      "category123456",
      dish,
      ">>>>>>>>>>>>>>>>>>",
      category.subcategories
    );
    setUpDateToDishId(dish.id);
    setDishToBeDeleted(dish);

    const getHighlightsRef = doc(
      db,
      "restaurants",
      `${localStorage.getItem("uid")}`
    );

    const docSnap = await getDoc(getHighlightsRef);

    if (docSnap.exists()) {
      if (docSnap.data().highlights) {
        if (
          docSnap.data().highlights.cheifSpecial &&
          docSnap
            .data()
            .highlights.cheifSpecial.some((data) => data.dishId === dish.id)
        ) {
          setCheifSpecial({ cheifSpecial: true });
        }
        if (
          docSnap.data().highlights.dishOftheDay &&
          docSnap
            .data()
            .highlights.dishOftheDay.some((data) => data.dishId === dish.id)
        ) {
          setDishOfTheDay({ dishOftheDay: true });
        }
      }
    }

    setSelectedCategory({
      id: category.id,
      name: category.name,
    });
    setSubCategories(category.subcategories);
    if (dish.multiplePrice) {
      let price = dish.price;
      price.map((item, index) => {
        price[index].amount = Number(item.amount / 100)
          .toFixed(2)
          .toString();
        return 0;
      });
      setDishData({
        dishId: dish.dishId,
        name: dish.name,
        description: dish.description,
        createdAt: dish.createdAt,
        categoryId: dish.categoryId,
        subcategoryId:
          category.subcategories !== undefined ? dish.subcategoryId : "",
        price: price,
        currency: "",
        ingredients: dish.ingredients,
        icon: dish.icon,
        coverPhoto: dish.coverPhoto,
        dishPhotos: dish.dishPhotos,
        isDisabled: dish.isDisabled || false,
        multiplePrice: dish.multiplePrice || false,
      });
    } else {
      setDishData({
        dishId: dish.dishId,
        name: dish.name,
        description: dish.description,
        createdAt: dish.createdAt,
        categoryId: dish.categoryId,
        subcategoryId:
          category.subcategories !== undefined ? dish.subcategoryId : "",
        price: Number(dish.price / 100)
          .toFixed(2)
          .toString(),
        currency: "",
        ingredients: dish.ingredients,
        icon: dish.icon,
        coverPhoto: dish.coverPhoto,
        dishPhotos: dish.dishPhotos,
        isDisabled: dish.isDisabled || false,
        multiplePrice: dish.multiplePrice || false,
      });
    }

    setOpen(true);
  };

  const deleteDishHandler = () => {
    setIsloadingDelete(true);
    const deleteData = async () => {
      const docRef = doc(
        db,
        "restaurants",
        `${localStorage.getItem("uid")}`,
        "dishes",
        `${upDateToDishId}`
      );
      deleteDoc(docRef).then(() => {
        deleteHighLights();
      });
    };
    deleteData();
    const deleteHighLights = async () => {
      const getHighlightsRef = doc(
        db,
        "restaurants",
        `${localStorage.getItem("uid")}`
      );
      const docSnap = await getDoc(getHighlightsRef);
      if (docSnap.exists()) {
        if (docSnap.data().highlights) {
          if (
            docSnap
              .data()
              .highlights.cheifSpecial.filter(
                (data) => data.dishId === dishToBeDeleted.dishId
              ).length
          ) {
            setDoc(
              getHighlightsRef,
              {
                highlights: {
                  cheifSpecial: [
                    ...docSnap
                      .data()
                      .highlights.cheifSpecial.filter(
                        (u) => u.dishId !== dishToBeDeleted.dishId
                      ),
                  ],
                },
              },
              { merge: true }
            );
          }
        }
        if (docSnap.data().highlights) {
          if (
            docSnap
              .data()
              .highlights.dishOftheDay.filter(
                (data) => data.dishId === dishToBeDeleted.dishId
              ).length
          ) {
            setDoc(
              getHighlightsRef,
              {
                highlights: {
                  dishOftheDay: [
                    ...docSnap
                      .data()
                      .highlights.dishOftheDay.filter(
                        (u) => u.dishId !== dishToBeDeleted.dishId
                      ),
                  ],
                },
              },
              { merge: true }
            );
          }
        }
        setUpDateToDishId("");
        setDishToBeDeleted(null);
        setCheifSpecial({ cheifSpecial: false });
        setDishOfTheDay({ dishOftheDay: false });
        setSubCollectionDeleted((prev) => !prev);
        setIsloadingDelete(false);
        setOpen(false);
      } else {
        console.log("No document");
      }
    };
  };

  const handleSubmitDish = async (e) => {
    let price = "";

    if (dishData.multiplePrice) {
      price = dishData.price;
      price.map((item, index) => {
        price[index].amount = Math.ceil(Number(item.amount) * 100).toString();
        return 0;
      });
    } else {
      price = Math.ceil(Number(dishData.price) * 100).toString();
    }
    e.preventDefault();
    setIsloading(true);
    if (upDateToDishId) {
      const messageRef = doc(
        db,
        "restaurants",
        `${localStorage.getItem("uid")}`,
        "dishes",
        `${upDateToDishId}`
      );

      await updateDoc(messageRef, {
        ...dishData,
        price: price,
      }).then(() => {
        setDishData({
          name: "",
          description: "",
          categoryId: "",
          subcategoryId: "",
          price: "",
          currency: "",
          icon: "",
          coverPhoto: "",
          dishPhotos: [],
          ingredients: [],
          isDisabled: false,
          multiplePrice: false,
        });
      });
      setUpDateToDishId("");
      setIsloading(false);
      const editHighLights = async () => {
        const getHighlightsRef = doc(
          db,
          "restaurants",
          `${localStorage.getItem("uid")}`
        );

        const docSnap = await getDoc(getHighlightsRef);

        if (docSnap.exists()) {
          if (docSnap.data().highlights) {
            if (
              docSnap
                .data()
                .highlights.cheifSpecial.filter(
                  (data) => data.dishId === dishData.dishId
                )?.length > 0 &&
              cheifSpecial.cheifSpecial
            ) {
              setDoc(
                getHighlightsRef,
                {
                  highlights: {
                    cheifSpecial: [
                      ...docSnap
                        .data()
                        .highlights.cheifSpecial.filter(
                          (u) => u.dishId !== dishData.dishId
                        ),
                      dishData,
                    ],
                  },
                },
                { merge: true }
              );
            }
            if (
              docSnap
                .data()
                .highlights.cheifSpecial.filter(
                  (data) => data.dishId === dishData.dishId
                )?.length === 0 &&
              cheifSpecial.cheifSpecial
            ) {
              setDoc(
                getHighlightsRef,
                {
                  highlights: {
                    cheifSpecial: [
                      ...docSnap.data().highlights.cheifSpecial,
                      dishData,
                    ],
                  },
                },
                { merge: true }
              );
            }

            if (
              docSnap
                .data()
                .highlights.cheifSpecial.filter(
                  (data) => data.dishId === dishData.dishId
                )?.length > 0 &&
              !cheifSpecial.cheifSpecial
            ) {
              setDoc(
                getHighlightsRef,
                {
                  highlights: {
                    cheifSpecial: [
                      ...docSnap
                        .data()
                        .highlights.cheifSpecial.filter(
                          (u) => u.dishId !== dishData.dishId
                        ),
                    ],
                  },
                },
                { merge: true }
              );
            }
          }

          if (docSnap.data().highlights) {
            if (
              docSnap
                .data()
                .highlights.dishOftheDay.filter(
                  (data) => data.dishId === dishData.dishId
                )?.length > 0 &&
              dishOftheDay.dishOftheDay
            ) {
              setDoc(
                getHighlightsRef,
                {
                  highlights: {
                    dishOftheDay: [
                      ...docSnap
                        .data()
                        .highlights.dishOftheDay.filter(
                          (u) => u.dishId !== dishData.dishId
                        ),
                      dishData,
                    ],
                  },
                },
                { merge: true }
              );
            }
            if (
              docSnap
                .data()
                .highlights.dishOftheDay.filter(
                  (data) => data.dishId === dishData.dishId
                )?.length === 0 &&
              dishOftheDay.dishOftheDay
            ) {
              setDoc(
                getHighlightsRef,
                {
                  highlights: {
                    dishOftheDay: [
                      ...docSnap.data().highlights.dishOftheDay,
                      dishData,
                    ],
                  },
                },
                { merge: true }
              );
            }
            if (
              docSnap
                .data()
                .highlights.dishOftheDay.filter(
                  (data) => data.dishId === dishData.dishId
                )?.length > 0 &&
              !dishOftheDay.dishOftheDay
            ) {
              setDoc(
                getHighlightsRef,
                {
                  highlights: {
                    dishOftheDay: [
                      ...docSnap
                        .data()
                        .highlights.dishOftheDay.filter(
                          (u) => u.dishId !== dishData.dishId
                        ),
                    ],
                  },
                },
                { merge: true }
              );
            }
          }
        } else {
          console.log("No document");
        }
      };

      editHighLights();
    } else {
      const docRef = await addDoc(
        collection(
          db,
          `restaurants`,
          `${localStorage.getItem("uid")}`,
          `dishes`
          // `${dishData.categoryId}${dishData.name}${dishData.subcategoryId}`
        ),
        {
          ...dishData,
          dishId: "",
          price: price,
          restaurantId: restaurantDetails.restaurantId,
          restaurantName: restaurantDetails.name,
          showOrder: Date.now(),
        }
      );

      const messageRef = doc(
        db,
        "restaurants",
        `${localStorage.getItem("uid")}`,
        "dishes",
        `${docRef.id}`
      );

      await updateDoc(messageRef, {
        dishId: docRef.id,
      });
      setIsloading(false);
      const getHighlightsRef = doc(
        db,
        "restaurants",
        `${localStorage.getItem("uid")}`
      );
      const docSnap = await getDoc(getHighlightsRef);
      if (docSnap.exists()) {
        const dishRef = doc(
          db,
          "restaurants",
          `${localStorage.getItem("uid")}`
        );

        if (docSnap.data().highlights) {
          if (
            docSnap.data().highlights.cheifSpecial.length > 0 &&
            cheifSpecial?.cheifSpecial
          ) {
            setDoc(
              dishRef,
              {
                highlights: {
                  cheifSpecial: [
                    ...docSnap.data().highlights.cheifSpecial,
                    {
                      ...dishData,
                      dishId: docRef.id,
                    },
                  ],
                },
              },
              { merge: true }
            );
          } else {
            if (cheifSpecial?.cheifSpecial) {
              setDoc(
                dishRef,
                {
                  highlights: {
                    cheifSpecial: [
                      {
                        ...dishData,
                        dishId: docRef.id,
                      },
                    ],
                  },
                },
                { merge: true }
              );
            } else {
              setDoc(
                dishRef,
                {
                  highlights: {
                    cheifSpecial:
                      docSnap.data().highlights.cheifSpecial.length > 0
                        ? [...docSnap.data().highlights.cheifSpecial]
                        : [],
                  },
                },
                { merge: true }
              );
            }
          }

          if (
            docSnap.data().highlights.dishOftheDay.length > 0 &&
            dishOftheDay?.dishOftheDay
          ) {
            setDoc(
              dishRef,
              {
                highlights: {
                  dishOftheDay: [
                    ...docSnap.data().highlights.dishOftheDay,
                    {
                      ...dishData,
                      dishId: docRef.id,
                    },
                  ],
                },
              },
              { merge: true }
            );
          } else {
            if (dishOftheDay?.dishOftheDay) {
              setDoc(
                dishRef,
                {
                  highlights: {
                    dishOftheDay: [
                      {
                        ...dishData,
                        dishId: docRef.id,
                      },
                    ],
                  },
                },
                { merge: true }
              );
            } else {
              setDoc(
                dishRef,
                {
                  highlights: {
                    dishOftheDay:
                      docSnap.data().highlights.dishOftheDay.length > 0
                        ? [...docSnap.data().highlights.dishOftheDay]
                        : [],
                  },
                },
                { merge: true }
              );
            }
          }
        } else {
          setDoc(
            dishRef,
            {
              highlights: {
                dishOftheDay: dishOftheDay.dishOftheDay
                  ? [
                      {
                        ...dishData,
                        dishId: docRef.id,
                      },
                    ]
                  : [],
                cheifSpecial: cheifSpecial.cheifSpecial
                  ? [
                      {
                        ...dishData,
                        dishId: docRef.id,
                      },
                    ]
                  : [],
                bestRated: [],
              },
            },
            { merge: true }
          );
        }
      } else {
        console.log("No such document!");
      }
      setDishOfTheDay({ dishOftheDay: false });
      setCheifSpecial({ cheifSpecial: false });
      setDishData({
        name: "",
        description: "",
        categoryId: "",
        createdAt: new Date().toString(),
        subcategoryId: "",
        price: "",
        currency: "",
        icon: "",
        coverPhoto: "",
        dishPhotos: [],
        ingredients: [],
        isDisabled: false,
        multiplePrice: false,
      });
    }

    handleClose();
    setCheifSpecial({ cheifSpecial: false });
    setDishOfTheDay({ dishOftheDay: false });
  };

  const handleOpenModal = () => {
    setOpen(true);
    setDishData({
      name: "",
      description: "",
      categoryId: "",
      createdAt: new Date().toString(),
      subcategoryId: "",
      price: "",
      currency: "",
      icon: "",
      coverPhoto: "",
      dishPhotos: [],
      ingredients: [],
      isDisabled: false,
      multiplePrice: false,
    });
  };

  return (
    <MenuItemsListContainer>
      <StyledMenuItemsHeaderContainer>
        <StyledMenuItemsHeader>Menu Item List</StyledMenuItemsHeader>
        <StyledAddNewDishButton onClick={handleOpenModal}>
          + Add New Dish
        </StyledAddNewDishButton>
      </StyledMenuItemsHeaderContainer>

      {dishes?.length > 0 && (
        <MenuBar
          selectedCategory={selectedCategory}
          handleSelectedCategory={handleSelectedCategory}
          categories={list}
          subCategory={sabCategoryList}
          dishes={dishes}
          editHandler={editHandler}
          deleteDishHandler={deleteDishHandler}
          currencyValue={currencyValue}
        />
      )}
      {open && (
        <AddDishModal
          open={open}
          handleClose={handleClose}
          setSelectedCategory={setSelectedCategory}
          list={list}
          handleSubmitDish={handleSubmitDish}
          selectedCategory={selectedCategory}
          subCategories={subCategories}
          setDishData={setDishData}
          dishData={dishData}
          setDishOfTheDay={setDishOfTheDay}
          setCheifSpecial={setCheifSpecial}
          dishOftheDay={dishOftheDay}
          cheifSpecial={cheifSpecial}
          upDateToDishId={upDateToDishId}
          categorySubCategoryList={categorySubCategoryList}
          deleteDishHandler={deleteDishHandler}
          dishes={dishes}
          isLoadingDelete={isLoadingDelete}
          currencyValue={currencyValue}
        />
      )}
      <ButtonContainer>
        <StyledCancelButton onClick={() => navigate("/register/categories")}>
          &lt;&nbsp;Previous
        </StyledCancelButton>

        <StyledSaveButton onClick={() => navigate("/register/featured")}>
          Next&nbsp;&gt;
        </StyledSaveButton>
      </ButtonContainer>
      {isLoading && <LoadingModal />}
    </MenuItemsListContainer>
  );
}

export default MenuItemsList;
