import styled from "styled-components/macro";
import { Button } from "@material-ui/core";
import { Star, ErrorOutlineOutlined } from "@material-ui/icons";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";

const PageContainer = styled.div`
  margin: 2rem auto;
  display: none;
`;
const BlockContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Header = styled.h1`
  text-align: center;
`;
const Tip = styled.p`
  font-weight: bold;
  text-decoration: underline;
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

const StyledButton = styled(Button).attrs(() => ({ variant: "contained" }))`
  && {
    background-color: #ef403b;
    color: white;
  }
`;

const DetailsContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin: 2.5rem;
`;

const ImageContainer = styled.div`
  width: 150px;
  height: 150px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const CoverPhoto = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 100%;
`;

const LogoImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  padding: 0px;
  align-items: center;
  gap: 8px;
  margin-top: 2rem;
  margin-left: 5px;

  margin-bottom: 0.5rem;
`;

const AccountName = styled.h4`
  margin: 0px;
  font-size: 22px;
`;

const AddressContainer = styled.div`
  display: flex;
  alignitems: center;
  gap: 8px;
  margintop: 10px;
`;

const StyledRoomOutlinedIcon = styled(RoomOutlinedIcon)`
  color: red;
`;

const AddressDetails = styled.div`
  margin: 0px;
`;

const StyledSaveButtonWrapper = styled.div`
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;
`;

const StyledSaveButton = styled(Button).attrs(() => ({
  variant: "contained",
}))`
  background-color: #ef403b !important ;
  color: white !important;
  padding: 5px 30px !important;
  border-radius: 8px !important;
  font-weight: bold !important;
  // margin-bottom: 30px !important;
`;

const SummaryWrapper = styled.h4`
  margin: 32px;
  padding: 32px;
  background: #ffffff;
  box-shadow: 0px 4px 50px 2px rgba(0, 0, 0, 0.08);
`;

const SummaryTitle = styled.p`
  margin: 0px;
  font-size: 18px;
`;
const TabRow = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
`;

const TabItem = styled.div`
  width: 100%;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  border: 1px solid #dbdbdb;
  background-color: #fff;
  padding: 8px;
  cursor: pointer;
`;

const ReviewRow = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
`;
const EarnedCard = styled.div`
  padding: 10px;
  width: 100%;
  display: flex;
`;

const EarnedTitle = styled.h4`
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
  margin: 0;
  margin-bottom: 10px;
`;

const EarnedSummary = styled.p`
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
`;
const ReviewCardWrapper = styled.div`
  padding: 10px;
  width: 100%;
  display: flex;
`;

const StarItem = styled(Star)`
  &&& {
    color: #ef403b;
    cursor: pointer;
  }
`;
const ReviewCardLeft = styled.div`
  padding: 10px;
`;
const ReviewCardRight = styled.div`
  padding: 10px;
  width: 100%;
`;

const StarRow = styled.div`
  display: flex;
`;

const ReviewAvg = styled.h1`
  font-size: 33px;
  line-height: 40px;
  margin: 0;
`;
const TotalReview = styled.p`
  font-weight: 400;
  font-size: 12px;
  marging: 0;
`;
const DataSummaryCountWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;
const DataSummaryCountItem = styled.div`
  background: #d8d8d8;
  padding: 20px 0 10px;
  width: 200px;
  text-align: center;
`;
const DataSummaryCount = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
`;
const DataSummaryTitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
`;
const FlaggedReviewsWrapper = styled.div`
  background-color: #f3f3f3;
  padding: 24px 15px;
  color: #ef403b;
  align-items: center;
  display: flex;
  margin: 0 30px;
  box-shadow: 0px 4px 50px 2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
`;

const StyledWarningOutlinedIcon = styled(ErrorOutlineOutlined)`
  color: red;

  padding-right: 10px;
`;
export {
  PageContainer,
  BlockContainer,
  ButtonContainer,
  Header,
  Tip,
  StyledButton,
  DetailsContainer,
  ImageContainer,
  CoverPhoto,
  HeaderContainer,
  AccountName,
  AddressContainer,
  StyledRoomOutlinedIcon,
  AddressDetails,
  LogoImage,
  StyledSaveButton,
  SummaryTitle,
  SummaryWrapper,
  TabRow,
  TabItem,
  ReviewRow,
  EarnedCard,
  EarnedTitle,
  EarnedSummary,
  ReviewCardWrapper,
  StarItem,
  ReviewCardLeft,
  ReviewCardRight,
  ReviewAvg,
  StarRow,
  TotalReview,
  DataSummaryCountWrapper,
  DataSummaryCountItem,
  DataSummaryCount,
  DataSummaryTitle,
  FlaggedReviewsWrapper,
  StyledWarningOutlinedIcon,
  StyledSaveButtonWrapper,
};
