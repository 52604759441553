import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  Button,
  FormControl,
  TextField,
  InputLabel,
  MenuItem,
  CircularProgress,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
// import CurrencyFormat from "react-currency-format";
import ConfirmationDialog from "./components/ConfirmationDialog";
import done from "../../../../../assets/done.svg";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../../../../firebase";
import { doc, setDoc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "../../../../../firebase";

import {
  ModalContentContainer,
  CategoryContainer,
  InputContaner,
  DishToggleBox,
  ContentContainer,
  StyledHeader,
  StyledHintContainer,
  ModalForm,
  HelperText,
  TextAreaWrapper,
  IngridientsInputContainer,
  StyledAddIngridientsButton,
  StyledTextField,
  DropdownContainer,
  IngridientsContainer,
  StyledRemoveIngridientsButton,
  DropdownField,
  FileWrapper,
  FileUpload,
  UploadedFile,
  DeleteIcon,
  StyledCancelIcon,
  StyledCloseButton,
  ButtonContainer,
  StyledDeleteButton,
  StyledSaveButton,
  StyledSelect,
  StyledInputLabel,
  SubCategoryContainer,
  StyledCheckbox,
  IngridientsDropdownContainer,
  DescriptionText,
  PriceTitle,
  DefaultText,
} from "./styles";

function CancelModal(props) {
  // const inputEl = useRef(null);
  const {
    open,
    handleClose,
    handleSubmitDish,
    dishData,
    setDishData,
    setDishOfTheDay,
    setCheifSpecial,
    dishOftheDay,
    cheifSpecial,
    upDateToDishId,
    categorySubCategoryList,
    deleteDishHandler,
    isLoadingDelete,
    setSelectedCategory,
  } = props;
  const iconInput = useRef();
  const coverInput = useRef();
  const dishPhotosInputs = useRef();
  const [isLoadingIcon, setIsLoadingIcon] = useState(false);
  const [isLoadingCover, setIsloadingCover] = useState(false);
  const [isLoadingDishPhotos, setIsLoadingDishPhotos] = useState(false);
  const [addIngredients, setAddIngredients] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const [errors, setErrors] = useState({});
  const [ingredientsData, setIngredients] = useState([]);
  const [priceSingleValue, setPriceSingleValue] = useState("");
  const [selectPriceOption, setSelectPriceOption] = useState("Single");
  const [priceArray, setPriceArray] = useState([
    {
      check: "true",
      option: "",
      amount: "",
    },
  ]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    (async () => {
      const docRef = doc(db, "ingredients", "ingredient");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setIngredients(docSnap.data().ingredients);
      } else {
        await setDoc(doc(db, "ingredients", "ingredient"), {
          ingredients: [],
        });
      }
    })();
    if (dishData.multiplePrice) {
      setPriceArray(dishData.price);
      setSelectPriceOption("Multiple");
    } else {
      setPriceSingleValue(dishData.price);
      setSelectPriceOption("Single");
    }
  }, [dishData.ingredients.length, dishData]);

  const handleChange = (e) => {
    setDishData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleChangeToggle = (e) => {
    setDishData((prev) => {
      return {
        ...prev,
        [e.target.name]: !e.target.checked,
      };
    });
  };

  const handleAddIngredients = async () => {
    const washingtonRef = doc(db, "ingredients", "ingredient");

    // Atomically add a new region to the "regions" array field.
    await updateDoc(washingtonRef, {
      ingredients: arrayUnion(addIngredients.toUpperCase()),
    });
    setDishData((prev) => {
      return {
        ...prev,
        ingredients: [...prev.ingredients, addIngredients],
      };
    });
    setAddIngredients("");
    setIsOpen(false);
  };

  const handleRemoveIngredients = (item) => {
    setDishData((prev) => {
      return {
        ...prev,
        ingredients: [...prev.ingredients.filter((v) => v !== item)],
      };
    });
  };

  const addSelected = (selected) => {
    setDishData((prev) => {
      return {
        ...prev,
        ingredients: [...prev.ingredients, selected],
      };
    });
    setIsOpen(false);
    setAddIngredients("");
  };

  const validate = () => {
    const errors = {};
    errors.name = dishData.name ? "" : "Dish Name is Required";
    // errors.description = dishData.description ? "" : "Description is Required";
    errors.categoryId = dishData.categoryId ? "" : "Category is Required";
    // errors.subcategoryId = dishData.subcategoryId
    //   ? ""
    //   : "Sub-Category is Required";
    // errors.price = dishData.price ? "" : "Price Required";

    // errors.price = /^[0-9]+$/.test(dishData.price)
    //   ? ""
    //   : "Enter price in cents. No special characters allowed";

    // errors.ingredients = dishData.ingredients.length
    //   ? ""
    //   : "Ingredients are Required";
    // errors.coverPhoto = dishData.coverPhoto ? "" : "Cover Photo Required";
    // errors.icon = dishData.icon ? "" : "Icon Photo Required";
    setErrors({ ...errors });
    return Object.values(errors).every((error) => error === "");
  };

  const handleFileChange = (event, type) => {
    const { files } = event.target;
    let randomId = Math.floor(Math.random() * 899999 + 100000);
    if (type === "coverPhoto") {
      setIsloadingCover(true);
    } else if (type === "icon") {
      setIsLoadingIcon(true);
    } else {
      setIsLoadingDishPhotos(true);
    }

    const storageRef = ref(
      storage,
      `dishes/${type}/${randomId}-${files[0].name}`
    );

    const uploadTask = uploadBytesResumable(storageRef, files[0]);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        // Handle unsuccessful uploads
        if (type === "coverPhoto") {
          setIsloadingCover(false);
        } else if (type === "icon") {
          setIsLoadingIcon(false);
        } else {
          setIsLoadingDishPhotos(false);
        }
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          if (type === "coverPhoto") {
            setIsloadingCover(false);
            setDishData((prev) => {
              return {
                ...prev,
                coverPhoto: downloadURL,
              };
            });
          } else if (type === "icon") {
            setIsLoadingIcon(false);
            setDishData((prev) => {
              return {
                ...prev,
                icon: downloadURL,
              };
            });
          } else {
            setIsLoadingDishPhotos(false);
            setDishData((prev) => {
              if (prev.dishPhotos === undefined || prev.dishPhotos === null) {
                return {
                  ...prev,
                  dishPhotos: [downloadURL],
                };
              } else {
                return {
                  ...prev,
                  dishPhotos: [...prev.dishPhotos, downloadURL],
                };
              }
            });
          }
        });
      }
    );
  };

  const handleDeleteFile = (type, photo) => {
    validate();
    if (type === "coverPhoto") {
      setDishData((prev) => {
        return {
          ...prev,
          coverPhoto: "",
        };
      });
    } else if (type === "icon") {
      setDishData((prev) => {
        return {
          ...prev,
          icon: "",
        };
      });
    } else {
      setDishData((prev) => {
        return {
          ...prev,
          dishPhotos: [...prev.dishPhotos.filter((x) => x !== photo)],
        };
      });
    }
  };

  const handleCategorySelect = (e, type) => {
    const { id } = e.target;
    if (type === "categoryId") {
      setSelectedCategory({
        id: id,
      });
    }

    setDishData((prev) => {
      return {
        ...prev,
        [type]: id,
      };
    });
  };

  // useState(() => {
  //   setDishData((prev) => {
  //     return {
  //       ...prev,
  //       categoryId: selectedCategory.id,
  //     };
  //   });
  // }, []);

  const handleChangeCheckBox = (e) => {
    if (e.target.name === "checkedA") {
      setDishOfTheDay({ dishOftheDay: e.target.checked });
    } else {
      setCheifSpecial({ cheifSpecial: e.target.checked });
    }
  };

  const deleteDish = () => {
    if (openDialog) {
      deleteDishHandler();
    } else {
      setOpenDialog(true);
    }
  };

  const setGender = (event) => {
    console.log(event.target.value);
    setSelectPriceOption(event.target.value);
    if (event.target.value === "Single") {
      priceSingleValue === ""
        ? setDishData((prevState) => ({
            ...prevState,
            multiplePrice: false,
            price: "",
          }))
        : setDishData((prevState) => ({
            ...prevState,
            multiplePrice: false,
            price: priceSingleValue,
          }));
    } else {
      setDishData((prevState) => ({
        ...prevState,
        multiplePrice: true,
        price: priceArray,
      }));
    }
  };

  const handlePriceChange = (event) => {
    handleChange(event);
    const priceInCents = event.target.value;
    if (priceInCents === "" || priceInCents === null) {
      setDishData((prevState) => ({ ...prevState, price: "" }));
    } else {
      const numericPrice = priceInCents.replace(/[^\d]/g, "");
      const paddedPrice = numericPrice.padStart(4, "0");
      const dollars = paddedPrice.slice(0, -2);
      const cents = paddedPrice.slice(-2);
      const formattedPrice = `${Number(dollars)}.${cents.padEnd(2, "0")}`;
      setPriceSingleValue(formattedPrice);
      setDishData((prevState) => ({ ...prevState, price: formattedPrice }));
    }
  };

  const setPriceRedio = (event, index) => {
    console.log(event.target.value);
    if (event.target.value === "false") {
      let array = priceArray;
      array.map((item, index1) => {
        if (index1 === index) {
          array[index1].check = "true";
        } else {
          array[index1].check = "false";
        }
        return "";
      });
      console.log(array);
      setRefresh(!refresh);
      setPriceArray(array);
      setDishData((prevState) => ({ ...prevState, price: array }));
    }
  };

  const handlePriceArrayChange = (event, index) => {
    handleChange(event);
    const priceInCents = event.target.value;
    if (priceInCents === "" || priceInCents === null) {
      setDishData((prevState) => ({ ...prevState, price: "" }));
    } else {
      const numericPrice = priceInCents.replace(/[^\d]/g, "");
      const paddedPrice = numericPrice.padStart(4, "0");
      const dollars = paddedPrice.slice(0, -2);
      const cents = paddedPrice.slice(-2);
      const formattedPrice = `${Number(dollars)}.${cents.padEnd(2, "0")}`;

      priceArray[index].amount = formattedPrice;
      setRefresh(!refresh);
      console.log("Get Response>>", formattedPrice);
      setDishData((prevState) => ({ ...prevState, price: priceArray }));
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEscapeKeyDown
        disableBackdropClick
      >
        <ModalContentContainer>
          <ContentContainer>
            <StyledHeader>
              {upDateToDishId ? "Edit Menu Dish" : "Add Menu Dish"}
            </StyledHeader>

            <StyledHintContainer>
              <img src={done} alt="" />
              <span>
                Add a new dish by adding as much details as possible.More
                details you add the more it will be easier for the users to
                understand the dish details.
              </span>
            </StyledHintContainer>

            <ModalForm>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  label="Dish Name"
                  onChange={handleChange}
                  name="name"
                  value={dishData.name}
                  error={errors.name && errors.name.length > 0}
                  helperText={errors.name}
                  variant="outlined"
                />
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <div
                  style={{
                    width: "100%",
                    alignSelf: "flex-start",
                    textAlign: "left",
                  }}
                  onChange={setGender}
                >
                  <input
                    checked={selectPriceOption === "Single"}
                    type="radio"
                    value="Single"
                    name="gender"
                  />{" "}
                  Single
                  <input
                    style={{ marginLeft: "35px" }}
                    checked={selectPriceOption === "Multiple"}
                    type="radio"
                    value="Multiple"
                    name="gender"
                  />{" "}
                  Multiple
                </div>
              </FormControl>
              {selectPriceOption === "Single" ? (
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    label="Price"
                    name="price"
                    value={dishData.price}
                    onChange={handlePriceChange}
                    error={errors.price && errors.price.length > 0}
                    helperText={errors.price}
                    variant="outlined"
                  />
                </FormControl>
              ) : (
                <>
                  <DescriptionText>
                    The option and amount that you will select by default will
                    be displayed upfront on the menu. Other options and amounts
                    will be displayed in dish description.
                  </DescriptionText>

                  <IngridientsInputContainer>
                    <FormControl size={"medium"} margin="normal">
                      <DefaultText>Default</DefaultText>
                    </FormControl>

                    <FormControl margin="normal" fullWidth>
                      <PriceTitle>Option</PriceTitle>
                    </FormControl>

                    <FormControl margin="normal" fullWidth>
                      <PriceTitle>Amount</PriceTitle>
                    </FormControl>
                    <PriceTitle></PriceTitle>
                  </IngridientsInputContainer>

                  {priceArray.map((item, index) => {
                    return (
                      <IngridientsInputContainer>
                        <FormControl size={"medium"} margin="normal">
                          <DefaultText>
                            {" "}
                            <input
                              style={{ width: 15, height: 15 }}
                              type="radio"
                              value={item.check}
                              name="price"
                              checked={item.check === "true"}
                              onChange={(e) => setPriceRedio(e, index)}
                            />
                          </DefaultText>
                        </FormControl>

                        <FormControl margin="normal" fullWidth>
                          <TextField
                            label="Option"
                            onChange={(e) => {
                              setRefresh(!refresh);
                              priceArray[index].option = e.target.value;
                              setDishData((prevState) => ({
                                ...prevState,
                                price: priceArray,
                              }));
                            }}
                            name="option"
                            value={item.option}
                            variant="outlined"
                          />
                        </FormControl>

                        <FormControl margin="normal" fullWidth>
                          <TextField
                            label="Amount"
                            onChange={(e) => {
                              handlePriceArrayChange(e, index);
                            }}
                            name="amount"
                            value={item.amount}
                            error={errors.name && errors.name.length > 0}
                            helperText={errors.name}
                            variant="outlined"
                          />
                        </FormControl>
                        {priceArray.length - 1 === index ? (
                          <StyledDeleteButton
                            onClick={() => {
                              let NewItemAdd = {
                                check: "false",
                                option: "",
                                amount: "",
                              };
                              setPriceArray((modifierAdd) => [
                                ...modifierAdd,
                                NewItemAdd,
                              ]);
                            }}
                          >
                            Add
                          </StyledDeleteButton>
                        ) : (
                          <PriceTitle></PriceTitle>
                        )}
                      </IngridientsInputContainer>
                    );
                  })}
                </>
              )}
              <TextAreaWrapper>
                <StyledTextField
                  onChange={handleChange}
                  name="description"
                  fullWidth
                  multiline
                  value={dishData.description}
                  error={errors.description && errors.description.length > 0}
                  helperText={errors.description}
                  minRows="4"
                  cols="66"
                  label="Enter Detailed description about the dish"
                />
              </TextAreaWrapper>
              {errors.description && errors.description.length > 0 && (
                <HelperText>{errors.description}</HelperText>
              )}
              <TextAreaWrapper>
                <IngridientsInputContainer>
                  <TextField
                    label="Ingredients"
                    onChange={(e) => {
                      setAddIngredients(e.target.value);
                      if (e.target.value.length >= 1) {
                        setIsOpen(true);
                      } else {
                        setIsOpen(false);
                      }
                    }}
                    fullWidth
                    name="ingredients"
                    value={addIngredients}
                    error={errors.ingredients && errors.ingredients.length > 0}
                    helperText={errors.ingredients}
                    variant="outlined"
                  />
                  <div>
                    <StyledAddIngridientsButton onClick={handleAddIngredients}>
                      Add Ingredients
                    </StyledAddIngridientsButton>
                  </div>
                </IngridientsInputContainer>
                <IngridientsDropdownContainer>
                  {isOpen && (
                    <DropdownContainer>
                      {ingredientsData.length > 0
                        ? ingredientsData
                            .filter((val) => {
                              if (val.includes(addIngredients.toUpperCase())) {
                                return val;
                              } else {
                                return false;
                              }
                            })
                            .map((x) => (
                              <DropdownField onClick={() => addSelected(x)}>
                                {x}
                              </DropdownField>
                            ))
                        : null}
                    </DropdownContainer>
                  )}
                </IngridientsDropdownContainer>
                <IngridientsContainer>
                  {dishData?.ingredients.map((ingre) => (
                    <StyledRemoveIngridientsButton key={ingre}>
                      <span
                        onClick={() => {
                          handleRemoveIngredients(ingre);
                        }}
                      >
                        x
                      </span>
                      {ingre}
                    </StyledRemoveIngridientsButton>
                  ))}
                </IngridientsContainer>
              </TextAreaWrapper>

              <CategoryContainer>
                <InputContaner>
                  <div>
                    <FormControl margin="normal" fullWidth variant="outlined">
                      <StyledInputLabel
                        isError={
                          errors.categoryId && errors.categoryId.length > 0
                        }
                        id="category"
                      >
                        Dish Menu Category
                      </StyledInputLabel>

                      <StyledSelect
                        label="Dish Menu Category"
                        labelId="category"
                        name="categoryId"
                        value={
                          categorySubCategoryList?.filter(
                            (id) => id.id === dishData.categoryId
                          )[0]?.name
                        }
                        error={
                          errors.categoryId && errors.categoryId.length > 0
                        }
                      >
                        {categorySubCategoryList.length > 0 &&
                          categorySubCategoryList.map((item) => (
                            <MenuItem
                              value={item.name}
                              id={item.id}
                              key={item.id}
                              onClick={(e) =>
                                handleCategorySelect(e, "categoryId")
                              }
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                      </StyledSelect>
                    </FormControl>
                  </div>
                  {errors.categoryId && errors.categoryId.length > 0 && (
                    <HelperText>{errors.categoryId}</HelperText>
                  )}
                </InputContaner>
                <InputContaner>
                  <div>
                    <FormControl margin="normal" fullWidth variant="outlined">
                      <StyledInputLabel
                        isError={
                          errors.subcategoryId &&
                          errors.subcategoryId.length > 0
                        }
                        id="category"
                      >
                        Dish Menu Sub-Category
                      </StyledInputLabel>
                      <InputLabel id="category"></InputLabel>
                      <StyledSelect
                        label="Dish Menu Sub-Category"
                        labelId="subCategory"
                        name="subcategoryId"
                        // onChange={handleChange}
                        value={
                          categorySubCategoryList
                            ?.filter((id) => id.id === dishData.categoryId)[0]
                            ?.subcategories.filter(
                              (id) => id.id === dishData.subcategoryId
                            )[0]?.name
                        }
                        error={
                          errors.subcategoryId &&
                          errors.subcategoryId.length > 0
                        }
                        // disabled={!dishData.category}
                      >
                        {categorySubCategoryList?.length > 0 &&
                          categorySubCategoryList
                            ?.filter((id) => id.id === dishData.categoryId)[0]
                            ?.subcategories?.map((item) => (
                              <MenuItem
                                value={item.name}
                                id={item.id}
                                key={item.id}
                                onClick={(e) =>
                                  handleCategorySelect(e, "subcategoryId")
                                }
                              >
                                {item.name}
                              </MenuItem>
                            ))}
                      </StyledSelect>
                    </FormControl>
                  </div>
                  {errors.subcategoryId && errors.subcategoryId.length > 0 && (
                    <HelperText>{errors.subcategoryId}</HelperText>
                  )}
                </InputContaner>
              </CategoryContainer>

              {
                <SubCategoryContainer>
                  <div>
                    <FormControlLabel
                      control={
                        <StyledCheckbox
                          checked={dishOftheDay.dishOftheDay}
                          onChange={handleChangeCheckBox}
                          name="checkedA"
                        />
                      }
                      label="Dish of the day"
                    />
                  </div>

                  <div>
                    <FormControlLabel
                      control={
                        <StyledCheckbox
                          checked={cheifSpecial.cheifSpecial}
                          onChange={handleChangeCheckBox}
                          name="checkedB"
                        />
                      }
                      label="Chef's special"
                    />
                  </div>
                </SubCategoryContainer>
              }
              <span style={{ textAlign: "left" }}>
                <li>Add Dish Icon Picture.</li>
              </span>
              <FileWrapper>
                <FileUpload>
                  <input
                    type="file"
                    ref={iconInput}
                    onChange={(e) => handleFileChange(e, "icon")}
                  />
                  <Button
                    onClick={() => iconInput.current.click()}
                    disabled={
                      dishData.icon || isLoadingCover || isLoadingDishPhotos
                    }
                  >
                    + Add Dish Icon Picture
                  </Button>
                </FileUpload>
                {isLoadingIcon && <CircularProgress />}
                {dishData?.icon?.length > 0 && (
                  <UploadedFile>
                    <img
                      width={35}
                      height={35}
                      src={dishData.icon}
                      alt="cover"
                    />

                    <DeleteIcon
                      onClick={() => {
                        iconInput.current.value = null;
                        handleDeleteFile("icon");
                      }}
                    >
                      <StyledCancelIcon />
                    </DeleteIcon>
                  </UploadedFile>
                )}
              </FileWrapper>
              {errors.icon && <HelperText>{errors.icon}</HelperText>}
              <span style={{ textAlign: "left" }}>
                <li class="selected">
                  Add Dish Banner Picture. If not added Dish Icon Picture will
                  be displayed as Banner Picture.
                </li>
              </span>
              <FileWrapper>
                <FileUpload>
                  <input
                    type="file"
                    ref={coverInput}
                    onChange={(e) => handleFileChange(e, "coverPhoto")}
                  />
                  <Button
                    onClick={() => coverInput.current.click()}
                    disabled={
                      dishData.coverPhoto ||
                      isLoadingIcon ||
                      isLoadingDishPhotos
                    }
                  >
                    + Add Dish Banner Picture
                  </Button>
                </FileUpload>
                {isLoadingCover && <CircularProgress />}
                {dishData?.coverPhoto?.length > 0 && (
                  <UploadedFile>
                    <img
                      width={35}
                      height={35}
                      src={dishData?.coverPhoto}
                      alt="cover"
                    />

                    <DeleteIcon
                      onClick={() => {
                        coverInput.current.value = null;
                        handleDeleteFile("coverPhoto");
                      }}
                    >
                      <StyledCancelIcon />
                    </DeleteIcon>
                  </UploadedFile>
                )}
              </FileWrapper>
              {errors.coverPhoto && (
                <HelperText>{errors.coverPhoto}</HelperText>
              )}
              <span style={{ textAlign: "left" }}>
                <li class="selected">
                  Dish Icon and Dish Banner Pictures will be displayed in the
                  dish details. Add Additional Pictures that needs to be
                  displayed.
                </li>
              </span>
              <FileWrapper>
                <FileUpload>
                  <input
                    type="file"
                    ref={dishPhotosInputs}
                    multiple
                    onChange={(e) => handleFileChange(e, "dishPhotos")}
                  />
                  <Button
                    disabled={isLoadingIcon || isLoadingCover}
                    onClick={() => dishPhotosInputs.current.click()}
                  >
                    + Add Additional Pictures
                  </Button>
                </FileUpload>
                {isLoadingDishPhotos && <CircularProgress />}
                <div>
                  {dishData?.dishPhotos?.length > 0 &&
                    dishData?.dishPhotos.map((photo) => (
                      <UploadedFile>
                        <img width={35} height={35} src={photo} alt="cover" />
                        <DeleteIcon
                          onClick={() => {
                            handleDeleteFile("dishPhotos", photo);
                            dishPhotosInputs.current.value = null;
                          }}
                        >
                          <StyledCancelIcon />
                        </DeleteIcon>
                      </UploadedFile>
                    ))}
                </div>
              </FileWrapper>
              <DishToggleBox>
                <span>Enable Dish</span>
                <Switch
                  checked={!dishData.isDisabled}
                  color="primary"
                  name="isDisabled"
                  inputProps={{ "aria-label": "primary checkbox" }}
                  onChange={handleChangeToggle}
                />
              </DishToggleBox>

              <ButtonContainer>
                <StyledCloseButton onClick={handleClose}>
                  Close
                </StyledCloseButton>
                {upDateToDishId && (
                  <StyledDeleteButton onClick={deleteDish}>
                    Delete
                  </StyledDeleteButton>
                )}
                <StyledSaveButton
                  type="submit"
                  disabled={
                    isLoadingDishPhotos || isLoadingCover || isLoadingIcon
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    if (validate()) {
                      setSelectPriceOption("");
                      handleSubmitDish(e);
                    }
                  }}
                >
                  {upDateToDishId ? "Update" : "Save"}
                </StyledSaveButton>
              </ButtonContainer>
            </ModalForm>
          </ContentContainer>
        </ModalContentContainer>
      </Modal>
      <ConfirmationDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        deleteDish={deleteDish}
        isLoadingDelete={isLoadingDelete}
      />
    </div>
  );
}

export default CancelModal;
