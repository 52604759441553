import styled from "styled-components/macro";
import { Typography } from "@material-ui/core";
import { Button, FormControlLabel, withTheme } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import StarIcon from "@material-ui/icons/Star";
import Timestamp from "react-timestamp";

const ModalContentContainer = withTheme(styled.div.attrs(() => ({
  className: "modal-content-container",
}))`
  position: absolute;
  width: 550px;
  border: 1px solid #000;
  height: 500px;
  overflow-y: scroll;
  ${({ theme }) => `
      background-color: ${theme.palette.background.paper};
      box-shadow: ${theme.shadows[5]};
      padding: ${theme.spacing(2, 4, 3)};
    `}
`);

const FormContainer = styled.div`
  padding: 40px;
`;

const StyledModal = styled(Modal)`
  &&& {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #dcd7d780;
  }
`;

const IconButtonContainer = styled.div`
  text-align: right;
`;

const ModalHeader = styled.h1`
  text-align: "center";
  margin: 0px;
`;
const StyledHeader = styled(Typography).attrs(() => ({
  component: "h1",
  variant: "h5",
}))`
  &&& {
    text-align: center;
    margin-bottom: 20px;
    font-weight: 600;
    text-transform: capitalize;
  }
`;

const HideButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const UnhideButtonContainer = styled(HideButtonContainer)`
  & {
    margin-top: 5rem;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  & .review-description {
    font-size: 12px;
  }
`;

const Header = styled.h1`
  text-align: center;
`;

const Hint = styled.div`
  margin-left: 3rem;
  background-color: #e4e4e4;
  padding: 0.5rem;
  width: 90%;
  font-weight: bold;
  border-radius: 3px;
  color: red;
`;

const ButtonContainer = styled.div`
  // margin: 1rem 0rem 2rem 3rem;
`;

const StyledAllReviewsButton = styled(Button).attrs(({ isOnlyFlagged }) => ({
  variant: "contained",
  color: !isOnlyFlagged ? "secondary" : "",
}))`
  &&& {
    background-color: ${({ isOnlyFlagged }) => (isOnlyFlagged ? "#fff" : "")};
  }
`;

const StyledFlaggedReviesButton = styled(Button).attrs(({ isOnlyFlagged }) => ({
  variant: "contained",
  color: isOnlyFlagged ? "secondary" : "",
}))`
  &&& {
    background-color: ${({ isOnlyFlagged }) => (!isOnlyFlagged ? "#fff" : "")};
  }
`;

const ReviewsContainer = styled.div`
  width: 100%;
  margin: 20px auto;
`;

const CommentContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: red;

  &:not(:first-child) {
    margin-left: 1rem;
  }
`;

const UserPhoto = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 100%;
`;

const ReviewUserContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const UserContainer = styled.div`
  display: flex;
  align-items: center;
`;

const UserNameContainer = styled.div`
  margin: 0px 0px 0px 10px;
`;

const UserName = styled.p`
  margin: 0px;
  font-weight: bold;
  font-size: 12px;
  color: ${({ isFlagged }) => (isFlagged ? "red" : "")};
`;

const UserType = styled.p`
  margin: 0px;
  fontsize: 12px;
`;

const StarsContainer = styled.div`
  display: flex;
  margin-top: 0.5rem;
`;

const StyledStarIcon = styled(StarIcon)`
  color: red;
`;

const StyledTimestamp = styled(Timestamp)`
  &&& {
    margin-left: 2rem;
  }
`;

const DishName = styled.p`
  color: ${({ isFlagged }) => (isFlagged ? "red" : "")};
  padding: 0.5rem;
  font-weight: bold;
  border-radius: 3px;
`;

const Message = styled.p`
  color: &{({isFlagged}) => (isFlagged ? "red" : "")};
  background-color: #e4e4e4;
  padding: 0.5rem;
  width: 90%;
  font-weight: bold;
  border-radius: 3px;
`;

const ImageContainer = styled.div`
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  margin: 10px 0px 10px 0px;

  & img {
    width: 200px;
    height: 200px;
    border-radius: 5px;
  }
`;

const ReplyContainer = styled.div`
  display: "flex";
  align-items: center;
  gap: "3rem";
  border-top: "1px solid black";
  border-bottom: ${({ isReplyOpen }) =>
    isReplyOpen ? "1px solid #fff" : "1px solid black"};
`;

const TextFieldContainer = styled.div`
  display: flex;
  margin-top: 1rem;
`;

const ReplyButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCheckmarkIcon = styled(CheckCircleOutlineIcon)`
  color: #1bb55c;
  cursor: pointer;
  margin-left: 5px;
`;

const StyledCancelIcon = styled(HighlightOffIcon)`
  color: #dd1d1d;
  cursor: pointer;
  margin-left: 5px;
`;

const CircularProgressContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ButtonsContainer = styled.div`
  // width: 50%;
  // margin: 30px auto 20px auto;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  margin-bottom: 0;
`;
const StyledCancelButton = styled(Button).attrs(() => ({
  variant: "contained",
}))`
  &&& {
    background-color: white;
    color: #ef403b;
    border: 1px solid #ef403b;
    //width: 142px;
    padding: 5px 30px !important;
    border-radius: 8px !important;
    font-weight: bold !important;
    margin: 10px !important;
  }
`;
const StyledSaveButton = styled(Button).attrs(() => ({
  variant: "contained",
}))`
  &&& {
    background-color: #ef403b;
    color: white;
    //width: 142px;
    padding: 5px 30px !important;
    border-radius: 8px !important;
    font-weight: bold !important;
    margin: 10px !important;
  }
`;

export {
  ModalContentContainer,
  StyledModal,
  IconButtonContainer,
  ModalHeader,
  FormContainer,
  StyledHeader,
  HideButtonContainer,
  UnhideButtonContainer,
  StyledFormControlLabel,
  Header,
  Hint,
  ButtonContainer,
  StyledAllReviewsButton,
  StyledFlaggedReviesButton,
  ReviewsContainer,
  CommentContainer,
  UserPhoto,
  ReviewUserContainer,
  UserContainer,
  UserNameContainer,
  UserName,
  UserType,
  StarsContainer,
  StyledStarIcon,
  StyledTimestamp,
  Message,
  ImageContainer,
  ReplyContainer,
  TextFieldContainer,
  ReplyButtonContainer,
  StyledCheckmarkIcon,
  StyledCancelIcon,
  CircularProgressContainer,
  DishName,
  ButtonsContainer,
  StyledCancelButton,
  StyledSaveButton,
};
