import styled from "styled-components/macro";
import topFooter from "../../assets/topFooter.JPEG";

const footer = styled.div`
  background-color: #000;
  text-align: center;
  padding: 10px;
  width: auto;
  height: 27px;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  justify-content: space-between;
  align-item: center;
`;

const footerTop = styled.div`
  text-align: center;
  padding: 10px;
  width: 99%;
  height: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-item: center;
  padding-right: 20px;
`;

const styles = {
  backgroundImage: `url(${topFooter})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  height: "30px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundPosition: "bottom",
};

const detailView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-item: center;
  width: 30%;
  background-color: #fff;
`;

const TextStyle = styled.div`
  font-size: 11px;
  font-weight: 400;
  color: #fff;
  text-align: center;
  align-item: center;
  justify-content: center;
  margin-left: 20px;
`;

const TextTitle = styled.div`
  font-size: 15px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  align-item: center;
  justify-content: center;
`;

export { footer, TextStyle, detailView, styles, footerTop, TextTitle };
