export const sidebarList = [
  {
    id: "home",
    name: "Home",
    navigateTo: "/register/home",
  },
  {
    id: "account_details",
    name: "Account Details",
    navigateTo: "/register/account-details",
  },
  {
    id: "restaurant_details",
    name: "Restaurant Details",
    navigateTo: "/register/restaurant-details",
  },
  {
    id: "about_us",
    name: "About Us",
    navigateTo: "/register/about-us",
  },
  {
    id: "categories",
    name: "Categories/Sub-Categories",
    navigateTo: "/register/categories",
  },
  {
    id: "menu_items",
    name: "Menu Items",
    navigateTo: "/register/menu-items",
  },
  {
    id: "featured",
    name: "Featured",
    navigateTo: "/register/featured",
  },
  {
    id: "sponsored",
    name: "Sponsored",
    navigateTo: "/register/promotions",
  },
  {
    id: "qr_code",
    name: "QR Code",
    navigateTo: "/register/qr-code",
  },
  {
    id: "review",
    name: "Reviews",
    navigateTo: "/register/review",
  },
  {
    id: "setting",
    name: "Restaurant Settings",
    navigateTo: "/register/setting",
  },
];

export const initialState = {
  id: "",
  value: "",
};

export const generateId = (prefix) => {
  return Math.random()
    .toString(36)
    .replace("0.", prefix || "");
};

export const restaurantTimings = [
  {
    name: "brunch",
    heading: "Brunch Timings",
  },
  {
    name: "lunch",
    heading: "Lunch Timings",
  },
  {
    name: "dinner",
    heading: "Dinner Timings",
  },
];

export const featuredPromotionsInitialState = {
  id: "",
  backgroundImage: null,
  description: "",
  checked: false,
};

export const categoryInitialState = {
  id: "",
  checked: false,
  subCategories: [],
  category: "",
};

export const subCategoryInitialState = {
  id: "",
  checked: false,
  subCategory: "",
};

export const defaultCategories = [
  "Small Plates/Snacks/Appetizers",
  "Soups/Salads",
  "Lunch Menu",
  "Dinner Menu",
];

export const dummyMenuListData = [
  {
    id: "1",
    category: "Small Plates/Snacks/Appetizers",
    subCategories: [
      {
        id: "1",
        subCategory: "Veg Small Plates & Snacks",
        dishes: [],
      },
      {
        id: "2",
        subCategory: "Non-Veg Small Plates & Snacks",
        dishes: [],
      },
      {
        id: "3",
        subCategory: "Veg Appetizers",
        dishes: [],
      },
      {
        id: "4",
        subCategory: "Non-Veg Appetizers",
        dishes: [],
      },
    ],
  },
  {
    id: "2",
    category: "Soups/Salads",
    subCategories: [
      {
        id: "1",
        subCategory: "Veg Small Plates & Snacks",
        dishes: [],
      },
      {
        id: "2",
        subCategory: "Non-Veg Small Plates & Snacks",
        dishes: [],
      },
      {
        id: "3",
        subCategory: "Veg Appetizers",
        dishes: [],
      },
      {
        id: "4",
        subCategory: "Non-Veg Appetizers",
        dishes: [],
      },
    ],
  },
  {
    id: "3",
    category: "Lunch Menu",
    subCategories: [
      {
        id: "1",
        subCategory: "Veg Small Plates & Snacks",
        dishes: [],
      },
      {
        id: "2",
        subCategory: "Non-Veg Small Plates & Snacks",
        dishes: [],
      },
      {
        id: "3",
        subCategory: "Veg Appetizers",
        dishes: [],
      },
      {
        id: "4",
        subCategory: "Non-Veg Appetizers",
        dishes: [],
      },
    ],
  },
  {
    id: "4",
    category: "Dinner Menu",
    subCategories: [
      {
        id: "1",
        subCategory: "Veg Small Plates & Snacks",
        dishes: [],
      },
      {
        id: "2",
        subCategory: "Non-Veg Small Plates & Snacks",
        dishes: [],
      },
      {
        id: "3",
        subCategory: "Veg Appetizers",
        dishes: [],
      },
      {
        id: "4",
        subCategory: "Non-Veg Appetizers",
        dishes: [],
      },
    ],
  },
];
