import { FlaggedReviewsWrapper, StyledWarningOutlinedIcon } from "./styles";

function FlaggedReviews(props) {
  const { flagCount } = props;
  return (
    <>
      {flagCount > 0 && (
        <FlaggedReviewsWrapper>
          <StyledWarningOutlinedIcon></StyledWarningOutlinedIcon> You have{" "}
          {flagCount} flagged reviews
        </FlaggedReviewsWrapper>
      )}
    </>
  );
}
export default FlaggedReviews;
