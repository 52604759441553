import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

function Protected(props) {
  const { Component } = props;
  const { user } = useSelector((state) => state.user);
  return user || JSON.parse(localStorage.getItem("user")) ? (
    <Component />
  ) : (
    <Navigate to='/login' />
  );
}

export default Protected;
