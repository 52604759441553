import { useEffect, useState } from "react";
import logo from "../../../../../assets/header.svg";
import {
  doc,
  getDoc,
  where,
  collectionGroup,
  query,
  getDocs,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { db } from "../../../../../firebase";
import LoadingModal from "../../../../baseComponents/LoadingModal";
import restaurant_avatar from "../../../../../assets/restaurant_avatar.png";
import {
  PageContainer,
  BlockContainer,
  ButtonContainer,
  Header,
  Tip,
  StyledButton,
  DetailsContainer,
  ImageContainer,
  CoverPhoto,
  LogoImage,
  HeaderContainer,
  AccountName,
  AddressContainer,
  StyledRoomOutlinedIcon,
  AddressDetails,
  StyledSaveButton,
  StyledSaveButtonWrapper,
} from "./styles";
import DataSummary from "./DataSummary";
import FlaggedReviews from "./flagReviews";

function Home() {
  const navigate = useNavigate();
  const [restaurantDetails, setRestaurantsDetails] = useState({});
  const [isLoading, setIsloading] = useState(false);
  const [numberOfFlggedReview, setNumberOfFlggedReview] = useState(0);

  // useEffect(() => {
  //   setIsloading(true);
  //   localStorage.setItem("uid", JSON.parse(localStorage.getItem("user")).uid);
  //   const getData = async () => {
  //     const docRef = doc(
  //       db,
  //       "restaurants",
  //       JSON.parse(localStorage.getItem("user")).uid
  //     );

  //     const docSnap = await getDoc(docRef);
  //     console.log(
  //       "Get User DataLLL:::",
  //       docSnap?.data(),
  //       localStorage.getItem("uid")
  //     );
  //     if (docSnap?.data() && docSnap?.data()?.promotions?.length > 0) {
  //       setRestaurantsDetails(docSnap?.data());
  //     }

  //     setIsloading(false);
  //   };

  //   getData();
  //   setIsloading(false);
  // }, []);

  useEffect(() => {
    setIsloading(true);
    if (localStorage.getItem("uid")) {
      localStorage.setItem("uid", localStorage.getItem("uid"));
    } else {
      localStorage.setItem("uid", JSON.parse(localStorage.getItem("user")).uid);
    }
    const getData = async () => {
      const docRef = doc(db, "restaurants", localStorage.getItem("uid"));
      const docSnap = await getDoc(docRef);
      console.log(
        "Get User DataLLL:::",
        docSnap?.data(),
        localStorage.getItem("uid")
      );
      if (docSnap?.data()) {
        setRestaurantsDetails(docSnap?.data());
      }
      setIsloading(false);
    };
    getData();
    setIsloading(false);
  }, []);

  const redirectToAccountDetailsHandler = () => {
    navigate("/register/restaurant-details");
  };

  useEffect(() => {
    const data = [];
    const getData = async () => {
      const museums = query(
        collectionGroup(db, "reviews"),
        where("restaurantId", "==", localStorage.getItem("uid"))
      );
      const querySnapshot = await getDocs(museums);
      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, reviewData: doc.data() });
      });
      setNumberOfFlggedReview(data.filter((u) => u.reviewData.flag).length);
    };
    getData();
  }, []);

  return (
    <>
      {!Object.keys(restaurantDetails).length > 0 && (
        <PageContainer>
          <BlockContainer>
            <img src={logo} alt="logo" height={62} />
          </BlockContainer>
          <div>
            <Header>Welcome</Header>
            <Tip>Start adding your restaurants details</Tip>
          </div>
          <ButtonContainer>
            <StyledButton onClick={redirectToAccountDetailsHandler}>
              Start journey
            </StyledButton>
          </ButtonContainer>
        </PageContainer>
      )}

      {Object.keys(restaurantDetails).length > 0 && (
        <div>
          <DetailsContainer>
            <div>
              {/* <ImageContainer>
                <CoverPhoto src={restaurantDetails.coverPhoto || logo} alt="cover" />
              </ImageContainer> */}
              <ImageContainer>
                {restaurantDetails.icon ? (
                  <CoverPhoto src={restaurantDetails.icon} alt="cover" />
                ) : (
                  <LogoImage src={restaurant_avatar} alt="logo" height={62} />
                )}
              </ImageContainer>
            </div>

            <div>
              <HeaderContainer>
                <AccountName>{restaurantDetails.name}</AccountName>
                {restaurantDetails.cuisine?.name !== undefined ? (
                  <span style={{ fontSize: "22px" }}>
                    ({restaurantDetails.cuisine?.name})
                  </span>
                ) : (
                  <span style={{ fontSize: "22px" }}>
                    ({restaurantDetails.cuisine?.join(", ")})
                  </span>
                )}
              </HeaderContainer>
              <AddressContainer>
                <div>
                  <StyledRoomOutlinedIcon />
                </div>
                <AddressDetails>
                  {restaurantDetails?.address?.addressLine1},{" "}
                  {restaurantDetails?.address?.addressLine2},{" "}
                  {restaurantDetails?.address?.city},{" "}
                  {restaurantDetails?.address?.state},{" "}
                  {restaurantDetails?.address?.zipCode},{" "}
                  {restaurantDetails?.address?.country}
                </AddressDetails>
              </AddressContainer>
            </div>
          </DetailsContainer>
        </div>
      )}
      <FlaggedReviews flagCount={numberOfFlggedReview}></FlaggedReviews>

      <DataSummary></DataSummary>
      {isLoading && <LoadingModal />}

      <StyledSaveButtonWrapper>
        <StyledSaveButton
          type="Button"
          onClick={() => {
            navigate("/register/account-details");
          }}
        >
          Next &gt;
        </StyledSaveButton>
      </StyledSaveButtonWrapper>
    </>
  );
}

export default Home;
