import React, { useState } from "react";
import { IconButton } from "@material-ui/core";
import {
  SubcategoriesWrapper,
  SubcategoryName,
  EditButton,
  StyledCheckbox,
} from "./styles";
import Modal from "../../deleteModal/DeleteModal";
import {
  Delete as DeleteOutlineIcon,
  Edit as EditOutlineIcon,
} from "@material-ui/icons";
import menu from "../../../../../assets/category-menu.png";

function SubCategoriesCard(props) {
  const {
    subCategory,
    setData,
    handleSubmit,
    data,
    handleOpenClick,
    setIsCategorySelected,
    onDragStart,
    onDragEnter,
    onDragEnd,
    index,
  } = props;
  const [open, setOpen] = useState(false);
  const categoryId = data.id;

  const handleSubCategoryChecked = (e) => {
    // if (!data.isDisabled) {
    setData((prev) => {
      var tempdata = prev.map((val) => {
        if (data.id === val.id) {
          if (data.checkCategory) {
            return {
              id: val.id,
              checkCategory: val.checkCategory,
              name: val.name,
              order: val.order,
              subcategories: val.subcategories.map((subVal) => {
                console.log("Get Data>", subVal);
                if (subVal.id === subCategory.id) {
                  return {
                    checkSubCategoryChecked: !subVal.checkSubCategoryChecked,
                    id: subVal.id,
                    name: subVal.name,
                    order: subVal.order,
                  };
                }
                return subVal;
              }),
            };
          } else {
            setIsCategorySelected(false);
          }
        }
        return val;
      });
      handleSubmit(tempdata);
      return tempdata;
    });
  };

  // working
  const handleDeleteSubCategory = (subId, categoryId) => {
    setData((prev) => {
      const updatedData = prev.map((val) => {
        if (val.id === categoryId) {
          return {
            ...val,
            subcategories: val.subcategories.filter((sub) => sub.id !== subId),
          };
        }
        return val;
      });
      handleSubmit(updatedData);
      return updatedData;
    });
  };

  const handleClose = () => setOpen(false);

  return (
    <SubcategoriesWrapper
      onDragStart={onDragStart}
      onDragEnter={onDragEnter}
      onDragEnd={onDragEnd}
      key={index}
      draggable
    >
      <img src={menu} alt="" height="20" />
      <StyledCheckbox
        onChange={handleSubCategoryChecked}
        checked={subCategory.checkSubCategoryChecked}
      />
      <SubcategoryName>{subCategory.name}</SubcategoryName>
      {/* <EditButton
        onClick={() => handleOpenClick(subCategory.name, subCategory.id)}
      >
        Edit
      </EditButton> */}
      <EditButton
        onClick={() => handleOpenClick(subCategory.name, subCategory.id)}
      >
        <EditOutlineIcon style={{ color: "red" }} />
      </EditButton>
      {/* <button onClick={() => handleDeleteSubCategory(subCategory.id, categoryId)}>Delete</button> */}
      <IconButton onClick={() => setOpen(true)}>
        <DeleteOutlineIcon style={{ color: "red" }} />
      </IconButton>
      {open && (
        <Modal
          title={"Delete SubCategory"}
          open={open}
          handleDeleteCategory={() =>
            handleDeleteSubCategory(subCategory.id, categoryId)
          }
          handleClose={handleClose}
          message={
            "Do you want to delete the sub-category? All dishes within will be shown without a sub-category or under ‘Others’ as applicable."
          }
        />
      )}
    </SubcategoriesWrapper>
  );
}

export default SubCategoriesCard;
