import styled from "styled-components/macro";

const Container = styled.div`
  width: calc(100% - 0px);
  min-height: calc(100vh - 195px);
  margin: 0 auto;
  display: flex;
  flex: 1;
  overflow: hidden;
`;

const Forms = styled.div`
  // margin-left: 64px;
  // margin-right: 180px;
  // margin-top: 54px;

  margin-left: clamp(10px, 3vw, 64px);
  margin-right: clamp(10px, 3vw, 180px);
  margin-top: clamp(10px, 3vw, 64px);
  // margin-bottom: clamp(10px, 5vw, 64px);

  background-color: rgb(255, 255, 255);
  width: 100%;
  overflow: auto;
  position: relative;
  padding-bottom: 70px;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: white;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 50px;
  }
`;

export { Container, Forms };
