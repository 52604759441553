import React, { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import Form from "./Form";

function AboutUs() {
  const handleChipClick = useOutletContext();

  useEffect(() => {
    handleChipClick("about_us");
  }, [handleChipClick]);

  return <Form />;
}

export default AboutUs;
