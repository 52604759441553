import styled from "styled-components/macro";

const Container = styled.div`
  width: 60%;
  margin: 0 auto;
  min-height: calc(100vh - 259px);

  /* Media query for phones */
  @media only screen and (max-width: 1300px) {
    width: 90%;
  }

  /* Media query for desktops and larger screens */
  @media only screen and (min-width: 1300px) {
    width: 60%;
  }
`;

const Header = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 20px;
`;

const AddButtonContainer = styled.div`
  margin-bottom: 20px;
  margin-right: 20%;
  margin-left: auto;
  text-align: right;

  /* Media query for phones */
  @media only screen and (max-width: 1300px) {
    margin-right: 5%;
  }

  & a:first-of-type {
    text-decoration: none;
  }

  & button:first-of-type {
    width: 150px;
  }
`;

export { Container, Header, AddButtonContainer };
