import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import done from "../../../../../assets/done.svg";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
  Button,
  FormControl,
  TextField,
  CircularProgress,
} from "@material-ui/core";
// import { makeAPiCall } from "../../../../../services/api";

import { db, storage } from "../../../../../firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import LoadingModal from "../../../../baseComponents/LoadingModal";

import FeaturedCard from "./FeaturedCard";

import {
  Container,
  StyledForm,
  StyledHeader,
  StyledTips,
  ItemWrapper,
  CheckboxTextWrapper,
  FileWrapper,
  FileUpload,
  UploadedFile,
  DeleteIcon,
  HelperText,
  StyledCancelIcon,
  StyledAddFeaturedButton,
  IconsContainer,
  StyledCheckCircleOutline,
  StyledHighlightOff,
  ButtonsContainer,
  StyledCancelButton,
  StyledSaveButton,
} from "./styles";

function Form(props) {
  const navigate = useNavigate();

  // const inputImage = useRef([]);
  const inputImage = useRef();

  const [featureItemsList, setFeatureItemsList] = useState([]);
  const [featureItems, setFeatureItems] = useState({
    name: "",
    background: "",
    url: "",
    color: "",
    isEnabled: false,
  });

  const [errors, setErrors] = useState({});
  const [isloading, setIsloading] = useState(false);

  const [isUpdate, setIsUpdate] = useState(false);
  const [showField, setShowField] = useState(true);
  const [selectedField, setSelectedField] = useState("");
  const [editableValue, setEditableValue] = useState({
    name: "",
    background: "",
    url: "",
    color: "",
    isEnabled: false,
  });

  const [dataSubmitted, setDataSubmitted] = useState(false);

  const [isLoadingPage, setIsloadingPage] = useState(false);

  const validate = () => {
    const errors = {};

    errors.name =
      featureItems.name.length > 0 && featureItems?.name.length <= 100
        ? ""
        : "Description over 100 characters";
    errors.name = featureItems.name.length > 0 ? "" : "Description Is Required";
    // errors.url =
    //   featureItems.url.length > 0 ? "" : "Background image Is Required";

    setErrors({ ...errors });
    return Object.values(errors).every((error) => error === "");
  };

  useEffect(() => {
    if (localStorage.getItem("uid")) {
      const getData = async () => {
        const docRef = doc(db, "restaurants", localStorage.getItem("id"));
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          if (docSnap.data().featured?.length >= 1) {
            setShowField(false);
          }
        }
      };
      getData();
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("uid")) {
      setIsloadingPage(true);
      localStorage.setItem("id", localStorage.getItem("uid"));
      const getData = async () => {
        const docRef = doc(db, "restaurants", localStorage.getItem("id"));
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          var fList = docSnap.data().featured;
          if (fList?.length > 0) {
            fList.forEach((item, index) => {
              if (fList[index]["isEnabled"] === undefined) {
                fList[index]["isEnabled"] = false;
              }
            });
            console.log(fList);

            setFeatureItemsList(fList);
            setIsloadingPage(false);
          } else {
            setIsloadingPage(false);
          }
        } else {
          setIsloadingPage(false);
        }
      };
      getData();
    }
  }, [dataSubmitted]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsloadingPage(true);

    const docRef = doc(db, "restaurants", localStorage.getItem("id"));
    const docSnap = await getDoc(docRef);
    if (isUpdate) {
      setDoc(docRef, { featured: [...featureItemsList] }, { merge: true }).then(
        (doc) => {
          setDataSubmitted((prev) => !prev);
        }
      );

      setIsUpdate(false);
      setIsloadingPage(false);
      setFeatureItems({
        name: "",
        background: "",
        url: "",
        color: "",
        isEnabled: false,
      });
      setEditableValue({
        name: "",
        background: "",
        url: "",
        color: "",
        isEnabled: false,
      });
    } else {
      if (validate()) {
        if (docSnap.exists()) {
          if (docSnap.data().featured) {
            setDoc(
              docRef,
              { featured: [...docSnap.data().featured, featureItems] },
              { merge: true }
            ).then((doc) => {
              setDataSubmitted((prev) => !prev);
            });
          } else {
            setDoc(docRef, { featured: [featureItems] }, { merge: true }).then(
              (doc) => {
                setDataSubmitted((prev) => !prev);
              }
            );
          }
          setIsloadingPage(false);
          setFeatureItems({
            name: "",
            background: "",
            url: "",
            color: "",
            isEnabled: false,
          });
          setEditableValue({
            name: "",
            background: "",
            url: "",
            color: "",
            isEnabled: false,
          });
          setShowField(false);
          setIsloadingPage(false);
        } else {
          console.log("No such document!");
          setIsloadingPage(false);
        }
      } else {
        setIsloadingPage(false);
      }
    }
  };

  const handleChange = (e, type) => {
    if (type) {
      setEditableValue(type);
      setSelectedField(type.name);
      setIsUpdate(true);
      setFeatureItemsList((prev) => {
        return prev.map((x) => {
          if (x.name === type.name) {
            return {
              ...x,
              name: e.target.value,
            };
          }
          return {
            ...x,
          };
        });
      });
      console.log(featureItemsList);
    } else {
      setFeatureItems((prev) => {
        return {
          ...prev,
          name: e.target.value,
        };
      });
    }
  };

  const handleFileChange = (e, type, val) => {
    const { files } = e.target;

    let randomId = Math.floor(Math.random() * 899999 + 100000);
    setIsloading(true);
    const storageRef = ref(storage, `${type}/${files[0].name}${randomId}`);

    const uploadTask = uploadBytesResumable(storageRef, files[0]);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        // Handle unsuccessful uploads
        setIsloading(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          if (val) {
            const temp = featureItemsList.filter((u) => u.name === val.name);
            setIsUpdate(true);
            const newObj = {
              name: temp[0].name,
              url: downloadURL,
              color: temp[0].color,
              background: temp[0].background,
              isEnabled: temp[0].isEnabled,
            };

            let CopyfeatureItemsList = featureItemsList;
            const index = CopyfeatureItemsList.indexOf(val);
            CopyfeatureItemsList.splice(index, 1);
            CopyfeatureItemsList.splice(index, 0, newObj);
            setFeatureItemsList([...CopyfeatureItemsList]);
            const docRef = doc(db, "restaurants", localStorage.getItem("id"));
            setDoc(
              docRef,
              { featured: [...featureItemsList] },
              { merge: true }
            ).then((doc) => {
              setDataSubmitted((prev) => !prev);
            });
          } else {
            setFeatureItems((prev) => {
              return {
                ...prev,
                url: downloadURL,
              };
            });
          }

          setEditableValue({
            name: "",
            background: "",
            url: "",
            color: "",
            isEnabled: false,
          });

          setIsloading(false);
        });
      }
    );
  };

  const handleDeleteFileEdit = (data) => {
    setIsloadingPage(true);
    setEditableValue(data);
    const temp = featureItemsList.filter((u) => u.url === data.url);
    const newObj = {
      ...temp[0],
      url: "",
    };
    let CopyfeatureItemsList = featureItemsList;
    const index = CopyfeatureItemsList.indexOf(data);
    CopyfeatureItemsList.splice(index, 1);
    CopyfeatureItemsList.splice(index, 0, newObj);

    setFeatureItemsList([...CopyfeatureItemsList]);
    const docRef = doc(db, "restaurants", localStorage.getItem("id"));
    setDoc(
      docRef,
      { featured: [...CopyfeatureItemsList] },
      { merge: true }
    ).then((doc) => {
      setDataSubmitted((prev) => !prev);
    });

    setIsloadingPage(false);
  };

  const handleDeleteFile = (type) => {
    setFeatureItems((prev) => {
      return {
        ...prev,
        url: "",
      };
    });
  };

  const changeCheckedFeature = (index, val) => {
    setIsloadingPage(true);
    featureItemsList[index].isEnabled = val;
    setFeatureItemsList(featureItemsList);
    const docRef = doc(db, "restaurants", localStorage.getItem("id"));
    setDoc(docRef, { featured: [...featureItemsList] }, { merge: true }).then(
      (doc) => {
        setDataSubmitted((prev) => !prev);
      }
    );
    setIsloadingPage(false);
  };
  const handleOpenClickForEdit = (val) => {
    console.log("handleOpenClickForEdit" + val);
    setIsUpdate(true);
    setFeatureItems(val);

    setSelectedField(val.name);
    setShowField((prev) => true);
  };
  const DeleteFeatured = async (index) => {
    console.log("DeleteFeatured");
    console.log(index);
    featureItemsList.splice(index, 1);
    console.log(featureItemsList);
    setFeatureItemsList(featureItemsList);
    const docRef = doc(db, "restaurants", localStorage.getItem("id"));
    // const docSnap = await getDoc(docRef);
    setDoc(docRef, { featured: [...featureItemsList] }, { merge: true }).then(
      (doc) => {
        setDataSubmitted((prev) => !prev);
      }
    );
  };

  const newSaveSubmit = async () => {
    console.log(isUpdate);
    console.log(featureItems.name);
    const docRef = doc(db, "restaurants", localStorage.getItem("id"));
    const docSnap = await getDoc(docRef);
    if (isUpdate) {
      console.log(selectedField);

      var finaldata = featureItemsList.map((x) => {
        if (x.name === selectedField) {
          return {
            ...featureItems,
          };
        }
        return {
          ...x,
        };
      });
      setFeatureItemsList(finaldata);
      console.log(finaldata);

      setDoc(docRef, { featured: [...finaldata] }, { merge: true }).then(
        (doc) => {
          setDataSubmitted((prev) => !prev);
        }
      );

      setIsUpdate(false);
      setIsloadingPage(false);
      setFeatureItems({
        name: "",
        background: "",
        url: "",
        color: "",
        isEnabled: false,
      });
      setEditableValue({
        name: "",
        background: "",
        url: "",
        color: "",
        isEnabled: false,
      });
    } else {
      if (validate()) {
        if (docSnap.exists()) {
          if (docSnap.data().featured) {
            setDoc(
              docRef,
              { featured: [...docSnap.data().featured, featureItems] },
              { merge: true }
            ).then((doc) => {
              setDataSubmitted((prev) => !prev);
            });
          } else {
            setDoc(docRef, { featured: [featureItems] }, { merge: true }).then(
              (doc) => {
                setDataSubmitted((prev) => !prev);
              }
            );
          }
          setIsloadingPage(false);
          setFeatureItems({
            name: "",
            background: "",
            url: "",
            color: "",
            isEnabled: false,
          });
          setEditableValue({
            name: "",
            background: "",
            url: "",
            color: "",
            isEnabled: false,
          });
          setShowField(false);
          setIsloadingPage(false);
        } else {
          console.log("No such document!");
          setIsloadingPage(false);
        }
      } else {
        setIsloadingPage(false);
      }
    }
  };

  return (
    <Container>
      <StyledForm onSubmit={handleSubmit}>
        <StyledHeader>featured Items</StyledHeader>
        <StyledTips>
          <img src={done} alt="" />
          The items will be displayed in the highlights tab of the featured
          homepage
        </StyledTips>
        <StyledTips>
          <img src={done} alt="" />
          You can add multiple featured items
        </StyledTips>
        {featureItemsList.length > 0 &&
          featureItemsList.map((val, index) => {
            return (
              <FeaturedCard
                key={index}
                index={index}
                FeaturedData={val}
                handleFeaturedChecked={changeCheckedFeature}
                handleOpenClickForEdit={handleOpenClickForEdit}
                DeleteFeatured={DeleteFeatured}
                handleFileChange={handleFileChange}
                isloading={isloading}
                editableValue={editableValue}
                handleDeleteFileEdit={handleDeleteFileEdit}
                featureItemsList={featureItemsList}
                setFeatureItemsList={setFeatureItemsList}
                setDataSubmitted={setDataSubmitted}
              ></FeaturedCard>
            );
          })}

        <ItemWrapper>
          {showField && (
            <CheckboxTextWrapper>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  label="Description"
                  onChange={(e) => handleChange(e)}
                  name="description"
                  value={featureItems.name}
                  error={errors.name}
                  helperText={featureItems.name && errors.name}
                  variant="outlined"
                />
              </FormControl>
              <IconsContainer>
                <StyledCheckCircleOutline
                  onClick={() => {
                    newSaveSubmit();
                  }}
                />
                <StyledHighlightOff
                  onClick={() => {
                    setFeatureItems({
                      name: "",
                      background: "",
                      url: "",
                      color: "",
                      isEnabled: false,
                    });
                    setIsUpdate(false);

                    setShowField((prev) => false);
                  }}
                />
              </IconsContainer>
            </CheckboxTextWrapper>
          )}

          {errors.name && !featureItems.name && (
            <HelperText>{errors.name}</HelperText>
          )}

          {showField && !isUpdate && (
            <FileWrapper>
              <FileUpload>
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={inputImage}
                  onChange={(e) => handleFileChange(e, "featured")}
                  name="backgroundImage"
                />
                <Button onClick={() => inputImage.current.click()}>
                  + Add Background Image
                </Button>
              </FileUpload>
              {isloading && <CircularProgress />}
              {featureItems.url && (
                <UploadedFile>
                  <img
                    width={35}
                    height={35}
                    src={featureItems.url}
                    alt="cover"
                  />
                  <DeleteIcon
                    onClick={() => {
                      handleDeleteFile(props.type);
                    }}
                  >
                    <StyledCancelIcon />
                  </DeleteIcon>
                </UploadedFile>
              )}
            </FileWrapper>
          )}
        </ItemWrapper>

        {errors.url && errors.url.length > 0 && !featureItems.url && (
          <HelperText>{errors.url}</HelperText>
        )}

        <StyledAddFeaturedButton
          disabled={isloading}
          onBlur={() =>
            setErrors({
              name: "",
              url: "",
            })
          }
          onClick={() => {
            setFeatureItems({
              name: "",
              background: "",
              url: "",
              color: "",
              isEnabled: true,
            });
            setIsUpdate(false);

            setShowField((prev) => !prev);
          }}
        >
          {showField ? "cancel" : "+ add featured item"}
        </StyledAddFeaturedButton>
        {/* <ButtonsContainer>
          <StyledCancelButton onClick={() => setOpen(true)}>
            Cancel
          </StyledCancelButton>
          {open && <Modal open={open} handleClose={handleClose} />}
          <StyledSaveButton type="submit" disabled={isloading}>
            Save
          </StyledSaveButton>
        </ButtonsContainer> */}
        <ButtonsContainer>
          <StyledCancelButton onClick={() => navigate("/register/menu-items")}>
            &lt;&nbsp;Previous
          </StyledCancelButton>

          <StyledSaveButton onClick={() => navigate("/register/promotions")}>
            Next&nbsp;&gt;
          </StyledSaveButton>
        </ButtonsContainer>
      </StyledForm>
      {isLoadingPage && <LoadingModal />}
    </Container>
  );
}

export default Form;
