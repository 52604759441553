/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useEffect } from "react";
import { QRCode } from "react-qrcode-logo";
import { useNavigate } from "react-router-dom";
import domtoimage from "dom-to-image";
import checkIcon from "../../../../../assets/check_icon.svg";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../../../../firebase";

import {
  FormContainer,
  TextBlock,
  StyledHeader,
  QRContainer,
  RestaurantName,
  QRBodyContainer,
  QRFooter,
  ButtonContainer,
  StyledPrintButton,
  // StyledGoToButton,
  ButtonsContainer,
  StyledCancelButton,
  StyledSaveButton,
} from "./styles";

function Form() {
  const navigate = useNavigate();
  const [url, setUrl] = useState(null);
  const [restaurantName, setRestaurantName] = useState(null);

  useEffect(() => {
    fetchRestaurantLogoURL();
  }, []);

  const fetchRestaurantLogoURL = async () => {
    const id = localStorage.getItem("id");
    const docRef = doc(db, "restaurants", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const name = docSnap.data().name;
      setRestaurantName(name);
    }
  };

  const handleDownload = () => {
    domtoimage
      .toJpeg(document.getElementById("qrcodewrapper"))
      .then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = "qr_code.jpeg";
        link.href = dataUrl;
        link.click();
      });
  };

  const firebaseUrl = async () => {
    let baseURL = "https://web.globalmenu.app";

    if (process.env.REACT_APP_ENVIRONMENT === "development") {
      baseURL = "https://web-dev.globalmenu.app";
    } else if (process.env.REACT_APP_ENVIRONMENT === "demo") {
      baseURL = "https://web-demo.globalmenu.app";
    }

    let androidPackageName = "app.globalmenu";
    if (process.env.REACT_APP_ENVIRONMENT === "development") {
      androidPackageName += ".dev";
    } else if (process.env.REACT_APP_ENVIRONMENT === "demo") {
      androidPackageName += ".demo";
    }

    const link = `${baseURL}/restaurant/${localStorage.getItem("uid")}`;

    const body = {
      dynamicLinkInfo: {
        domainUriPrefix: baseURL + "/share",
        link,
        androidInfo: { androidPackageName, androidFallbackLink: link },
      },
    };

    const res = await fetch(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.REACT_APP_APIKEY}`,
      {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );

    const data = await res.json();

    const docRef = doc(db, "restaurants", localStorage.getItem("id"));
    await setDoc(docRef, { dynamicShareURL: data.shortLink }, { merge: true });

    setUrl(data.shortLink);
  };

  // useEffect(() => {
  //   const getData = async () => {
  //     const docRef = doc(
  //       db,
  //       "accounts",
  //       JSON.parse(localStorage.getItem("user")).uid
  //     );

  //     const docSnap = await getDoc(docRef);
  //     if (docSnap.data().role === "admin") {
  //       const frankDocRef = doc(db, "accounts", docSnap.id);
  //       await updateDoc(frankDocRef, {
  //         uid: localStorage.getItem("uid"),
  //       });
  //     }
  //   };

  //   getData();
  // }, []);

  // const dashboardHandler = async () => {
  //   // const washingtonRef = doc(db, "restaurants", localStorage.getItem("id"));
  //   // const cityRef = doc(db, "restaurants", localStorage.getItem("id"));
  //   // setDoc(cityRef, { qrcodeurl: imageUrl }, { merge: true });
  //   const getData = async () => {
  //     const docRef = doc(db, "users", localStorage.getItem("uid"));
  //     const docSnap = await getDoc(docRef);

  //     if (docSnap.exists() && docSnap?.data().role === "admin") {
  //       navigate("/register/home");
  //     } else {
  //       navigate("/");
  //     }
  //   };
  //   getData();
  // };

  useEffect(() => {
    if (localStorage.getItem("uid")) {
      localStorage.setItem("id", localStorage.getItem("uid"));
      const getData = async () => {
        const docRef = doc(db, "restaurants", localStorage.getItem("id"));
        const docSnap = await getDoc(docRef);
        if (docSnap.exists() && docSnap.data().dynamicShareURL) {
          setUrl(docSnap.data().dynamicShareURL);
        } else {
          firebaseUrl();
        }
      };
      getData();
    }
  }, []);

  return (
    <FormContainer>
      <StyledHeader>QR Code</StyledHeader>

      <div>
        <TextBlock>
          <img src={checkIcon} alt="Check Icon" />
          This item can be placed on table where customer can scan and get
          navigated to your restaurant homepage on the menu app
        </TextBlock>
        <TextBlock>
          <img src={checkIcon} alt="Check Icon" />
          You can print multiple copies of this QR code
        </TextBlock>
      </div>

      <div>
        <QRContainer id="qrcodewrapper">
          <RestaurantName>{restaurantName ?? "SCAN ME"}</RestaurantName>
          <QRBodyContainer>
            {url && (
              <QRCode
                enableCORS
                value={url}
                // logoImage={restaurantLogoURL}
                quietZone={1}
                size={250}
                logoHeight={75}
                logoWidth={75}
              />
            )}
          </QRBodyContainer>
          <QRFooter>Powered by Menu Technologies</QRFooter>
        </QRContainer>
      </div>

      <ButtonContainer>
        <StyledPrintButton onClick={handleDownload}>Print</StyledPrintButton>
      </ButtonContainer>
      {/* <StyledGoToButton variant="outlined" onClick={dashboardHandler}>
        Go to DashBoard
      </StyledGoToButton> */}
      <ButtonsContainer>
        <StyledCancelButton onClick={() => navigate("/register/promotions")}>
          &lt;&nbsp;Previous
        </StyledCancelButton>

        <StyledSaveButton onClick={() => navigate("/register/review")}>
          Next&nbsp;&gt;
        </StyledSaveButton>
      </ButtonsContainer>
    </FormContainer>
  );
}

export default Form;
