import React from "react";

import { StyledLink } from "./styles";

function SidebarChip(props) {
  const {
    list: { id, name, navigateTo },
    selectedChip,
    handleChipClick,
  } = props;

  return (
    <StyledLink
      to={navigateTo}
      isActive={selectedChip === id}
      onClick={() => handleChipClick(id.split("_").join("-"))}
    >
      {name}
    </StyledLink>
  );
}

export default SidebarChip;
