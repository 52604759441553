import styled from "styled-components/macro";
import { Button, TextField, Checkbox, Typography } from "@material-ui/core";
import { HighlightOff, CheckCircleOutline } from "@material-ui/icons";

const Container = styled.div`
  padding: 40px;
`;
const StyledHeader = styled(Typography).attrs(() => ({
  component: "h1",
  variant: "h5",
}))`
  && {
    text-align: center;
    font-weight: 600;
    margin-bottom: 10px;
  }
`;

const CategoryWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Category = styled.div`
  margin-left: 10 px;
`;

const StyledButton = styled(Button)`
  && {
    color: #ef403b;
    font-size: 12px;
    font-weight: 600;
    &:hover {
      background: none;
    }
  }
`;

const StyledAddSubcategoryButton = styled(StyledButton)`
  && {
    margin-left: 40px;
  }
`;

const TextFieldCardContainer = styled.div`
  margin: 10px 0;
  display: flex;
  align-items: center;
  margin-left: ${({ id }) => (id ? "50px" : 0)};
`;

const StyledTextField = styled(TextField).attrs(() => ({
  variant: "outlined",
}))`
  width: 100%;
  flex: 1;
`;
const IconsContainer = styled.div`
  margin-left: 20px;
  width: 70px;
  display: flex;
  justify-content: space-between;
`;
const SubCategories = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: 40px;
`;
const SubcategoriesWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 40%;
  margin-right: 2%;
`;

const SubcategoryName = styled.div`
  font-size: 12px;
`;

const ButtonContainer = styled.div`
  // width: 50%;
  // margin: 30px auto 20px auto;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  margin-bottom: 0;
`;

const EditButton = styled.div`
  color: red;
  margin-left: 1rem;
  cursor: pointer;
  font-size: 14px;
`;

const DeleteButton = styled.div`
  color: red;
  margin-left: 1rem;
  cursor: pointer;
  font-size: 14px;
`;

const StyledFieldValue = styled(TextField)`
  &&& {
    width: 100%;
    flex: 1;
    border: 1px solid red;
  }
`;

const StyledHighlightOff = styled(HighlightOff)`
  &&& {
    color: #dd1d1d;
    cursor: pointer;
  }
`;

const StyledCheckCircleOutline = styled(CheckCircleOutline)`
  &&& {
    color: #1bb55c;
    cursor: pointer;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  &&& {
    color: black;
  }
`;

const StyledCancelButton = styled(Button).attrs(() => ({
  variant: "contained",
}))`
  &&& {
    background-color: white;
    color: #ef403b;
    border: 1px solid #ef403b;
    //width: 142px;
    padding: 5px 30px !important;
    border-radius: 8px !important;
    font-weight: bold !important;
    margin: 10px !important;
  }
`;

const StyledSaveButton = styled(Button).attrs(() => ({ variant: "contained" }))`
  &&& {
    background-color: #ef403b;
    color: white;
    //width: 142px;
    padding: 5px 30px !important;
    border-radius: 8px !important;
    font-weight: bold !important;
    margin: 10px !important;
  }
`;

const CategoryTips = styled.div`
  color: red;
  margin-left: 1rem;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
`;

export {
  Container,
  StyledHeader,
  CategoryWrapper,
  Category,
  StyledButton,
  TextFieldCardContainer,
  StyledTextField,
  IconsContainer,
  SubCategories,
  SubcategoriesWrapper,
  SubcategoryName,
  ButtonContainer,
  EditButton,
  DeleteButton,
  StyledAddSubcategoryButton,
  StyledFieldValue,
  StyledCheckCircleOutline,
  StyledHighlightOff,
  StyledCheckbox,
  StyledCancelButton,
  StyledSaveButton,
  CategoryTips,
};
