import styled from "styled-components/macro";
import { TextField, Typography } from "@material-ui/core";
import backG from "../../assets/backG.png";
const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  min-height: calc(100vh - 324px);

  /* Media query for phones */
  @media only screen and (max-width: 1300px) {
    // height: 55%;
    width: 90vw;
    // margin-top: 1vw;
  }

  /* Media query for desktops and larger screens */
  @media only screen and (min-width: 1300px) {
    height: 80%;
    width: 70vw;
    // margin-top: 1.5vw;
  }
`;

const Form = styled.form`
  background-color: white;
  width: 50%;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  flex-basis: 50%;
  @media only screen and (max-width: 767px) {
    width: 90%;
    flex-basis: 90%;
  }
`;

const ImageForm = styled.form`
  background-color: white;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 0;
  flex-basis: 50%;
  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
`;

const StyledTextField = styled(TextField)`
  && {
    ${"" /* width: 60%; */}
    margin: 0px 50px 0;
    width: calc(100% - 100px);

    @media only screen and (max-width: 767px) {
      margin: 0px 20px 0;
      width: calc(100% - 50px);
    }
  }
`;

const ButtonContainer = styled.div`
  text-align: center;
  display: flex;
  margin: 20px auto;
  justify-content: space-between;
  align-items: center;
  width: 55%;
  @media only screen and (max-width: 767px) {
    width: calc(100% - 60px);
    padding: 30px;
  }
`;

const StyledTypograthy = styled(Typography)`
  && {
    text-align: center;
    font-weight: 600;
  }
`;
const styles = {
  backgroundImage: `url(${backG})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  height: "100%",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export {
  Container,
  Form,
  Wrapper,
  ButtonContainer,
  StyledTextField,
  StyledTypograthy,
  styles,
  ImageForm,
};
