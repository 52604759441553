import React, { useState, useRef, useEffect } from "react";
import Dish from "./dish/Dish";
// import { Timestamp } from "firebase/firestore";
import Hidden from "../../../../../assets/Hidden.png";
import { db } from "../../../../../firebase";

import { doc, updateDoc } from "firebase/firestore";
import {
  MenuBarContainer,
  CategoryName,
  SubcategoryName,
  CategoryView,
  ScrollButtonLeft,
  ScrollButtonRight,
  // SubcategoryContainer,
} from "./styles";
let isScrollableToLeft = false;
let isScrollableToRight = false;
function MenuBar(props) {
  const menuBarRef = useRef(null);
  const dragItem = useRef();

  const dragOverItem = useRef();
  const {
    categories,
    handleSelectedCategory,
    selectedCategory,
    dishes,
    editHandler,
    deleteDishHandler,
    currencyValue,
    subCategory,
  } = props;
  const [UnCategorised, setUnCategorised] = useState(false);
  const [dataArray, setDataArray] = useState([]);
  const [refresh, setRefresh] = useState(false);

  // useEffect(() => {
  //   // if (
  //   //   dataArray?.filter((subDish) => {
  //   //     return !subCategory?.map((x) => x.id).includes(subDish.subcategoryId);
  //   //   })?.length > 0
  //   // ) {
  //   //   setUnCategorised(true);
  //   // } else {
  //   //   setUnCategorised(false);
  //   // }
  //   // menuBarRef.current.scrollLeft = 0;
  // }, [dishes]);
  useEffect(() => {
    var newdish = dishes.sort((a, b) =>
      (a.showOrder ?? 0) > (b.showOrder ?? 0) ? 1 : -1
    );
    // newdish = dishes.sort((a, b) =>
    //   new Timestamp(a.createdAt) > new Timestamp(b.createdAt) ? 1 : -1
    // );
    console.log("refresh Item:::", newdish);
    console.log(dishes);

    setDataArray(newdish);
    // handleScrollToEnd();
    // menuBarRef.current.scrollLeft = 0;
  }, [dishes]);

  const handleScrollLeft = () => {
    setRefresh(!refresh);
    menuBarRef.current.scrollLeft -= 200;
    // menuBarRef.current.scrollLeft = 0;
  };

  const handleScrollRight = () => {
    console.log(
      "Get Value of scroll>>>",
      menuBarRef.current.scrollLeft <
        menuBarRef.current.scrollWidth - menuBarRef.current.clientWidth
    );
    setRefresh(!refresh);
    menuBarRef.current.scrollLeft += 200;
  };

  // const handleScrollToEnd = () => {
  //   setTimeout(() => {
  //     if (
  //       dishes?.filter((subDish) => {
  //         return !subCategory?.map((x) => x.id).includes(subDish.subcategoryId);
  //       })?.length > 0
  //     ) {
  //       console.log(
  //         "Get Response:::",

  //         menuBarRef.current.scrollLeft,
  //         menuBarRef.current.scrollWidth,
  //         menuBarRef.current.clientWidth
  //       );
  //       // const menuBar = menuBarRef.current;
  //       menuBarRef.current.scrollLeft =
  //         menuBarRef.current.scrollWidth - menuBarRef.current.clientWidth;
  //       // menuBar.scrollTo({
  //       //   left: menuBarRef.current.scrollWidth+200,
  //       //   behavior: 'smooth'
  //       // });
  //       isScrollableToLeft =
  //         menuBarRef.current && menuBarRef.current.scrollLeft > 0;
  //       setRefresh(!refresh);
  //       isScrollableToRight =
  //         menuBarRef.current &&
  //         menuBarRef.current.scrollLeft <
  //           menuBarRef.current.scrollWidth - menuBarRef.current.clientWidth + 3;
  //       console.log(
  //         "Get Response:::",
  //         menuBarRef.current.scrollWidth - menuBarRef.current.clientWidth,
  //         isScrollableToRight,
  //         menuBarRef.current.scrollLeft
  //       );
  //       setRefresh(!refresh);
  //     } else {
  //       setUnCategorised(false);
  //     }
  //   }, 500);
  // };

  isScrollableToLeft = menuBarRef.current && menuBarRef.current.scrollLeft > 0;
  isScrollableToRight =
    menuBarRef.current &&
    menuBarRef.current.scrollLeft <
      menuBarRef.current.scrollWidth - menuBarRef.current.clientWidth;

  const drop = (e) => {
    const copyListItems = [...dataArray];
    // console.log("Get Response>>>>", dataArray);
    // console.log("Get Response>>>> dragOverItem", dragOverItem.current);
    // console.log("Get Response>>>> dragItem", dragItem.current);

    const dragItemContent = copyListItems[dragItem.current];
    // console.log("Get Response>>>>1", dragItemContent);
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);

    console.log(
      "Get Response>>>>2",
      dataArray,
      dragOverItem.current + dragItem.current
    );
    // setList(copyListItems);
    copyListItems.map((e, index) => (e.showOrder = index));
    // console.log(dragItemContent.name,dragItemContent.showOrder);
    // console.log(copyListItems[dragItem.current].name,copyListItems[dragItem.current].showOrder);
    // console.log(copyListItems[dragOverItem.current-1].name,copyListItems[dragOverItem.current-1].showOrder);

    // console.log("Get Response>>>>3", copyListItems);
    setRefresh(!refresh);
    setDataArray(copyListItems);
    saveReorder(dragItemContent, copyListItems[dragOverItem.current - 1]);
    dragItem.current = null;
    dragOverItem.current = null;
  };

  const saveReorder = async (dish1, dish2) => {
    dataArray.forEach(async (e) => {
      const docRef1 = doc(
        db,
        `restaurants`,
        `${localStorage.getItem("uid")}`,
        `dishes`,
        `${e.id}`
      );
      await updateDoc(docRef1, {
        ...e,
      });
    });
    // console.log(dish1.id,dish2.id);
    // const docRef1 = doc(
    //   db,
    //   `restaurants`,
    //   `${localStorage.getItem("uid")}`,
    //   `dishes`,
    //   `${dish1.id}`
    // );
    // await updateDoc(docRef1, {
    //   ...dish1
    // })
    // const docRef2 = doc(
    //   db,
    //   `restaurants`,
    //   `${localStorage.getItem("uid")}`,
    //   `dishes`,
    //   `${dish2.id}`
    // );
    // await updateDoc(docRef2, {
    //   ...dish2
    // })
  };

  const dragStart = (e, position, dish) => {
    console.log("Get Details Dish:::", dish);
    console.log("Get Details new:::", dataArray.indexOf(dish));
    console.log("Get Details:::", position);

    dragItem.current = dataArray.indexOf(dish);
    console.log(e.target.innerHTML);
  };

  const dragEnter = (e, position, dish) => {
    console.log("Get Enter Dish:::", dish);
    console.log("Get Details new:::", dataArray.indexOf(dish));
    console.log("Get Enter:::", position);
    dragOverItem.current = dataArray.indexOf(dish);
    console.log(e.target.innerHTML);
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "left" }}>
        {isScrollableToLeft && (
          <ScrollButtonLeft onClick={handleScrollLeft}>{"<"}</ScrollButtonLeft>
        )}
        <MenuBarContainer ref={menuBarRef}>
          {categories.map((category) => (
            <CategoryView>
              <CategoryName
                key={category.id}
                isActive={selectedCategory.id === category.id && !UnCategorised} //&& !UnCategorised
                onClick={() => {
                  handleSelectedCategory(category);
                  setUnCategorised(false);
                }}
              >
                {category.name.length > 9
                  ? `${category.name.substring(0, 9)}...`
                  : category.name}
                {!category.checkCategory ? (
                  <img
                    src={Hidden}
                    alt=""
                    style={{ width: "15px", height: 9, marginLeft: 5 }}
                  />
                ) : null}
              </CategoryName>
            </CategoryView>
          ))}
          {dataArray?.filter((subDish) => {
            return !subCategory
              ?.map((x) => x.id)
              .includes(subDish.subcategoryId);
          })?.length > 0 &&
          dataArray.filter((subDish) => {
            let dishes = null;
            if (!categories?.map((x) => x.id).includes(subDish.categoryId)) {
              if (
                !subCategory?.map((x) => x.id).includes(subDish.subcategoryId)
              ) {
                dishes = subDish;
              } else if (subDish.subcategoryId !== "") {
                dishes = subDish;
              }
            }
            return dishes;
          })?.length > 0 ? (
            <CategoryView>
              <CategoryName
                style={{ cursor: "pointer" }}
                isActive={UnCategorised}
                onClick={() => setUnCategorised(true)}
              >
                Uncategor...
              </CategoryName>
            </CategoryView>
          ) : null}
        </MenuBarContainer>
        {isScrollableToRight && (
          <ScrollButtonRight onClick={handleScrollRight}>
            {">"}
          </ScrollButtonRight>
        )}
      </div>
      {categories.map((category) => (
        <div>
          {selectedCategory.id === category.id &&
            !UnCategorised &&
            dataArray
              .filter(
                (subDish) =>
                  subDish.categoryId === category.id &&
                  !category.subcategories
                    ?.map((x) => x.id)
                    .includes(subDish.subcategoryId)
              )
              //  .sort((a, b) =>
              //               new Timestamp(a.createdAt) > new Timestamp(b.createdAt) ? 1 : -1
              //             )
              .map((dish, index) => (
                <Dish
                  editHandler={editHandler}
                  deleteDishHandler={deleteDishHandler}
                  category={category}
                  index={index}
                  key={dish.dishId}
                  dish={dish}
                  currencyValue={currencyValue}
                  drop={drop}
                  onDragStart={(e) => dragStart(e, index, dish)}
                  onDragEnter={(e) => dragEnter(e, index, dish)}
                />
              ))}

          {selectedCategory.id === category.id &&
            !UnCategorised &&
            category.subcategories.map((subCategory) => {
              return (
                <div key={subCategory.id}>
                  <SubcategoryName>
                    {subCategory.name}{" "}
                    {!subCategory.checkSubCategoryChecked ? (
                      <img
                        src={Hidden}
                        alt=""
                        style={{ width: 16, height: 9, marginLeft: 10 }}
                      />
                    ) : null}
                  </SubcategoryName>
                  {dataArray
                    .filter(
                      (subDish) =>
                        subDish.categoryId === category.id &&
                        subDish.subcategoryId === subCategory.id
                    )
                    // .sort((a, b) =>
                    //   new Timestamp(a.createdAt) > new Timestamp(b.createdAt)
                    //     ? 1
                    //     : -1
                    // )
                    .map((dish, index) => (
                      <Dish
                        editHandler={editHandler}
                        deleteDishHandler={deleteDishHandler}
                        category={category}
                        key={dish.dishId}
                        index={index}
                        dish={dish}
                        currencyValue={currencyValue}
                        drop={drop}
                        onDragStart={(e) => dragStart(e, index, dish)}
                        onDragEnter={(e) => dragEnter(e, index, dish)}
                      />
                    ))}
                </div>
              );
            })}
        </div>
      ))}
      {dataArray?.filter((subDish) => {
        return !categories?.map((x) => x.id).includes(subDish.categoryId);
      })?.length > 0 ? (
        <div>
          {UnCategorised && (
            <div>
              {dataArray
                .filter((subDish) => {
                  let dishes = null;
                  if (
                    !categories?.map((x) => x.id).includes(subDish.categoryId)
                  ) {
                    if (
                      !subCategory
                        ?.map((x) => x.id)
                        .includes(subDish.subcategoryId)
                    ) {
                      dishes = subDish;
                    } else if (subDish.subcategoryId !== "") {
                      dishes = subDish;
                    }
                  }
                  return dishes;
                })
                // .sort((a, b) =>
                //   new Timestamp(a.createdAt) > new Timestamp(b.createdAt)
                //     ? 1
                //     : -1
                // )
                .map((dish, index) => (
                  <Dish
                    editHandler={editHandler}
                    deleteDishHandler={deleteDishHandler}
                    category={""}
                    key={dish.dishId}
                    index={index}
                    dish={dish}
                    currencyValue={currencyValue}
                    drop={drop}
                    onDragStart={(e) => dragStart(e, index, dish)}
                    onDragEnter={(e) => dragEnter(e, index, dish)}
                  />
                ))}
            </div>
          )}
        </div>
      ) : // </SubcategoryContainer>
      null}
    </>
  );
}

export default MenuBar;
