import React from "react";
import CategoryMenu from "../../../../../../assets/category-menu.png";
import Hidden from "../../../../../../assets/Hidden.png";
import Edit from "../../../../../../assets/Edit.png";
import {
  DishContainer,
  IconsContainer,
  DishName,
  Actions,
  EditAction,
  Price,
} from "./styles";

function Dish(props) {
  const {
    dish,
    editHandler,
    category,
    currencyValue,
    index,
    drop,
    onDragStart,
    onDragEnter,
  } = props;
  const makeTwoDecimal = (val) => {
    let decimalValue = Math.round((val / 100) * 100) / 100;
    return decimalValue.toString();
  };
  return (
    <DishContainer
      draggable
      key={index}
      onDragStart={onDragStart}
      onDragEnter={onDragEnter}
      onDragEnd={drop}
    >
      <IconsContainer>
        <img src={CategoryMenu} alt="" style={{ width: 14, height: 24 }} />
        {/* {dish.isDisabled ? <ClearIcon /> : <CheckIcon />} */}
      </IconsContainer>
      <DishName>
        {dish.name}
        {/* {dish.showOrder} */}
        {dish.isDisabled ? (
          <img
            src={Hidden}
            alt=""
            style={{ width: 16, height: 9, marginLeft: 10 }}
          />
        ) : null}
      </DishName>

      <Actions>
        {dish.multiplePrice ? (
          dish.price.map((item, index) => {
            if (item.check === "true") {
              return (
                <Price>
                  {makeTwoDecimal(item.amount) === "0"
                    ? "Unavailable"
                    : Number(item.amount / 100)
                        .toFixed(2)
                        .toString()}{" "}
                  <span>{item.amount === "0" ? "" : currencyValue}</span>
                </Price>
              );
            }
            return "";
          })
        ) : (
          <Price>
            {makeTwoDecimal(dish.price) === "0"
              ? "Unavailable"
              : Number(dish.price / 100)
                  .toFixed(2)
                  .toString()}{" "}
            <span>{dish.price === "0" ? "" : currencyValue}</span>
          </Price>
        )}

        <span>|</span>

        <EditAction
          onClick={() => {
            editHandler(dish, category);
          }}
        >
          <img src={Edit} alt="" style={{ width: 15, height: 15 }} />
        </EditAction>
      </Actions>
    </DishContainer>
  );
}

export default Dish;
