import React from "react";
import Table from "./Table";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Header, AddButtonContainer } from "./styles";

import Footer from "../footer/Footer";

function List() {
  const getEnvironmentText = () => {
    if (process.env.REACT_APP_ENVIRONMENT === "development") {
      return " - Development";
    } else if (process.env.REACT_APP_ENVIRONMENT === "demo") {
      return " - Demo";
    } else {
      return "";
    }
  };

  return (
    <>
      <Header>Restaurant List {getEnvironmentText()}</Header>
      <AddButtonContainer>
        <Link to="/register/account-details">
          <Button variant="contained" color="primary">
            Add New
          </Button>
        </Link>
      </AddButtonContainer>
      <Table />

      <Footer />
    </>
  );
}

export default List;
