import React, { useEffect, useState, useRef, useCallback } from "react";
import SimpleModal from "./Modal";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { db } from "../../../../../firebase";
import {
  serverTimestamp,
  collectionGroup,
  query,
  limit,
  getDocs,
  where,
  startAfter,
  updateDoc,
  doc,
  collection,
  addDoc,
} from "firebase/firestore";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import ModeCommentOutlinedIcon from "@material-ui/icons/ModeCommentOutlined";
import { TextField } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { uniqueID } from "../../../../../utils/randomUniqueIdGenerator";

import avatarProfile from "../../../../../assets/avatar_profile.png";
import { useNavigate } from "react-router-dom";
import {
  StyledHeader,
  FormContainer,
  Hint,
  ButtonContainer,
  StyledAllReviewsButton,
  StyledFlaggedReviesButton,
  ReviewsContainer,
  CommentContainer,
  UserPhoto,
  ReviewUserContainer,
  UserContainer,
  UserNameContainer,
  UserName,
  UserType,
  StarsContainer,
  StyledStarIcon,
  StyledTimestamp,
  Message,
  ImageContainer,
  ReplyContainer,
  TextFieldContainer,
  ReplyButtonContainer,
  StyledCheckmarkIcon,
  StyledCancelIcon,
  CircularProgressContainer,
  DishName,
  ButtonsContainer,
  StyledCancelButton,
  StyledSaveButton,
} from "./styles";

function Form() {
  const navigate = useNavigate();
  const [reviewData, setReviewData] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [lastDoc, setLastDoc] = useState();
  const [value, setValue] = useState(null);
  const [openMenu, setOpenMenu] = useState("");
  const [isOnlyFlggedReview, setIsOnlyFlggedReview] = useState(false);
  const [numberOfFlggedReview, setNumberOfFlggedReview] = useState(0);
  const [comments, setComments] = useState([]);
  const [message, setMessage] = useState("");
  const [openReply, setOpenReply] = useState("");
  const [hideReason, setHideReason] = useState("");

  const observer = useRef();

  const fetchMore = useCallback(() => {
    setLoading(true);
    const data = [];
    const getData = async () => {
      const museums = query(
        collectionGroup(db, "reviews"),
        where("restaurantId", "==", localStorage.getItem("uid")),
        startAfter(lastDoc),
        limit(4)
      );
      const querySnapshot = await getDocs(museums);
      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, reviewData: doc.data() });
      });
      let lastDocs = querySnapshot.docs[querySnapshot.docs.length - 1];

      setReviewData((prev) => [...prev, ...data]);
      setLoading(false);
      setLastDoc(lastDocs);
      // fetchMoreCommets();
      setHasMore(querySnapshot.docs.length >= 1 ? true : false);
    };
    getData();
  }, [reviewData]);

  const lastElement = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          fetchMore();
        }
      });
      if (reviewData) {
      }
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, reviewData, fetchMore]
  );

  const handleClick = (e, data) => {
    setAnchorEl(e.currentTarget);
    setOpenMenu(data.id);
    setValue(data);
  };

  const handleHideReview = async (val) => {
    if (val.reviewData.reviewType === "dish") {
      setLoading(true);
      const updateDishReview = doc(
        db,
        "restaurants",
        localStorage.getItem("id"),
        "dishes",
        val.reviewData.dishId,
        "reviews",
        val.id
      );
      await updateDoc(updateDishReview, {
        flag: null,
      }).then(() => {
        setLoading(true);
        const data = [];
        const getData = async () => {
          const museums = query(
            collectionGroup(db, "reviews"),
            where("restaurantId", "==", localStorage.getItem("uid")),
            limit(4)
          );
          const querySnapshot = await getDocs(museums);
          querySnapshot.forEach((doc) => {
            data.push({ id: doc.id, reviewData: doc.data() });
          });
          let lastDocs = querySnapshot.docs[querySnapshot.docs.length - 1];
          setReviewData(data);
          setLoading(false);
          setLastDoc(lastDocs);
          setHasMore(querySnapshot.docs.length >= 1 ? true : false);
          setOpen(false);
        };
        getData();
      });
    }
    if (val.reviewData.reviewType === "restaurant") {
      setLoading(true);
      const updateDishReview = doc(
        db,
        "restaurants",
        localStorage.getItem("id"),
        "reviews",
        val.id
      );
      await updateDoc(updateDishReview, {
        flag: null,
      }).then(() => {
        setLoading(true);
        const data = [];
        const getData = async () => {
          const museums = query(
            collectionGroup(db, "reviews"),
            where("restaurantId", "==", localStorage.getItem("uid")),
            limit(4)
          );
          const querySnapshot = await getDocs(museums);
          querySnapshot.forEach((doc) => {
            data.push({ id: doc.id, reviewData: doc.data() });
          });
          let lastDocs = querySnapshot.docs[querySnapshot.docs.length - 1];
          setReviewData(data);
          setLoading(false);
          setLastDoc(lastDocs);
          setHasMore(querySnapshot.docs.length >= 1 ? true : false);
          setOpen(false);
        };
        getData();
      });
    }
  };

  const handleUnHideReview = async (val) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (val.reviewData.reviewType === "dish") {
      setLoading(true);
      const updateDishReview = doc(
        db,
        "restaurants",
        localStorage.getItem("id"),
        "dishes",
        val.reviewData.dishId,
        "reviews",
        val.id
      );

      await updateDoc(updateDishReview, {
        flag: {
          title: hideReason ? hideReason : "Off Topic",
          subtitle: "",
          flaggerEmail: user.email,
          flaggerName: user.displayName || "",
          flaggerPhotoURL: user.photoURL || "",
          flaggerID: uniqueID(),
        },
      }).then(() => {
        setLoading(true);
        const data = [];
        const getData = async () => {
          const museums = query(
            collectionGroup(db, "reviews"),
            where("restaurantId", "==", localStorage.getItem("uid")),
            limit(4)
          );
          const querySnapshot = await getDocs(museums);
          querySnapshot.forEach((doc) => {
            data.push({ id: doc.id, reviewData: doc.data() });
          });
          let lastDocs = querySnapshot.docs[querySnapshot.docs.length - 1];
          setReviewData(data);
          setLoading(false);
          setLastDoc(lastDocs);
          setHasMore(querySnapshot.docs.length >= 1 ? true : false);
          setOpen(false);
        };
        getData();
      });
    }
    if (val.reviewData.reviewType === "restaurant") {
      setLoading(true);
      const updateDishReview = doc(
        db,
        "restaurants",
        localStorage.getItem("id"),
        "reviews",
        val.id
      );
      await updateDoc(updateDishReview, {
        flag: {
          title: hideReason ? hideReason : "Off Topic",
          subtitle: "",
          flaggerEmail: user.email,
          flaggerName: user.displayName || "",
          flaggerPhotoURL: user.photoURL || "",
          flaggerID: uniqueID(),
        },
      }).then(() => {
        setLoading(true);
        const data = [];
        const getData = async () => {
          const museums = query(
            collectionGroup(db, "reviews"),
            where("restaurantId", "==", localStorage.getItem("uid")),
            limit(4)
          );
          const querySnapshot = await getDocs(museums);
          querySnapshot.forEach((doc) => {
            data.push({ id: doc.id, reviewData: doc.data() });
          });
          let lastDocs = querySnapshot.docs[querySnapshot.docs.length - 1];
          setReviewData(data);
          setLoading(false);
          setLastDoc(lastDocs);
          setHasMore(querySnapshot.docs.length >= 1 ? true : false);
          setOpen(false);
        };
        getData();
      });
    }
  };

  const handleClose = () => {
    setOpen(true);
    setAnchorEl(null);
  };

  const handleAddCommnet = async (val) => {
    let user = JSON.parse(localStorage.getItem("user"));

    if (val.reviewData.reviewType === "restaurant") {
      await addDoc(
        collection(
          db,
          "restaurants",
          localStorage.getItem("id"),
          "reviews",
          val.id,
          "comments"
        ),
        {
          userID: user.uid,
          reviewerName: user.displayName || "",
          message: message,
          restaurantID: val.reviewData.restaurantId,
          dishID: "",
          reviewID: val.id,
          userPhotoURL: user.photoURL || "",
          createdAt: serverTimestamp(),
        }
      );
      setMessage("");

      const data = [];
      const getData = async () => {
        const museums = query(
          collectionGroup(db, "comments"),
          where("restaurantID", "==", localStorage.getItem("uid"))
          // limit(4)
        );
        const querySnapshot = await getDocs(museums);
        querySnapshot.forEach((doc) => {
          data.push({ id: doc.id, comment: doc.data() });
        });

        setComments(data);
        reSetReview();
      };
      getData();
    }

    if (val.reviewData.reviewType === "dish") {
      await addDoc(
        collection(
          db,
          "restaurants",
          localStorage.getItem("id"),
          "dishes",
          val.reviewData.dishId,
          "reviews",
          val.id,
          "comments"
        ),
        {
          userID: user.uid,
          reviewerName: user.displayName || "",
          message: message,
          restaurantID: val.reviewData.restaurantId,
          dishID: val.reviewData.dishId,
          reviewID: val.id,
          userPhotoURL: user.photoURL || "",
          createdAt: user.createdAt,
        }
      );
      setMessage("");

      const data = [];
      const getData = async () => {
        const museums = query(
          collectionGroup(db, "comments"),
          where("restaurantID", "==", localStorage.getItem("uid"))
          // limit(4)
        );
        const querySnapshot = await getDocs(museums);
        querySnapshot.forEach((doc) => {
          data.push({ id: doc.id, comment: doc.data() });
        });

        setComments(data);
        reSetReview();
      };
      getData();
    }
    setOpenReply("");
  };

  const reSetReview = () => {
    setLoading(true);

    const data = [];
    const getData = async () => {
      const museums = query(
        collectionGroup(db, "reviews"),
        where("restaurantId", "==", localStorage.getItem("uid")),
        limit(4)
      );
      const querySnapshot = await getDocs(museums);
      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, reviewData: doc.data() });
      });
      let lastDocs = querySnapshot.docs[querySnapshot.docs.length - 1];

      setReviewData(data);
      setLoading(false);
      setLastDoc(lastDocs);
      setHasMore(querySnapshot.docs.length >= 1 ? true : false);
    };
    getData();
  };

  const handleOpenReply = (val) => {
    setOpenReply(val);
  };

  useEffect(() => {
    const data = [];
    const getData = async () => {
      const museums = query(
        collectionGroup(db, "reviews"),
        where("restaurantId", "==", localStorage.getItem("uid"))
      );
      const querySnapshot = await getDocs(museums);
      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, reviewData: doc.data() });
      });
      setNumberOfFlggedReview(data.filter((u) => u.reviewData.flag).length);
    };
    getData();
  }, [reviewData]);

  useEffect(() => {
    if (isOnlyFlggedReview) {
      setReviewData(reviewData.filter((u) => u.reviewData.flag));
    }
  }, [isOnlyFlggedReview, lastDoc]);

  useEffect(() => {
    if (localStorage.getItem("uid")) {
      localStorage.setItem("id", localStorage.getItem("uid"));
      setLoading(true);
      const data = [];
      const getData = async () => {
        const museums = query(
          collectionGroup(db, "reviews"),
          where("restaurantId", "==", localStorage.getItem("uid")),
          limit(4)
        );
        const querySnapshot = await getDocs(museums);
        querySnapshot.forEach((doc) => {
          data.push({ id: doc.id, reviewData: doc.data() });
        });
        let lastDocs = querySnapshot.docs[querySnapshot.docs.length - 1];

        setReviewData(data);

        setLoading(false);
        setLastDoc(lastDocs);
        setHasMore(querySnapshot.docs.length >= 1 ? true : false);
      };
      getData();
    }
  }, []);
  useEffect(() => {
    const data = [];
    const getData = async () => {
      const museums = query(
        collectionGroup(db, "comments"),
        where("restaurantID", "==", localStorage.getItem("uid"))
        // limit(4)
      );
      const querySnapshot = await getDocs(museums);
      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, comment: doc.data() });
      });

      setComments(data);
    };
    getData();
  }, []);
  return (
    <FormContainer>
      <StyledHeader>Review</StyledHeader>
      {numberOfFlggedReview > 0 && (
        <Hint>
          <p>You have {numberOfFlggedReview} flagged reviews</p>
        </Hint>
      )}
      <ButtonContainer>
        <StyledAllReviewsButton
          style={{ marginRight: "10px" }}
          isOnlyFlagged={isOnlyFlggedReview}
          onClick={() => {
            reSetReview();
            setIsOnlyFlggedReview(false);
          }}
        >
          All review
        </StyledAllReviewsButton>
        <StyledFlaggedReviesButton
          isOnlyFlagged={isOnlyFlggedReview}
          onClick={() => setIsOnlyFlggedReview(true)}
        >
          Flagged review
        </StyledFlaggedReviesButton>
      </ButtonContainer>
      {reviewData.length > 0 &&
        reviewData?.map((val, index) => {
          console.log("Get All Details review>>>>", val.reviewData);
          if (reviewData.length === index + 1) {
            return (
              <ReviewsContainer key={val.id} ref={lastElement}>
                {Boolean(val.reviewData.flag) && (
                  <CommentContainer>
                    <span>
                      <strong>
                        Flagged Comment ({val.reviewData.flag.title})
                      </strong>
                    </span>
                    <span>Flagged by: {val.reviewData?.flag?.flaggerName}</span>
                  </CommentContainer>
                )}

                <ReviewUserContainer>
                  <UserContainer>
                    <UserPhoto
                      src={
                        val.reviewData.userPhotoURL !== undefined &&
                        val.reviewData.userPhotoURL !== ""
                          ? val.reviewData.userPhotoURL
                          : avatarProfile
                      }
                      alt="review"
                    />

                    <UserNameContainer>
                      <UserName isFlagged={Boolean(val.reviewData.flag)}>
                        {val.reviewData.reviewerName}
                      </UserName>
                      <UserType>User 1</UserType>
                    </UserNameContainer>
                  </UserContainer>
                  <div>
                    <IconButton
                      aria-label="delete"
                      onClick={(e) => handleClick(e, val)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    {openMenu === val.id && (
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        <MenuItem onClick={() => handleClose(val)}>
                          {Boolean(!val.reviewData.flag)
                            ? "Hide Comment"
                            : "UnHide Comment"}
                        </MenuItem>
                      </Menu>
                    )}
                  </div>
                </ReviewUserContainer>

                <StarsContainer>
                  {new Array(val.reviewData.amountOfStars)
                    .fill(val.reviewData.amountOfStars)
                    .map(() => (
                      <StyledStarIcon style={{ color: "red" }} />
                    ))}
                  <StyledTimestamp
                    relative
                    date={
                      new Date(
                        val.reviewData.createdAt.seconds * 1000 +
                          val.reviewData.createdAt.nanoseconds / 1000000
                      )
                    }
                    autoUpdate
                  />
                </StarsContainer>
                <DishName>{val.reviewData.dishOrRestaurantLabel}</DishName>
                <div>
                  <Message isFlagged={Boolean(val.reviewData.flag)}>
                    {val.reviewData.message}
                  </Message>
                </div>

                <div>
                  {comments
                    ?.filter((data) => data?.comment?.reviewID === val?.id)
                    .map((msg) => (
                      <Message isFlagged={false}>
                        <span>
                          <strong>(Admin): </strong>
                        </span>
                        {msg.comment?.message}
                      </Message>
                    ))}
                </div>
                <ImageContainer>
                  {val.reviewData.images.map((pic) => {
                    return (
                      <div key={pic}>
                        <img src={pic} alt="dish" />
                      </div>
                    );
                  })}
                </ImageContainer>
                {!(openReply === val.id) && (
                  <ReplyContainer isReplyOpen={openReply === val.id}>
                    <IconButton onClick={() => handleOpenReply(val.id)}>
                      <ModeCommentOutlinedIcon />
                    </IconButton>
                    <span>Reply</span>
                  </ReplyContainer>
                )}
                {openReply === val.id && (
                  <TextFieldContainer>
                    <TextField
                      fullWidth
                      label="Enter Comment"
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}
                      // error={error && !value}
                      // helperText={!value && error && "Input Required"}
                      variant="outlined"
                    />
                    <ReplyButtonContainer>
                      <StyledCheckmarkIcon
                        onClick={() => handleAddCommnet(val)}
                      />
                      <StyledCancelIcon onClick={() => setOpenReply("")} />
                    </ReplyButtonContainer>
                  </TextFieldContainer>
                )}
              </ReviewsContainer>
            );
          }
          return (
            <ReviewsContainer key={val.id}>
              {Boolean(val.reviewData.flag) && (
                <CommentContainer>
                  <span>
                    <strong>
                      Flagged Comment ({val.reviewData.flag.title})
                    </strong>
                  </span>
                  <span>Flagged by: {val.reviewData?.flag?.flaggerName}</span>
                </CommentContainer>
              )}
              <ReviewUserContainer>
                <div>
                  <UserContainer>
                    <div>
                      <UserPhoto
                        src={
                          val.reviewData.userPhotoURL !== undefined &&
                          val.reviewData.userPhotoURL !== ""
                            ? val.reviewData.userPhotoURL
                            : avatarProfile
                        }
                        alt="review"
                      />
                    </div>
                    <UserNameContainer>
                      <UserName>{val.reviewData.reviewerName}</UserName>
                      <UserType>User</UserType>
                    </UserNameContainer>
                  </UserContainer>
                </div>

                <div>
                  <IconButton
                    aria-label="delete"
                    onClick={(e) => handleClick(e, val)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  {openMenu === val.id && (
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={() => handleClose(val)}>
                        {Boolean(!val.reviewData.flag)
                          ? "Hide Comment"
                          : "UnHide Comment"}
                      </MenuItem>
                    </Menu>
                  )}
                </div>
              </ReviewUserContainer>

              <div>
                <StarsContainer>
                  {new Array(val.reviewData.amountOfStars)
                    .fill(val.reviewData.amountOfStars)
                    .map((x) => (
                      <span>
                        <StyledStarIcon />
                      </span>
                    ))}
                  <StyledTimestamp
                    relative
                    date={
                      new Date(
                        val.reviewData.createdAt.seconds * 1000 +
                          val.reviewData.createdAt.nanoseconds / 1000000
                      )
                    }
                    autoUpdate
                  />
                </StarsContainer>
              </div>
              <DishName>{val.reviewData.dishOrRestaurantLabel}</DishName>
              <div>
                <Message>{val.reviewData.message}</Message>
              </div>
              <div>
                {comments
                  ?.filter((data) => data?.comment?.reviewID === val?.id)
                  .map((msg) => (
                    <Message>
                      <span>
                        <strong>(Admin): </strong>
                      </span>

                      {msg.comment?.message}
                    </Message>
                  ))}
              </div>
              <ImageContainer>
                {val.reviewData.images.map((pic) => {
                  return (
                    <div key={pic}>
                      <UserPhoto src={pic} alt="dish" />
                    </div>
                  );
                })}
              </ImageContainer>

              {!(openReply === val.id) && (
                <ReplyContainer isReplyOpen={openReply === val.id}>
                  <IconButton onClick={() => handleOpenReply(val.id)}>
                    <ModeCommentOutlinedIcon />
                  </IconButton>
                  <span>Reply</span>
                </ReplyContainer>
              )}
              {openReply === val.id && (
                <TextFieldContainer>
                  <TextField
                    fullWidth
                    label="Enter Comment"
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                    // error={error && !value}
                    // helperText={!value && error && "Input Required"}
                    variant="outlined"
                    // className={classes.value}
                  />
                  <ReplyButtonContainer>
                    <StyledCheckmarkIcon
                      onClick={() => handleAddCommnet(val)}
                    />
                    <StyledCancelIcon onClick={() => setOpenReply("")} />
                  </ReplyButtonContainer>
                </TextFieldContainer>
              )}
            </ReviewsContainer>
          );
        })}
      {loading && (
        <CircularProgressContainer>
          <CircularProgress />
        </CircularProgressContainer>
      )}
      {open && (
        <SimpleModal
          open={open}
          setOpen={setOpen}
          loading={loading}
          setLoading={setLoading}
          val={value}
          handleHideReview={handleHideReview}
          handleUnHideReview={handleUnHideReview}
          setHideReason={setHideReason}
        />
      )}

      <ButtonsContainer>
        <StyledCancelButton onClick={() => navigate("/register/qr-code")}>
          &lt;&nbsp;Previous
        </StyledCancelButton>

        <StyledSaveButton onClick={() => navigate("/register/setting")}>
          Next&nbsp;&gt;
        </StyledSaveButton>
      </ButtonsContainer>
    </FormContainer>
  );
}

export default Form;
