import React, { useState, useRef, useEffect } from "react";
import done from "../../../../../assets/done.svg";
import Modal from "../../cancelModal/CancelModal";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
  Button,
  FormControl,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { makeAPiCall } from "../../../../../services/api";
import { useNavigate } from "react-router-dom";
import { db, storage } from "../../../../../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import LoadingModal from "../../../../baseComponents/LoadingModal";

import {
  FormContainer,
  SponsordForm,
  StyledHeader,
  StyledDescription,
  ItemWrapper,
  FileWrapper,
  FileUpload,
  UploadedFile,
  DeleteIconContainer,
  StyledCanceIcon,
  ButtonContainer,
  HelperText,
  StyledCancelButton,
  StyledSaveButton,
  FlexWrapper,
  IconsContainer,
  StyledCheckCircleOutline,
  StyledHighlightOff,
  EditButton,
} from "./styles";
import {
  Delete as DeleteOutlineIcon,
  Edit as EditOutlineIcon,
} from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

function Form(props) {
  const navigate = useNavigate();
  const inputImage = useRef();

  const [promotionItems, setPromotionItems] = useState({
    name: "",
    background: "",
    url: "",
    color: "",
  });
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [isloading, setIsloading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [showField, setShowField] = useState(false);

  const [isLoadingPage, setIsloadingPage] = useState(false);

  const validate = () => {
    const errors = {};
    errors.name =
      promotionItems.name.length >= 0 ? "" : "Description Is Required";
    errors.url =
      promotionItems.url.length >= 0 ? "" : "Background image Is Required";

    errors.name =
      promotionItems?.name.length <= 100
        ? ""
        : "Description over 100 characters";

    setErrors({ ...errors });
    return Object.values(errors).every((error) => error === "");
  };

  useEffect(() => {
    if (localStorage.getItem("uid")) {
      localStorage.setItem("id", localStorage.getItem("uid"));
      const getData = async () => {
        const docRef = doc(db, "restaurants", localStorage.getItem("id"));
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          if (docSnap.data().promotions?.length > 0) {
            setIsUpdate(true);
            setPromotionItems({
              name: docSnap.data().promotions[0].name ?? "",
              background: docSnap.data().promotions[0].background,
              url: docSnap.data().promotions[0].url,
              color: docSnap.data().promotions[0].color,
              // additional: [],
            });
          } else {
            setShowField(true);
          }
        } else {
        }
      };
      getData();
    }
  }, [props.type]);

  const handleSubmit = async () => {
    // e.preventDefault();

    if (validate()) {
      setIsloadingPage(true);

      if (isUpdate) {
        const washingtonRef = doc(
          db,
          "restaurants",
          localStorage.getItem("id")
        );
        await updateDoc(washingtonRef, {
          promotions: [promotionItems],
        }).then(() => {
          setIsloadingPage(false);
          navigate("/register/qr-code");
        });
      } else {
        const data = {
          uid: localStorage.getItem("uid"),
          promotions: [promotionItems],
        };
        makeAPiCall("POST", "add-promotions", data)
          .then((data) => {
            setIsloadingPage(false);
            navigate("/register/qr-code");
          })
          .catch((error) => {
            setIsloadingPage(false);
          });
      }
    }
  };

  const handleChange = (e, type) => {
    setPromotionItems((prev) => {
      return {
        ...prev,
        name: e.target.value,
      };
    });
  };

  const handleFileChange = (e, type) => {
    const { files } = e.target;
    let randomId = Math.floor(Math.random() * 899999 + 100000);
    setIsloading(true);
    const storageRef = ref(storage, `${type}/${randomId}-${files[0].name}`);

    const uploadTask = uploadBytesResumable(storageRef, files[0]);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        // Handle unsuccessful uploads
        setIsloading(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          setPromotionItems((prev) => {
            return {
              ...prev,
              url: downloadURL,
            };
          });
          const washingtonRef = doc(
            db,
            "restaurants",
            localStorage.getItem("id")
          );
          await updateDoc(washingtonRef, {
            promotions: [{ ...promotionItems, url: downloadURL }],
          }).then(() => {
            setIsloadingPage(false);
            // navigate("/register/qr-code");
          });

          setIsloading(false);
        });
      }
    );
  };

  const handleDeleteFile = async (type) => {
    setPromotionItems((prev) => {
      return {
        ...prev,
        url: "",
      };
    });
    const washingtonRef = doc(db, "restaurants", localStorage.getItem("id"));
    console.log(promotionItems);
    await updateDoc(washingtonRef, {
      promotions: [
        {
          ...promotionItems,
          url: "",
        },
      ],
    }).then(() => {
      setIsloadingPage(false);
      // navigate("/register/qr-code");
    });
  };
  const handleClose = () => setOpen(false);

  return (
    <FormContainer>
      <SponsordForm onSubmit={handleSubmit}>
        <StyledHeader>Sponsored Items</StyledHeader>
        <StyledDescription>
          <img src={done} alt="" />
          The items will be displayed on the Homepage and Trending page
        </StyledDescription>

        <ItemWrapper>
          {!showField && (
            <FlexWrapper>
              {promotionItems.name}
              <EditButton
                onClick={() => {
                  setShowField(true);
                }}
              >
                <EditOutlineIcon style={{ color: "red" }} />
              </EditButton>

              {/* <button onClick={() => handleDeleteCategory(data.id)}>Delete</button> */}

              <IconButton onClick={() => setOpen(true)}>
                <DeleteOutlineIcon style={{ color: "red" }} />
              </IconButton>
              {open && (
                <Modal
                  title={"Delete Featured"}
                  open={open}
                  handleDeleteCategory={() => {
                    // DeleteFeatured(index);
                    handleClose();
                  }}
                  handleClose={handleClose}
                  message={"Do you want to delete the featured?"}
                />
              )}
            </FlexWrapper>
          )}
          {showField && (
            <FlexWrapper>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  label="Description"
                  onChange={(e) => handleChange(e, props.type)}
                  name="description"
                  value={promotionItems.name}
                  // error={errors.name}
                  helperText={promotionItems.name && errors.name}
                  variant="outlined"
                />
              </FormControl>
              <IconsContainer>
                <StyledCheckCircleOutline
                  onClick={() => {
                    handleSubmit();
                  }}
                />
                <StyledHighlightOff
                  onClick={() => {
                    // setFeatureItems({
                    //   name: "",
                    //   background: "",
                    //   url: "",
                    //   color: "",
                    //   isEnabled: false,
                    // });
                    // setIsUpdate(false);
                    // setShowField((prev) => false);
                  }}
                />
              </IconsContainer>
            </FlexWrapper>
          )}

          {errors.name && !promotionItems.name && (
            <HelperText>{errors.name}</HelperText>
          )}

          <FileWrapper>
            <FileUpload>
              <input
                type="file"
                ref={inputImage}
                onChange={(e) => handleFileChange(e, props.type)}
                name="backgroundImage"
              />
              <Button
                onClick={() => inputImage.current.click()}
                // disabled={item.backgroundImage}
              >
                + Add Background Image
              </Button>
            </FileUpload>
            {isloading && <CircularProgress />}
            {promotionItems.url && (
              <UploadedFile>
                <img
                  width={35}
                  height={35}
                  src={promotionItems.url}
                  alt="cover"
                />
                <DeleteIconContainer
                  onClick={() => {
                    handleDeleteFile(props.type);
                  }}
                >
                  <StyledCanceIcon />
                </DeleteIconContainer>
              </UploadedFile>
            )}
          </FileWrapper>
          {/* <ButtonContainer>
            <StyledCancelButton onClick={() => setOpen(true)}>
              Cancel
            </StyledCancelButton>
            {open && <Modal open={open} handleClose={handleClose} />}
            <StyledSaveButton type="submit" disabled={isloading}>
              Save
            </StyledSaveButton>
          </ButtonContainer> */}
          <ButtonContainer>
            <StyledCancelButton onClick={() => navigate("/register/featured")}>
              &lt;&nbsp;Previous
            </StyledCancelButton>

            <StyledSaveButton onClick={() => navigate("/register/qr-code")}>
              Next&nbsp;&gt;
            </StyledSaveButton>
          </ButtonContainer>
        </ItemWrapper>
      </SponsordForm>
      {isLoadingPage && <LoadingModal />}
    </FormContainer>
  );
}

export default Form;
