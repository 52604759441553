import React, { useState } from "react";
import { uniqueID } from "../../../../../utils/randomUniqueIdGenerator";

import {
  TextFieldCardContainer,
  StyledTextField,
  IconsContainer,
  StyledCheckCircleOutline,
  StyledHighlightOff,
} from "./styles";

function TextFieldCard(props) {
  const {
    label,
    handleCancelClick,
    data,
    id,
    setData,
    handleSubmit,
    editCategoryValue,
    editCategoryId,
    setEditCategoryId,
    setEditCategoryValue,
    setIsCategorySelected,
  } = props;
  const [value, setValue] = useState(editCategoryValue || "");
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    const { value } = e.target;
    setValue(value);
    if (value) setError(false);
  };

  const handleAddClick = () => {
    if (!value) {
      setError(true);
      return;
    }
    if (editCategoryId && data) {
      setData((prev) => {
        const updatedData = prev.map((category) => {
          if (category.name === data.name) {
            return {
              checkCategory: category.checkCategory,
              id: category.id,
              name: category.name,
              order: category.order,
              subcategories: category.subcategories.map((dataValue) => {
                if (dataValue.id === editCategoryId) {
                  return {
                    checkSubCategoryChecked: dataValue.checkSubCategoryChecked,
                    id: dataValue.id,
                    name: value,
                    order: dataValue.order,
                  };
                }
                return dataValue;
              }),
            };
          } else {
            return category;
          }
        });
        handleSubmit(updatedData);
        return updatedData;
      });
    } else if (editCategoryId) {
      setData((prev) => {
        const updatedData = prev.map((data) => {
          if (data.id === editCategoryId) {
            return {
              checkCategory: data.checkCategory,
              id: data.id,
              name: value,
              order: data.order,
              subcategories: [...data.subcategories],
            };
          }
          return data;
        });
        handleSubmit(updatedData);
        return updatedData;
      });
    } else {
      if (data) {
        if (data.checkCategory) {
          setData((prev) => {
            console.log(prev);
            const updatedData = prev.map((category) => {
              if (category.id === data.id) {
                return {
                  checkCategory: category.checkCategory,
                  id: category.id,
                  name: category.name,
                  order: category.order,
                  subcategories: [
                    ...category.subcategories,
                    {
                      checkSubCategoryChecked: true,
                      id: `sub${uniqueID()}`,
                      name: value,
                      order: category.subcategories.length + 1,
                    },
                  ],
                };
              } else {
                return category;
              }
            });
            handleSubmit(updatedData);
            return updatedData;
          });
        } else {
          setIsCategorySelected(false);
        }
      } else {
        setData((prev) => {
          const updatedData = [
            ...prev,
            {
              checkCategory: true,
              id: uniqueID(),
              name: value,
              order: prev.length++,
              subcategories: [],
            },
          ];
          handleSubmit(updatedData);
          return updatedData;
        });
      }
    }

    handleCancelClick();
  };

  return (
    <TextFieldCardContainer id={id}>
      <StyledTextField
        label={label}
        onChange={handleChange}
        value={value}
        error={error && !value}
        helperText={!value && error && "Input Required"}
      />

      <IconsContainer>
        <StyledCheckCircleOutline
          onClick={() => {
            if (editCategoryId) {
              handleAddClick();

              setEditCategoryId("");
              setEditCategoryValue("");
            } else {
              handleAddClick();
            }
          }}
        />
        <StyledHighlightOff
          onClick={() => {
            if (editCategoryId) {
              setEditCategoryId("");
              setEditCategoryValue("");
              handleCancelClick();
            } else {
              handleCancelClick();
            }
          }}
        />
      </IconsContainer>
    </TextFieldCardContainer>
  );
}

export default TextFieldCard;
