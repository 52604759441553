import { useEffect, useState } from "react";
import { db } from "../../../../../firebase";
import LoadingModal from "../../../../baseComponents/LoadingModal";
import {
  getDocs,
  collection,
  // serverTimestamp,
  // collectionGroup,
  // query,
  // limit,
  // where,
  // startAfter,
  // updateDoc,
  // addDoc,
} from "firebase/firestore";
import {
  SummaryWrapper,
  SummaryTitle,
  TabRow,
  TabItem,
  ReviewRow,
  EarnedCard,
  EarnedTitle,
  EarnedSummary,
  DataSummaryCountWrapper,
  DataSummaryCountItem,
  DataSummaryCount,
  DataSummaryTitle,
} from "./styles";
import batch from "../../../../../assets/batch.png";
import ReviewCard from "./ReatingCard/ReviewRatingCard";
function DataSummary() {
  const [selectedFilter, setSelectedFilter] = useState("1w");
  const [loading, setLoading] = useState(false);
  const [totQRCount, setTotQRCount] = useState(0);
  const [totAppCount, setTotAppCount] = useState(0);
  const [totWebCount, setTotWebCount] = useState(0);

  const [allCountList, setAllCountList] = useState([]);
  const selectedItemStyle = { background: "red", color: "#fff" };
  const nonSelectedItemStyle = { background: "#fff", color: "#000" };

  useEffect(() => {
    const data = [];
    const getData = async () => {
      setLoading(true);
      const counterDocRef = collection(
        db,
        `restaurants`,
        `${localStorage.getItem("uid")}`,
        `counters`
      );
      const counterdocSnap = await getDocs(counterDocRef);
      counterdocSnap.forEach((doc) => {
        var dateStr = doc.id;
        var dateElements = dateStr.split("-");
        dateElements.reverse();
        data.push({
          id: doc.id,
          date: new Date(dateElements.join("-")),
          ...doc.data(),
        });
      });

      setAllCountList(data);
      setTimeout(() => {
        console.log("allCountList");
        console.log(data);
        filterCountByDateRange(data, selectedFilter);
        setLoading(false);
      }, 1000);
    };
    getData();
  }, []);
  useEffect(() => {
    filterCountByDateRange(allCountList, selectedFilter);
  }, [selectedFilter]);

  function isBefore(date1, date2) {
    return date1 < date2;
  }
  function isAfter(date1, date2) {
    return date1 > date2;
  }
  const filterCountByDateRange = (allCountList, sf) => {
    var firstDate = new Date();
    var currentDate = new Date();
    var qrCount = 0;
    var appCount = 0;
    var webCount = 0;
    var day;
    if (sf === "1w") {
      day = firstDate.getTime() - 7 * 24 * 60 * 60 * 1000;
      firstDate.setTime(day);
      console.log("7 day before " + firstDate);
      let filteredData = allCountList.filter(
        (u) => isBefore(u.date, currentDate) && isAfter(u.date, firstDate)
      );
      filteredData.forEach((doc) => {
        qrCount += doc.qrCount;
        appCount += doc.appCount;
        webCount += doc.webCount;
      });
      setTotQRCount(qrCount);
      setTotAppCount(appCount);
      setTotWebCount(webCount);
    } else if (sf === "1m") {
      day = firstDate.getTime() - 30 * 24 * 60 * 60 * 1000;
      firstDate.setTime(day);
      console.log("30 day before " + firstDate);
      let filteredData = allCountList.filter(
        (u) => isBefore(u.date, currentDate) && isAfter(u.date, firstDate)
      );
      filteredData.forEach((doc) => {
        qrCount += doc.qrCount;
        appCount += doc.appCount;
        webCount += doc.webCount;
      });
      setTotQRCount(qrCount);
      setTotAppCount(appCount);
      setTotWebCount(webCount);
    } else if (sf === "1y") {
      day = firstDate.getTime() - 365 * 24 * 60 * 60 * 1000;
      firstDate.setTime(day);
      console.log("365 day before " + firstDate);
      let filteredData = allCountList.filter(
        (u) => isBefore(u.date, currentDate) && isAfter(u.date, firstDate)
      );
      filteredData.forEach((doc) => {
        qrCount += doc.qrCount;
        appCount += doc.appCount;
        webCount += doc.webCount;
      });
      setTotQRCount(qrCount);
      setTotAppCount(appCount);
      setTotWebCount(webCount);
    } else {
      console.log("All day before ");

      allCountList.forEach((doc) => {
        qrCount += doc.qrCount;
        appCount += doc.appCount;
        webCount += doc.webCount;
      });
      setTotQRCount(qrCount);
      setTotAppCount(appCount);
      setTotWebCount(webCount);
    }
  };

  return (
    <SummaryWrapper>
      <SummaryTitle>Data Summary</SummaryTitle>
      <TabRow>
        <TabItem
          style={
            selectedFilter === "1w" ? selectedItemStyle : nonSelectedItemStyle
          }
          onClick={() => {
            setSelectedFilter("1w");
          }}
        >
          1 week
        </TabItem>
        <TabItem
          style={
            selectedFilter === "1m" ? selectedItemStyle : nonSelectedItemStyle
          }
          onClick={() => {
            setSelectedFilter("1m");
          }}
        >
          1 Month
        </TabItem>
        <TabItem
          style={
            selectedFilter === "1y" ? selectedItemStyle : nonSelectedItemStyle
          }
          onClick={() => {
            setSelectedFilter("1y");
          }}
        >
          1 Year
        </TabItem>
        <TabItem
          style={
            selectedFilter === "all" ? selectedItemStyle : nonSelectedItemStyle
          }
          onClick={() => {
            setSelectedFilter("all");
          }}
        >
          All
        </TabItem>
      </TabRow>

      <ReviewRow>
        <ReviewCard selectedFilter={selectedFilter} />
        <EarnedCard>
          <div>
            <EarnedTitle>You have earned it!</EarnedTitle>
            <EarnedSummary>
              Your customers have given you excellent reviews. Keep up the
              momentum with great food and service!
            </EarnedSummary>
          </div>
          <img src={batch} alt="" height="100" />
        </EarnedCard>
      </ReviewRow>

      <DataSummaryCountWrapper>
        <DataSummaryCountItem>
          <DataSummaryCount>{totQRCount}</DataSummaryCount>
          <DataSummaryTitle>QR Code Scans</DataSummaryTitle>
        </DataSummaryCountItem>
        <DataSummaryCountItem>
          <DataSummaryCount>{totWebCount}</DataSummaryCount>
          <DataSummaryTitle>Web App Visit</DataSummaryTitle>
        </DataSummaryCountItem>
        <DataSummaryCountItem>
          <DataSummaryCount>{totAppCount}</DataSummaryCount>
          <DataSummaryTitle>App Visits</DataSummaryTitle>
        </DataSummaryCountItem>
      </DataSummaryCountWrapper>
      {loading && <LoadingModal />}
    </SummaryWrapper>
  );
}

export default DataSummary;
