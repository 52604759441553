import React, { useEffect } from "react";
import Form from "./Form";
import { useOutletContext } from "react-router-dom";

function AccountDetails() {
  const handleChipClick = useOutletContext();

  useEffect(() => {
    handleChipClick("account_details");
  }, [handleChipClick]);

  return <Form />;
}

export default AccountDetails;
