import React from "react";
import { Header as HeaderLayout, HeaderLogo, Button } from "./styles";
import { useSelector, useDispatch } from "react-redux";
import logo from "../../assets/header.svg";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { logout } from "../login/loginSignupSlice";
import {
  // collection,
  // getDocs,
  // updateDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebase";

function Header() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const auth = getAuth();

  const handleLogOut = () => {
    signOut(auth)
      .then(() => {
        dispatch(logout());
        navigate("/login");
        localStorage.removeItem("user");
        localStorage.removeItem("id");
        localStorage.removeItem("uid");
      })
      .catch((error) => {
        // An error happened.
      });
  };

  const getEnvironmentText = () => {
    if (process.env.REACT_APP_ENVIRONMENT === "development") {
      return " - Development";
    } else if (process.env.REACT_APP_ENVIRONMENT === "demo") {
      return " - Demo";
    } else {
      return "";
    }
  };

  const handleRedirectToDashboard = () => {
    const getData = async () => {
      const docRef = doc(
        db,
        "users",
        JSON.parse(localStorage.getItem("user")).uid
      );

      const docSnap = await getDoc(docRef);
      if (docSnap.data()?.role === "admin") {
        navigate("/register/home");
      } else {
        navigate("/");
      }
    };

    getData();
  };

  return (
    <HeaderLayout>
      <HeaderLogo onClick={handleRedirectToDashboard}>
        <img src={logo} alt="logo" height={32} />
        {getEnvironmentText()}
      </HeaderLogo>
      <Button onClick={handleLogOut}>
        {(user.user || localStorage.getItem("user")) && "Log Out"}
      </Button>
    </HeaderLayout>
  );
}

export default Header;
