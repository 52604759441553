import styled from "styled-components/macro";
import { Button } from "@material-ui/core";
import { Typography } from "@material-ui/core";

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px;
`;

const StyledForm = styled.form`
  width: 100%;
`;

const StyledHeader = styled(Typography).attrs(() => ({
  component: "h1",
  variant: "h5",
}))`
  && {
    text-align: center;
    margin-bottom: 20px;
    font-weight: 600;
  }
`;

const ButtonsContainer = styled.div`
  width: 50%;
  margin: 20px auto;
  display: flex;
  justify-content: space-around;
`;

const HelperText = styled.div`
  color: #f44336;
  margin: -3px 0 0 14px;
  font-size: 12px;
`;
const SaveButtonWrapper = styled.div`
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;
`;
const StyledSaveButton = styled(Button).attrs(() => ({
  variant: "contained",
}))`
  //width: 142px;
  background-color: #ef403b !important ;
  color: white !important;
  padding: 5px 30px !important;
  border-radius: 8px !important;
  font-weight: bold !important;
  margin: 10px !important;
`;
const StyledCancelButton = styled(Button).attrs(() => ({
  variant: "contained",
}))`
  //width: 142px;
  background-color: white !important;
  color: #ef403b !important;
  border: 1px solid #ef403b !important;
  padding: 5px 30px !important;
  border-radius: 8px !important;
  font-weight: bold !important;
  margin: 10px !important;
`;
export {
  Container,
  StyledForm,
  StyledHeader,
  ButtonsContainer,
  HelperText,
  StyledSaveButton,
  StyledCancelButton,
  SaveButtonWrapper,
};
