import React, { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  TextField,
  Select,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { doc, getDoc, setDoc } from "firebase/firestore";
import LoadingModal from "../../../../baseComponents/LoadingModal";
import Modal from "../../cancelModal/CancelModal";
import { makeAPiCall } from "../../../../../services/api";
import { useNavigate } from "react-router-dom";
import { auth } from "../../../../../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { db } from "../../../../../firebase";

import {
  Container,
  StyledForm,
  StyledHeader,
  ButtonsContainer,
  HelperText,
  StyledSaveButton,
  StyledCancelButton,
  SaveButtonWrapper,
} from "./styles";

function Form() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    subscriptionPlan: "Gold",
    enabled: "yes",
  });
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsloading] = useState(false);
  const [isDisabledForm, setIsDisabled] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("uid")) {
      localStorage.setItem("id", localStorage.getItem("uid"));
      const getData = async () => {
        const docRef = doc(db, "restaurants", localStorage.getItem("id"));
        const docSnap = await getDoc(docRef);

        if (
          docSnap.exists() &&
          docSnap.data().resturantAccountName &&
          docSnap.data().email &&
          docSnap.data().subscriptionPlan
        ) {
          setIsDisabled(true);
        }
      };
      getData();
    }
  }, []);

  useEffect(() => {
    if (isDisabledForm) {
      const getData = async () => {
        const docRef = doc(db, "restaurants", localStorage.getItem("id"));
        const docSnap = await getDoc(docRef);
        if (
          docSnap.exists() &&
          docSnap.data().resturantAccountName &&
          docSnap.data().email &&
          docSnap.data().subscriptionPlan
        ) {
          setIsDisabled(true);

          setFormData({
            name: docSnap.data().resturantAccountName,
            email: docSnap.data().email,
            password: "********",
            subscriptionPlan: docSnap.data().subscriptionPlan,
            enabled: docSnap.data().status ? "yes" : "no",
          });
        }
      };
      getData();
    }
  }, [isDisabledForm]);

  const validate = () => {
    const errors = {};
    errors.name = formData.name ? "" : "Restaurant Account Name Is Required";
    errors.name = formData.name.length > 25 ? "Max 25 characters" : "";
    errors.email = formData.email
      ? /.+@.+..+/.test(formData.email)
        ? ""
        : "Invalid Email"
      : "Login Email is Required";

    errors.password =
      formData.password.length >= 6
        ? ""
        : "Password should be 6 or more characters";
    errors.subscriptionPlan = formData.subscriptionPlan
      ? ""
      : "Plan Is Required";
    errors.enabled = formData.enabled ? "" : "Input Required";
    setErrors({ ...errors });
    return Object.values(errors).every((error) => error === "");
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      setIsloading((prev) => !prev);
      const status = formData.enabled === "yes" ? "published" : "disabled";

      localStorage.setItem("status", status);

      const docData = {
        resturantAccountName: formData.name,
        email: formData.email,
        subscriptionPlan: formData.subscriptionPlan,
        status,
        enableRestaurant: true,
        enableReviews: true,
      };
      createUserWithEmailAndPassword(auth, formData.email, formData.password)
        .then(async (userCredential) => {
          // Signed up
          const { uid } = userCredential.user;
          localStorage.setItem("uid", uid);

          await setDoc(doc(db, "users", uid), {
            email: formData.email,
            uid: uid,
            role: "admin",
          })
            .then((data) => {
              makeAPiCall("POST", "add-account-details", {
                ...docData,
                restaurantId: uid,
                uid,
              })
                .then((data) => {
                  localStorage.setItem("uid", uid);
                  setIsloading((prev) => !prev);
                  navigate("/register/restaurant-details");
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          if (error.code === "auth/email-already-in-use") {
            alert("Email already in use");
            setIsloading(false);
          } else if (error.code === "auth/weak-password") {
            setIsloading(false);
          } else {
            console.log(error);
            setIsloading(false);
          }

          // ..
        });
    } else {
      alert("Please fill all fields correctly");
    }
  };

  const handleClose = () => setOpen(false);

  return (
    <>
      <Container>
        <StyledForm onSubmit={handleSubmit}>
          <StyledHeader>Account Details</StyledHeader>
          <FormControl margin="normal" required fullWidth>
            <TextField
              label="Restaurant Account Name"
              onChange={handleChange}
              name="name"
              value={formData.name}
              error={errors.name && errors.name.length > 0}
              helperText={errors.name}
              variant="outlined"
              inputProps={{
                autoComplete: "none",
              }}
              disabled={isDisabledForm ? true : false}
            />
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <TextField
              label="Login Email"
              onChange={handleChange}
              name="email"
              value={formData.email}
              error={errors.email && errors.email.length > 0}
              helperText={errors.email}
              variant="outlined"
              autoComplete="nope"
              disabled={isDisabledForm ? true : false}
            />
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <TextField
              label="Password (Min 6 chars)"
              onChange={handleChange}
              name="password"
              type="password"
              value={formData.password}
              error={errors.password && errors.password.length > 0}
              helperText={errors.password}
              variant="outlined"
              inputProps={{
                type: "password",
                autoComplete: "new-password",
              }}
              disabled={isDisabledForm ? true : false}
            />
          </FormControl>
          <FormControl
            disabled={true}
            margin="normal"
            fullWidth
            variant="outlined"
          >
            <InputLabel id="subscription-plan">Subscription Plan</InputLabel>
            <Select
              label="Subscription Plan"
              labelId="subscription-plan"
              name="subscriptionPlan"
              onChange={handleChange}
              value={formData.subscriptionPlan}
              error={
                errors.subscriptionPlan && errors.subscriptionPlan.length > 0
              }
            >
              <MenuItem value="Silver">Silver</MenuItem>
              <MenuItem value="Gold">Gold</MenuItem>
              <MenuItem value="Platinum">Platinum</MenuItem>
              <MenuItem value="Diamond">Diamond</MenuItem>
            </Select>
          </FormControl>
          {errors.subscriptionPlan && errors.subscriptionPlan.length > 0 && (
            <HelperText>{errors.subscriptionPlan}</HelperText>
          )}
          <FormControl
            disabled={isDisabledForm ? true : false}
            margin="normal"
            fullWidth
            variant="outlined"
          >
            <InputLabel id="enabled-in-menu-app">
              Enabled in Menu App
            </InputLabel>
            <Select
              label="Enabled in Menu App"
              id="enabled-in-menu-app"
              name="enabled"
              onChange={handleChange}
              value={formData.enabled}
              error={errors.enabled && errors.enabled.length > 0}
            >
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </Select>
          </FormControl>
          {errors.enabled && errors.enabled.length > 0 && (
            <HelperText>{errors.enabled}</HelperText>
          )}
          {/* <span className="error-msg">{loginErrorMsg}</span><br/><br/> */}
          {!isDisabledForm && (
            <ButtonsContainer>
              <Button
                variant={"contained"}
                style={{
                  backgroundColor: "white",
                  color: "#ef403b",
                  border: "1px solid #ef403b",
                  marginRight: "5px",
                }}
                onClick={() => setOpen(true)}
                disabled={isDisabledForm ? true : false}
              >
                Cancel
              </Button>
              {open && <Modal open={open} handleClose={handleClose} />}
              <Button
                variant={"contained"}
                type="submit"
                style={{ backgroundColor: "#ef403b", color: "white" }}
                disabled={isDisabledForm ? true : false}
              >
                Register
              </Button>
            </ButtonsContainer>
          )}
        </StyledForm>
        {isLoading && <LoadingModal />}
      </Container>

      <SaveButtonWrapper>
        <StyledCancelButton onClick={() => navigate("/register/home")}>
          &lt;&nbsp;Previous
        </StyledCancelButton>
        <StyledSaveButton
          type="Button"
          onClick={() => {
            navigate("/register/restaurant-details");
          }}
        >
          Next&nbsp;&gt;
        </StyledSaveButton>
      </SaveButtonWrapper>
    </>
  );
}

export default Form;
