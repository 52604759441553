const url = process.env.REACT_APP_FIREBASE_FUNCTIONS_URL;

export const makeAPiCall = async (method, path, body) => {
  if (method === `POST`) {
    const res = await fetch(`${url}/${path}`, {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const data = await res.json();
    return data;
  } else {
    const res = await fetch(`${url}/${path}`);
    const data = await res.json();
    return data;
  }
};
