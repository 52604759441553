import React from "react";
import IconButton from "@material-ui/core/IconButton";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {
  CircularProgress,
  // HighlightOff,
  // CheckCircleOutline,
} from "@material-ui/core";

import {
  ModalContentContainer,
  StyledModal,
  IconButtonContainer,
  ModalHeader,
  UnhideButtonContainer,
  StyledFormControlLabel,
  HideButtonContainer,
} from "./styles";

export default function SimpleModal({
  open,
  setOpen,
  val,
  loading,
  setLoading,
  handleHideReview,
  handleUnHideReview,
  setHideReason,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  const [value, setValue] = React.useState("Off Topic");

  const handleChange = (event) => {
    setValue(event.target.value);
    setHideReason(event.target.value);
  };

  const bodyUnHide = loading ? (
    <CircularProgress />
  ) : (
    <ModalContentContainer>
      <IconButtonContainer>
        <IconButton onClick={handleClose}>
          <ClearOutlinedIcon />
        </IconButton>
      </IconButtonContainer>
      <ModalHeader>UnHide Review</ModalHeader>
      <p>Reason for unflagging the review</p>
      <div>
        <TextField
          id="standard-multiline-static"
          label="Enter the reason for unhiding the review"
          multiline
          rows={5}
          variant="outlined"
          fullWidth
        />
      </div>
      <UnhideButtonContainer>
        <Button
          onClick={() => handleHideReview(val)}
          variant="contained"
          color="secondary"
        >
          UnHide Review
        </Button>
      </UnhideButtonContainer>
    </ModalContentContainer>
  );

  const bodyHide = loading ? (
    <CircularProgress />
  ) : (
    <ModalContentContainer>
      <IconButtonContainer>
        <IconButton onClick={handleClose}>
          <ClearOutlinedIcon />
        </IconButton>
      </IconButtonContainer>
      <ModalHeader>Hide Review</ModalHeader>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="gender"
          name="gender1"
          value={value}
          onChange={handleChange}
        >
          <StyledFormControlLabel
            value="Off Topic"
            control={<Radio />}
            label={
              <p className="review-description">
                <strong>Off Topic</strong>
                <br />
                Review doesn’t pertain to an experience at or with this business
              </p>
            }
          />
          <StyledFormControlLabel
            value="Spam"
            control={<Radio />}
            label={
              <p className="review-description">
                <strong>Spam</strong>
                <br />
                Review is from a bot, a fake account, or contains ads and
                promotions
              </p>
            }
          />
          <StyledFormControlLabel
            value="Conflict of interest"
            control={<Radio />}
            label={
              <p className="review-description">
                <strong>Conflict of interest</strong>
                <br />
                Review is from someone affiliated with the business ora
                competitor’s business
              </p>
            }
          />
          <StyledFormControlLabel
            value="Profanity"
            control={<Radio />}
            label={
              <p className="review-description">
                <strong>Profanity</strong>
                <br />
                Review contains swear words, has sexually explicit language, or
                details graphic violence
              </p>
            }
          />
          <StyledFormControlLabel
            value="Bullying or harassment"
            control={<Radio />}
            label={
              <p className="review-description">
                <strong>Bullying or harassment</strong>
                <br />
                Review personally attacks a specific individual
              </p>
            }
          />
          <StyledFormControlLabel
            value="Discrimination or hate speech"
            control={<Radio />}
            label={
              <p className="review-description">
                <strong>Discrimination or hate speech</strong>
                <br />
                Review has harmful language about an individual or group based
                on identity
              </p>
            }
          />
          <StyledFormControlLabel
            value="Personal Information"
            control={<Radio />}
            label={
              <p className="review-description">
                <strong>Personal Information</strong>
                <br />
                Review contains personal information, such as an address or
                phone number
              </p>
            }
          />
          <StyledFormControlLabel
            value="Not helpful"
            control={<Radio />}
            label={
              <p className="review-description">
                <strong>Not helpful</strong>
                <br />
                Review doesn’t help people decide whether to go to this place
              </p>
            }
          />
        </RadioGroup>
      </FormControl>
      <HideButtonContainer>
        <Button
          onClick={() => handleUnHideReview(val)}
          variant="contained"
          color="secondary"
        >
          Hide Review
        </Button>
      </HideButtonContainer>
    </ModalContentContainer>
  );

  return (
    <StyledModal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {!val.reviewData.flag ? bodyHide : bodyUnHide}
    </StyledModal>
  );
}
