import React from "react";
import { Modal, Typography } from "@material-ui/core";
// import { useNavigate } from "react-router-dom";
import {
  StyledCancelButton,
  StyledConfirmationButton,
  ButtonContainer,
  ModalContentContainer,
  TitleText,
} from "./styles";

function DeleteModal(props) {
  const { open, handleClose, message, handleDeleteCategory, title } = props;
  // const navigate = useNavigate();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalContentContainer>
        <TitleText>{title}</TitleText>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {message}
        </Typography>
        <ButtonContainer>
          <StyledConfirmationButton
            onClick={handleDeleteCategory}
            variant={"contained"}
          >
            Yes
          </StyledConfirmationButton>
          <StyledCancelButton onClick={handleClose} variant={"contained"}>
            No
          </StyledCancelButton>
        </ButtonContainer>
      </ModalContentContainer>
    </Modal>
  );
}

export default DeleteModal;
