import React, { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import MenuItemsList from "./MenuItemsList";

function Form() {
  const handleChipClick = useOutletContext();

  useEffect(() => {
    handleChipClick("menu_items");
  }, [handleChipClick]);

  return <MenuItemsList />;
}

export default Form;
