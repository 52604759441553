import React from "react";
import { Modal, Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import {
  StyledCancelButton,
  StyledConfirmationButton,
  ButtonContainer,
  ModalContentContainer,
} from "./styles";

function CancelModal(props) {
  const { open, handleClose } = props;
  const navigate = useNavigate();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalContentContainer>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Do you want to Cancel?
        </Typography>
        <ButtonContainer>
          <StyledConfirmationButton
            onClick={() => navigate("/")}
            variant={"contained"}
          >
            Yes
          </StyledConfirmationButton>
          <StyledCancelButton onClick={handleClose} variant={"contained"}>
            No
          </StyledCancelButton>
        </ButtonContainer>
      </ModalContentContainer>
    </Modal>
  );
}

export default CancelModal;
