import styled from "styled-components/macro";
import { Button, Typography, TextField } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { CheckCircleOutline } from "@material-ui/icons";

const Container = styled.div`
  padding: 40px;
`;

const StyledForm = styled.form`
  width: 100%;
  margin: 0 auto;
`;

const StyledHeader = styled(Typography).attrs(() => ({
  component: "h1",
  variant: "h5",
}))`
  text-align: center;
  margin-bottom: 20px;
  font-weight: 600 !important;
`;

const Tips = styled(Typography)`
  && {
    display: flex;
    align-items: center;
    margin: 10px 0;
    font-size: 13px;
  }

  && img {
    margin-right: 10px;
  }
`;

const StyledDescriptionTextField = styled(TextField)`
  outline: none;
  padding: 12px;
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 0.23);

  &:focus {
    border: 2px solid #3f51b5;
  }
`;

const FileUploadContainer = styled.div`
  display: flex;
  margin: 10px 0;
`;

const ButtonContainer = styled.div`
  width: 50%;
  margin: 30px auto 20px auto;
  display: flex;
  justify-content: space-around;
`;

const FileUpload = styled.div`
  width: 220px;
  & button {
    background: white;
    outline: none;
    cursor: pointer;
    padding: 5px 20px;
    color: #ef403b;
    border: 2px solid #ef403b;
    border-radius: 5px;
    text-transform: capitalize;
    font-weight: 600;
  }
  & button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;
const UploadedFile = styled.div`
  width: 35px;
  height: 35px;
  margin-left: 40px;
  position: relative;
  border-radius: 3px;
  border: 2px solid red;
  background-size: cover;
`;

const DeleteIcon = styled.div`
  color: #100f0f;
  position: absolute;
  top: -6px;
  left: 22px;
  cursor: pointer;
  background-color: #fff;
  padding: 0px;
  width: 0px;
  height: 0px;
`;

const MultipleFile = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
`;
const HelperText = styled.div`
  color: #f44336;
  margin: 0 0 0 14px;
  font-size: 12px;
`;
const StyledCancelIcon = styled(CancelIcon)`
  backgroundcolor: "white";
  borderradius: "5px";
  height: "15px";
  width: "15px";
`;

const ButtonsContainer = styled.div`
  // width: 50%;
  // margin: 20px auto;
  display: flex;
  justify-content: center;

  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  margin-bottom: 0;
`;

const StyledCancelButton = styled(Button).attrs(() => ({
  variant: "contained",
}))`
  &&& {
    background-color: white;
    color: #ef403b;
    border: 1px solid #ef403b;
    //width: 155px;
    padding: 5px 30px !important;
    border-radius: 8px !important;
    font-weight: bold !important;
    margin: 10px !important;
  }
`;

const StyledSaveButton = styled(Button).attrs(() => ({ varian: "contained" }))`
  &&& {
    background-color: #ef403b;
    color: white;
    //width: 142px;
    padding: 5px 30px !important;
    border-radius: 8px !important;
    font-weight: bold !important;
    margin: 10px !important;
  }
`;
const StyledTextButton = styled(Button).attrs(() => ({}))`
  &&& {
    color: #ef403b;
    // //width: 142px;
    padding: 5px 30px !important;
    border-radius: 8px !important;
    font-weight: bold !important;
    margin: 10px !important;
  }
`;

const SuccessWrapper = styled.div`
  padding: 11px 17px 11px 11px;
  margin-top: 10px;
  margin-bottom: 10px;
  background: #e4fcea;
  border-radius: 3px;
  display: inline-flex;
  line-height: 1;
  align-items: center;
  font-weight: 700;
`;

const StyledCheckCircleOutline = styled(CheckCircleOutline)`
  &&& {
    color: #1bb55c;
    // cursor: pointer;
    margin-right: 5px;
  }
`;
export {
  Container,
  StyledForm,
  Tips,
  StyledDescriptionTextField,
  StyledHeader,
  FileUpload,
  FileUploadContainer,
  MultipleFile,
  HelperText,
  DeleteIcon,
  UploadedFile,
  ButtonContainer,
  StyledCancelIcon,
  ButtonsContainer,
  StyledCancelButton,
  StyledSaveButton,
  StyledTextButton,
  SuccessWrapper,
  StyledCheckCircleOutline,
};
