import { Typography } from "@material-ui/core";
import styled from "styled-components/macro";
import CancelIcon from "@material-ui/icons/Cancel";

import { HighlightOff, CheckCircleOutline } from "@material-ui/icons";
import { Button } from "@material-ui/core";

const FormContainer = styled.div`
  padding: 40px;
`;

const SponsordForm = styled.form`
  width: 100%;
  margin: 0 auto;
`;

const StyledHeader = styled(Typography).attrs(() => ({
  component: "h1",
  variant: "h5",
}))`
  &&& {
    text-align: center;
    margin-bottom: 20px;
    font-weight: 600;
    text-transform: capitalize;
  }
`;

const StyledDescription = styled(Typography).attrs(() => ({
  component: "p",
  // variant: "p",
}))`
  &&& {
    display: flex;
    align-items: center;
    margin: 10px 0;
    font-size: 13px;
  }
`;

const ItemWrapper = styled.div`
  margin: 10px 0;
`;

const FileWrapper = styled.div`
  display: flex;
  margin: 10px 0;
`;

const FileUpload = styled.div`
  width: 230px;

  & input:first-of-type {
    display: none;
  }

  & button:first-of-type {
    background: white;
    outline: none;
    cursor: pointer;
    padding: 5px 20px;
    color: #ef403b;
    border: 2px solid #ef403b;
    border-radius: 5px;
    text-transform: capitalize;
    font-weight: 600;

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
`;

const UploadedFile = styled.div`
  width: 35px;
  height: 35px;
  margin-left: 40px;
  position: relative;
  border-radius: 3px;
  border: 2px solid red;
  background-size: cover;
`;

const DeleteIconContainer = styled.div`
  color: #100f0f;
  position: absolute;
  top: -6px;
  left: 22px;
  cursor: pointer;
  background-color: #fff;
  padding: 0px;
  width: 0px;
  height: 0px;
`;

const StyledCanceIcon = styled(CancelIcon)`
  &&& {
    background-color: white;
    border-radius: 5px;
    height: 15px;
    width: 15px;
  }
`;

const ButtonContainer = styled.div`
  // width: 50%;
  // margin: 30px auto 20px auto;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  margin-bottom: 0;
`;

const HelperText = styled.div`
  color: #f44336;
  margin: 0 0 0 14px;
  font-size: 12px;
`;

const StyledCancelButton = styled(Button).attrs(() => ({
  variant: "contained",
}))`
  background-color: white !important;
  color: #ef403b !important;
  border: 1px solid #ef403b !important;
  //width: 142px;
  padding: 5px 30px !important;
  border-radius: 8px !important;
  font-weight: bold !important;
  margin: 10px !important;
`;

const StyledSaveButton = styled(Button).attrs(() => ({
  variant: "contained",
}))`
  background-color: #ef403b !important;
  color: white !important;
  //width: 142px;
  padding: 5px 30px !important;
  border-radius: 8px !important;
  font-weight: bold !important;
  margin: 10px !important;
`;
const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconsContainer = styled.div`
  margin-left: 20px;
  width: 70px;
  display: flex;
  justify-content: space-between;
`;

const StyledCheckCircleOutline = styled(CheckCircleOutline)`
  &&& {
    color: #1bb55c;
    cursor: pointer;
  }
`;

const StyledHighlightOff = styled(HighlightOff)`
  &&& {
    color: #dd1d1d;
    cursor: pointer;
  }
`;

const EditButton = styled.div`
  color: red;
  margin-left: 1rem;
  cursor: pointer;
  font-size: 14px;
`;
const Featured = styled.div`
  margin-left: 10 px;
`;

export {
  Featured,
  FormContainer,
  SponsordForm,
  StyledHeader,
  StyledDescription,
  ItemWrapper,
  FileWrapper,
  FileUpload,
  UploadedFile,
  DeleteIconContainer,
  StyledCanceIcon,
  ButtonContainer,
  HelperText,
  StyledCancelButton,
  StyledSaveButton,
  FlexWrapper,
  IconsContainer,
  StyledCheckCircleOutline,
  StyledHighlightOff,
  EditButton,
};
