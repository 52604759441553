import styled from "styled-components/macro";
const Container = styled.div`
  // position: absolute;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background: grey;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
  z-index: 1000;
`;

export { Container };
