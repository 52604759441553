const validate = (values) => {
  const errors = {};
  if (values.name.length < 1) {
    errors.name = "Restaurant Account Name Is Required";
  }
  if (values.name.length > 25) {
    errors.name = "Max 25 characters";
  }
  if (values.email.length < 1) {
    errors.email = "Email is Required";
  }
  if (values.email.length >= 1 && !/.+@.+..+/.test(values.email)) {
    errors.email = "Invalid Email";
  }

  if (values.password.length < 6) {
    errors.password = "Password should be 6 or more characters";
  }
  if (values.confirmPassword.length < 6) {
    errors.confirmPassword = "Confirm Password should be 6 or more characters";
  }

  if (values.password !== values.confirmPassword) {
    errors.confirmPassword = "Confirm Password should be same as Password";
  }

  return errors;
};

export default validate;
