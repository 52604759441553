import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  withStyles,
  Switch,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useDispatch } from "react-redux";
import { accoutHandler } from "./tableSlice";
import LoadingModal from "../baseComponents/LoadingModal";
import { Container } from "./styles";

export default function BasicTable() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const TableHeaderCell = withStyles((theme) => ({
    root: {
      color: "black",
      fontWeight: 600,
    },
  }))(TableCell);
  const TableDataCell = withStyles((theme) => ({
    root: {
      color: "#0085FF",
      fontWeight: 600,
    },
  }))(TableCell);

  const [restaurants, setRestaurants] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    setIsloading(true);
    let data = [];
    const getData = async () => {
      const querySnapshot = await getDocs(collection(db, "restaurants"));
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        if (doc.data().name) {
          data.push({
            restaurant: doc.data(),
            id: doc.id,
          });
        }
      });

      data.sort((a, b) => a.restaurant.name.localeCompare(b.restaurant.name));
      setIsloading(false);
      setRestaurants(data);
    };

    getData();
    localStorage.removeItem("uid");
    localStorage.removeItem("id");
    localStorage.removeItem("status");
  }, [toggle]);

  useEffect(() => {
    const getData = async () => {
      const docRef = doc(
        db,
        "users",
        JSON.parse(localStorage.getItem("user")).uid
      );

      const docSnap = await getDoc(docRef);

      if (docSnap?.data() && docSnap?.data().role === "super admin") {
        navigate("/");
      } else if (docSnap?.data() && docSnap?.data().role === "admin") {
        navigate("/register/home");
      } else {
        navigate("/login");
      }

      setIsloading(false);
    };

    getData();
  }, [navigate]);

  const handleClick = (id) => {
    localStorage.setItem("uid", id);
    dispatch(accoutHandler(id));
    navigate("/register/home");
  };

  const handleChange = async (statusId, status) => {
    const washingtonRef = doc(db, "restaurants", statusId);

    await updateDoc(washingtonRef, {
      status: status === "published" ? "disabled" : "published",
    }).then(() => {
      setToggle((prev) => !prev);
    });
  };

  return (
    <Container>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableHeaderCell>Restaurant Name</TableHeaderCell>
              <TableHeaderCell>Location</TableHeaderCell>
              <TableHeaderCell>Cusine</TableHeaderCell>
              <TableHeaderCell>Subscription Plan</TableHeaderCell>
              <TableHeaderCell>Status</TableHeaderCell>
              <TableHeaderCell>Publish</TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {restaurants?.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableDataCell
                  onClick={(e) => {
                    handleClick(row.id);
                  }}
                  component="th"
                  scope="row"
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                >
                  {row.restaurant.name || "No name"}
                </TableDataCell>
                <TableCell>{row.restaurant.address.city}</TableCell>
                <TableCell>{row.restaurant.cuisine?.name || ""}</TableCell>
                <TableCell>{row.restaurant.subscriptionPlan}</TableCell>
                <TableCell>
                  {typeof row.restaurant.status === "string" &&
                    row.restaurant.status
                      .split(" ")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                </TableCell>

                <TableCell>
                  {" "}
                  <Switch
                    checked={
                      row.restaurant.status === "published" ? true : false
                    }
                    onChange={() => handleChange(row.id, row.restaurant.status)}
                    color="primary"
                    name={row.id}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isLoading && <LoadingModal />}
    </Container>
  );
}
