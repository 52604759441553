import React, { useState } from "react";
import Sidebar from "./components/sidebar/Sidebar";
import { Outlet } from "react-router-dom";

import { Container, Forms } from "./styles";
import Footer from "../footer/Footer";

function Register() {
  const [selectedChip, setSelectedChip] = useState("");

  const handleChipClick = (selectedChip) => {
    setSelectedChip(selectedChip);
  };

  return (
    <div>
      <Container>
        <Sidebar
          selectedChip={selectedChip}
          handleChipClick={handleChipClick}
        />
        <Forms>
          <Outlet context={handleChipClick} />
        </Forms>
      </Container>
      <Footer />
    </div>
  );
}

export default Register;
