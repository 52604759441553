import { Button, withTheme } from "@material-ui/core";
import styled from "styled-components/macro";

const ModalContentContainer = withTheme(styled.div`
  position: absolute;
  width: 250px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  outline: none;
  border-radius: 10px;
  ${({ theme }) => `
  background-color: ${theme.palette.background.paper};
  box-shadow: ${theme.shadows[5]};
  padding: ${theme.spacing(4)}px;
`}
`);

const ButtonContainer = styled.div`
  margin-top: 20px;
`;

const StyledCancelButton = withTheme(styled(Button)`
  && {
    background-color: white;
    color: #ef403b;
    border: 1px solid #ef403b;
    width: 80px;
  }
`);

const StyledConfirmationButton = withTheme(styled(Button)`
  && {
    background-color: #ef403b;
    color: white;
    margin-right: 30px;
    width: 80px;
  }
`);

export {
  StyledCancelButton,
  StyledConfirmationButton,
  ButtonContainer,
  ModalContentContainer,
};
