import React, { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import Form from "./Form";

function RestoDetails() {
  const handleChipClick = useOutletContext();

  useEffect(() => {
    handleChipClick("restaurant_details");
  }, [handleChipClick]);

  return <Form />;
}

export default RestoDetails;
